import axiosInstance from 'axios-instance';
interface DecodedToken {
    id: string,
    email: string
}

//this file contains all the helpers function

export const jwt_decode = (token: string) => {
    if (token) {
        const tokenFraction = token.split('.')[1];
        let decoded = atob(tokenFraction);
        let user: DecodedToken = JSON.parse(decoded);
        return user;
    } return {};
};

export const setAxiosHeaders = (token: string) => {
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = token;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

export const scrollToElementById = (elementId: string) => {
    const element = document.querySelector(elementId);

    if(element) {
        element.scrollIntoView({behavior: "smooth", block: "end"});
        return;
    }
} 

export const stringToOption = (str: string) => {
    return {
        label: str,
        value: str
    };
};


