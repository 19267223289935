import React, { useEffect, useState } from 'react';
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import '../styles.scss';
import Loader from 'components/loader/Loader';

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    html.style.overflowY = 'auto';
    body.style.zoom = "normal";
    const script = document.createElement('script');
    script.id = 'termly-jssdk';
    script.src = 'https://app.termly.io/embed-policy.min.js';
    document.getElementsByTagName('script')[0].parentNode.insertBefore(script, null);

    script.onload = () => setLoading(false);


    return () => {
      if (html) {
        html.style.overflowY = 'hidden';
      }
      if (body) {
        body.style.zoom = "90%";
      }
    }
  }, []);

  useEffect(() => {
    window.scroll({left: 0, top: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <Header />
      <div className='privacy-legal'>
        {loading &&  <div className='loader-privacy'><Loader /></div>}
        <div name="termly-embed" data-id="91561b2c-0273-4c26-9766-700316d85a5f"></div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;