import Spinner from 'react-bootstrap/Spinner';

//this file contains the logic of custom loader

type LoaderProps = {
    variant?: "primary" | "secondary"| "success" | "danger"| "warning" | "info" | "light" | "dark",
    animation?: "border" | "grow",
    className?: string,
    lengthStyle?: any
}

function Loader({className, variant, animation = "border", lengthStyle = {}}: LoaderProps) {

    return (
        <Spinner animation={animation} variant={variant} className={className} style={lengthStyle}  role="status" />
    )
}

export default Loader;