import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "axios-instance";
import { MarkerProps } from "utils/types";

interface commentProp {
	user: string | undefined,
	message: string,
	pitch?: string,
	commentId?: string,
	marker?: MarkerProps,
	feedbackId: string,
	type?: string,
}


//this file contains the review's API.

export const getFeedbackAction = createAsyncThunk<any, any>('get/feedback', async (obj, { rejectWithValue }) => {
	try {
		const {id, userId} = obj;

		const { data } = await axiosInstance.get(`/api/feedbacks/${id}`, {
			params: {
				userId: userId
			}
		});
		return data;
	} catch (error: any) {
		console.log('error', error);
		const { response } = error;
		return rejectWithValue({ message: response.data.error, status: response.status })
	}
});

export const getScales = createAsyncThunk<any, void>('get/all-scales', async (_, {rejectWithValue}) => {
try {
	const { data } = await axiosInstance.get(`/api/scales`)

	return data;
} catch (error: any) {
	console.log('error', error);
	const { response } = error;
	return rejectWithValue({ message: response.data.error, status: response.status })
}
})

export const getReviewAction = createAsyncThunk<any, any>('get/review', async (id, { rejectWithValue }) => {
	try {
		const { data } = await axiosInstance.get(`/api/reviews/${id}`)

		return data;
	} catch (error: any) {
		console.log('error', error);
		const { response } = error;
		return rejectWithValue({ message: response.data.error, status: response.status })
	}
})

export const createComment = async (obj: commentProp) => {
	try {
		const { data } = await axiosInstance.post('/api/comments', obj);

		return data;
	} catch (error: any) {
		console.log(error);
		const { response } = error;
		throw new Error('Error', { cause: { message: response.data.error, status: response.status } });
	}
}

export const deleteMarker = createAsyncThunk<any, any>('delete/marker', async (obj, { rejectWithValue }) => {
	try {

		const { comment, review } = obj;
		await axiosInstance.put('/api/markers', obj);

		return { comment: comment, review: review };
	} catch (error: any) {
		console.log('error', error);
		const { response } = error;
		return rejectWithValue({ message: response.data.error, status: response.status })
	}
})

export const updateFeedbackAction = async (obj) => {
	try {
		const {_id} = obj;

		const { data } = await axiosInstance.put(`/api/feedbacks/${_id}`, obj);
		return data;
	} catch (error: any) {
		console.log(error);
		const { response } = error;
		throw new Error('Error', { cause: { message: response.data.error, status: response.status } });
	}
}

export const createReply = async (obj: commentProp) => {
	try {
		const { data } = await axiosInstance.post('/api/replies', obj);

		return data;
	} catch (error: any) {
		console.log(error);
		const { response } = error;
		throw new Error('Error', { cause: { message: response.data.error, status: response.status } });
	}
}