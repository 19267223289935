import CustomFormikField from "components/CustomFormikField/CustomFormikField";
import TextError from "components/TextError";
import { ErrorMessage } from "formik";
import { useEffect } from "react";

const PersonalInfo = ({ setTouched, setFieldValue }: any) => {
    useEffect(() => {
        setTouched({
            expertName: true,
            companyDescription: true
        })
    }, [])
    return (
        <div>
            <div className="form-group extra-mb">
                <label htmlFor="#" style={{ opacity: 0 }} className="control-label big">Hey! What is your name?</label>
                <CustomFormikField
                    name="expertName"
                    type="text"
                    className="form-control only-bottom-b"
                    placeholder="Enter your full name."
                    setFieldValue={setFieldValue}
                />
                <ErrorMessage name="expertName" component={TextError} />
            </div>
            <div className="form-group extra-mb">
                <label htmlFor="#" className="control-label big" style={{ opacity: 0 }}>Hey! What is your name?</label>
                <CustomFormikField
                    name="linkedinProfile"
                    type="text"
                    className="form-control only-bottom-b"
                    placeholder="Enter your LinkedIn profile URL."
                    setFieldValue={setFieldValue}
                    style={{ marginBottom: '20px' }}
                />
                <ErrorMessage name="linkedinProfile" component={TextError} />
            </div>
        </div>
    );
};

export default PersonalInfo;