import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Trash3Fill } from 'react-bootstrap-icons';
import "./styles.scss";

//this file display the document through attachment when creating the pitch.

type DocumentProps = {
	docs: [],
	headerProps?: {
		disableHeader: boolean,
		disableFileName?: boolean,
		retainURLParams?: boolean
	},
	documentClass?: string,
	parentClass?: string,
	clearAttachment?: () => void,
	disableBinIcon?: boolean
}

const areEqual = (prevProps: DocumentProps, nextProps: DocumentProps) => {
	return JSON.stringify(prevProps.docs) === JSON.stringify(nextProps.docs);
}

const DocumentViewer = React.memo(({ docs, headerProps, documentClass, parentClass, clearAttachment, disableBinIcon = false }: DocumentProps) => {

	const handleChange = (e) => {
		console.log(e);
	}

	return (
		<div className={parentClass}>
			{!disableBinIcon && (
			<div className='deck-header'>
				<Trash3Fill className="trash-bin" onClick={clearAttachment} />
			</div>
			)}
			<DocViewer
				pluginRenderers={DocViewerRenderers}
				className={documentClass}
				onDocumentChange={handleChange}
				documents={docs}
				config={{
					header: {
						...headerProps
					}
				}}
			/>
		</div>
	)
}, areEqual)

export default DocumentViewer