import CustomFormikField from 'components/CustomFormikField/CustomFormikField';
import { ErrorMessage, Field } from 'formik';

const RoundInfo = ({ setFieldValue }) => {


    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleChange = (event, field) => {
        let inputValue = event.target.value;

        const numericValue = inputValue.replace(/,/g, "");
        const formattedValue = formatNumberWithCommas(numericValue);
        setFieldValue(field.name, formattedValue);

    };

    return (
        <div>
            <div className="form-group extra-mb">
                <label style={{ opacity: 0 }} htmlFor="#" className="control-label big">What's the round size of your company?</label>
                <CustomFormikField
                    type="text"
                    className="form-control only-bottom-b"
                    handleChange={handleChange}
                    placeholder="Enter your company round size."
                    id="roundSize"
                    name="roundSize"
                    setFieldValue={setFieldValue}
                />
                <div className="error-field">
                    <ErrorMessage name="roundSize" />
                </div>
            </div>
            <div className="form-group extra-mb">
                <label htmlFor="#" className="control-label big">How far along is this round?</label>
                <div className="radio default-radio  mt-0">
                    <label>
                        <Field type="radio" name="roundProgress" id="roundProgress" value="Have not begun raising funds" />
                        <span>
                            Have not begun raising funds
                        </span>
                    </label>
                    <label>
                        <Field type="radio" name="roundProgress" id="roundProgress" value="Just starting" />
                        <span>
                            Just starting
                        </span>
                    </label>
                    <label>
                        <Field type="radio" name="roundProgress" id="roundProgress" value="Some committed" />
                        <span>
                            Some committed
                        </span>
                    </label>
                    <label>
                        <Field type="radio" name="roundProgress" id="roundProgress" value="Other" />
                        <span>
                            Other
                        </span>
                    </label>
                </div>
                <div className="error-field">
                    <ErrorMessage name="roundProgress" />
                </div>
            </div>

            <div className="form-group extra-mb">
                <label htmlFor="#" className="control-label big">Select 3-6 Keywords to describe your company</label>

                <ul className="d-flex list-unstyled select-multiple-buttons">
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Manufacturing'} />
                            <span>Manufacturing</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Agriculture'} />
                            <span>Agriculture</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Food industry'} />
                            <span>Food industry</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Production'} />
                            <span>Production</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Industrial Sector" />
                            <span>Industrial sector</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Education" />
                            <span>Education</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Engineering" />
                            <span>Engineering</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Warehouse" />
                            <span>Warehouse</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Biotechnology" />
                            <span>Biotechnology</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Management" />
                            <span>Management</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Healthcare" />
                            <span>Healthcare</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="E-commerce" />
                            <span>E-commerce</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Energy" />
                            <span>Energy</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Hospitality industry" />
                            <span>Hospitality industry</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Finance" />
                            <span>Finance</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Aviation" />
                            <span>Aviation</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Tertiary sector of the economy" />
                            <span>Tertiary sector of the economy</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Medical device" />
                            <span>Medical device</span>
                        </label>
                    </li>
                </ul>
                <div className="error-field">
                    <ErrorMessage name="companyDescription" />
                </div>
            </div>
        </div>
    );
};

export default RoundInfo;