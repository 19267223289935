import { useAppDispatch, useAppSelector } from 'hooks';
import Select from 'react-select';
import { updateProfile } from 'reducers/profile';
import { stringToOption } from 'utils/helpers';

const stageOptions = [
    { value: 'Idea', label: 'Idea' },
    { value: 'Pre-seed', label: 'Pre-seed' },
    { value: 'Seed', label: 'Seed' },
    { value: 'Series-A', label: 'Series-A' },
    { value: 'Series-B', label: 'Series-B' },
    { value: 'Series-C', label: 'Series-C' },
    { value: 'Beyond', label: 'Beyond' },
];
const stageProgressOptions = [
    { value: 'Have not begun raising funds', label: 'Have not begun raising funds' },
    { value: 'Just starting', label: 'Just starting' },
    { value: 'Some committed', label: 'Some committed' },
    { value: 'Other', label: 'Other' }
];
const multiOption = [
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Agriculture', label: 'Agriculture' },
    { value: 'Food industry', label: 'Food industry' },
    { value: 'Production', label: 'Production' },
    { value: 'Industrial sector', label: 'Industrial sector' },
    { value: 'Education', label: 'Education' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Warehouse', label: 'Warehouse' },
    { value: 'Biotechnology', label: 'Biotechnology' },
    { value: 'Management', label: 'Management' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'E-commerce', label: 'E-commerce' },
    { value: 'Hospitality industry', label: 'Hospitality industry' },
    { value: 'Fincance', label: 'Finance' },
    { value: 'Aviation', label: 'Aviation' },
    { value: 'Tertiary sector of the economy', label: 'Tertiary sector of the economy' },
    { value: 'Medical device', label: 'Medical device' }
];


//this file contains the company settings on the settings page
const CompanySettings = ({ errors }: any) => {
    const { profile: { companyName = '', companyWebsite = '', incorporationDetails = '',
        stage = '', roundSize = '', roundProgress = '', companyDescription = [] } }: any = useAppSelector(({ profileStore }) => profileStore);
    const dispatch = useAppDispatch();

    const onChange = (e: any, type: string) => {
        switch (type) {
            case 'stage':
                dispatch(updateProfile({ type: type, value: e }));
                break;
            case 'roundProgress':
                dispatch(updateProfile({ type: type, value: e }));
                break;
            case 'companyDescription':
                const value = e.map((v: any) => v.value) || [];
                dispatch(updateProfile({ type: type, value: value }));
                break;
            default:
                dispatch(updateProfile({ type: type, value: e.target.value }));
        }
    };
    return (
        <div className="company-inner-wrapper">
            <div className="custom-form-wrapper">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Company name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Jhon Smith"
                                value={companyName}
                                onChange={(e) => onChange(e, 'companyName')}
                            />
                            <div className="error p-1">{errors.companyName}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Company website</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="www.example.com"
                                value={companyWebsite}
                                onChange={(e) => onChange(e, 'companyWebsite')}
                            />
                            <div className="error p-1">{errors.companyWebsite}</div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Company incorporate</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="xyz@example.com"
                                value={incorporationDetails}
                                onChange={(e) => onChange(e, 'incorporationDetails')}
                            />
                            <div className="error p-1">{errors.incorporationDetails}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Company stage</label>
                            <Select
                                options={stageOptions}
                                className="custom-select"
                                value={stringToOption(stage)}
                                onChange={(v: any) => onChange(v.value, 'stage')}
                            />
                            <div className="error p-1">{errors.stage}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Company round size</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="5-10"
                                value={roundSize}
                                onChange={(e: any) => onChange(e, 'roundSize')}
                            />
                            <div className="error p-1">{errors.roundSize}</div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">How far along is this round</label>
                            <Select
                                options={stageProgressOptions}
                                className="custom-select"
                                value={stringToOption(roundProgress)}
                                onChange={(v: any) => onChange(v.value, 'roundProgress')}
                            />
                            <div className="error p-1">{errors.roundProgress}</div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Select 3 - 6 Keywords to describe your company</label>
                            <Select
                                options={multiOption}
                                isMulti={true}
                                className="custom-multi-select"
                                value={companyDescription.map((v: string) => stringToOption(v)) || []}
                                onChange={(v: any) => onChange(v, 'companyDescription')}
                            />
                            <div className="error p-1">{errors.companyDescription}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanySettings;