import { Link } from 'react-router-dom';
import logonew from "assets/images/svg/new-logo.svg";
import { useEffect, useState } from 'react';
import BillingModal from 'views/expert/billing/BillingModal';
import ExpertPlan from 'views/expert/billing/ExpertPlan';
import "./services.scss";
import ExpertWallet from './ExpertWallet';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getServicesAction } from 'actions/billingActions';

function Services() {
    const [show, setShow] = useState(false);
    const dispatch = useAppDispatch();
    const { services, loading } = useAppSelector(({ service }) => service)

    const getServices = async () => {
        try {
            const response = await dispatch(getServicesAction({}));
            if (response.type.includes('rejected')) throw response;
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getServices();

        //eslint-disable-next-line
    }, []);

    return (
        <div className="services-screen">
            <div className="container">
                <div className="fullLogo text-center">
                    <Link to="/">
                        <img src={logonew} alt="whatapitch" />
                    </Link>
                </div>
                <BillingModal show={show} setShow={setShow} />
                <section className='services-section'>
                    <div className="row g-5">
                        <ExpertPlan services={services} loading={loading} />
                        <ExpertWallet setShow={setShow} />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Services;