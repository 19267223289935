import "./index.scss";

//This file contains the logic to display the stats.

function Stats() {
    return (
        <>
            <div className="container">
                <div className="statsWrapper text-center" data-aos="fade-right" data-aos-easing="ease-in-sine">
                    <div className="row justify-content-between gx-2">
                        <div className="col-lg-3 col-sm-6">
                            <div className="statsListinig">
                                <h4>$500M+</h4>
                                <p>Raised with our pitch reviews and deck designs</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="statsListinig">
                                 <h4>600+</h4>
                                <p>Ventures and Founders Coached</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="statsListinig">
                                <h4>1,000+</h4>
                                <p>Industry and fundraising experts</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="statsListinig">
                                <h4>$250,000+</h4>
                                <p>Paid to Industry and fundraising Experts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Stats;
