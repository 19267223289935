import Login from "views/entry/Signin";
import "./styles.scss";
import Signup from './Signup';
import logonew from "assets/images/logo-t.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { useEffect } from "react";


function Auth() {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const html = document.querySelector('html');
    if(location.pathname.includes('auth')) {
      if(html) {
        html.style.overflowY = 'hidden';
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated) navigate('/dashboard');
  }, []);

  return (
    <>
      <div className="auth-screen">
        <div className="container">
          <div className="fullLogo text-center">
            <Link to="/">
              <img src={logonew} alt="whatapitch" />
            </Link>
          </div>
          <section>
            <h2 className="sectionTitle font-bold" data-aos="fade-in" data-aos-easing="ease-in-sine">The easiest way to <span>perfect your pitch</span> and get back to changing the world.</h2>
            <div className="row auth-cards-container justify-content-center flex-lg-row flex-column-reverse">
              <Signup />
              <Login />
            </div>
          </section>
          <div className="copyright text-center">
            © 2024 WhataPitch. All Rights Reserved
          </div>
        </div>
      </div>
    </>
  )
}

export default Auth
