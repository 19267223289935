import axiosInstance from "axios-instance";

//this file contains the user setting API

export const updatePasswordAPI = async (obj: any) => {
    try {
        const { data } = await axiosInstance.put(`/api/settings/updatePassword`, obj);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        return data;
    }
};

export const updateUser = async (obj: any, id: any) => {
    try {
        const { data } = await axiosInstance.put(`/api/users/${id}`, obj);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        return data;
    }
};

export const uploadProfileImage = async (formData: any) => {
    try {
        const { data } = await axiosInstance.post(`/api/settings/uploadProfileImage`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } });
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        return data;
    }
};