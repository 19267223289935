import React, { useContext, useState } from 'react';
import ReviewContext from 'contexts/reviewContext';
import './styles.scss';
import classNames from "classnames";
import { clearMarker, setMarker } from 'reducers/reviewsReducer';
import { useAppSelector, useAppDispatch } from 'hooks';
import CustomModal from 'components/custom-modal/CustomModal';
import Button from 'react-bootstrap/Button';
import { deleteMarker } from "actions/reviewActions";
import Loader from 'components/loader/Loader';
import { isEmpty, last } from "lodash";

interface MarkerWrapperProps {
	children: React.ReactNode;
	loading: boolean | null
}

//This file contains the logic to add or remove markers in documents and images.

function MarkerWrapper({ children, loading = null }: MarkerWrapperProps) {
	const { markers, comments, review, markerLoading, selectedMarker } = useAppSelector(({ reviews }) => reviews);
	const { isPinActive, toggleIsPinActive, page } = useContext(ReviewContext);
	const dispatch = useAppDispatch();
	const [show, setShow] = useState(false);
	const [selectMarker, setTheMarker] = useState<any>(null);

	const handleClose = () => setShow(false);

	const handleShow = (marker) => {
		setTheMarker(marker);
		setShow(true)
	}

	const handleMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const bounds = event.currentTarget.getBoundingClientRect();
		const x = event.clientX - bounds.left;
		const y = event.clientY - bounds.top;
		const xPercent = (x / bounds.width) * 100;
		const yPercent = (y / bounds.height) * 100;
		const indexNumber = markers.length + 1;
		var lastMarker: any = last(markers);

		let number = lastMarker ? Number(lastMarker.number) + 1: indexNumber.toString();

		let newMarker = { yAxis: yPercent, xAxis: xPercent, number: number, page: page };

		dispatch(setMarker({ marker: newMarker }));
	};

	const removeMarker = async () => {
		const { number } = selectMarker;

		let allMarkers = markers.filter((marker) => marker.number !== number);
		var commentData = comments.find((comment) => comment.marker === number);

		const reviewCopy = { ...review };
		reviewCopy.markers = allMarkers;

		if (commentData) {
			dispatch(deleteMarker({ review: reviewCopy, comment: commentData }));
		}

		setShow(false);
		toggleIsPinActive();
	};

	function viewComment() {
		const { number } = selectMarker;
		const element = document.getElementById(number);

		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}

		setShow(false);
		toggleIsPinActive();
	}

	return (
		<>
			<CustomModal show={show} handleClose={handleClose} title={"Marker Information"} description={"Do you want to view the marker's comment or delete it? '\n' Note: When you remove the marker if it associated with the comment that comment will automatically remove. "}>
				<Button disabled={markerLoading} variant="primary" onClick={removeMarker}>
					{markerLoading && <Loader />}
					{!markerLoading && "Remove"}
				</Button>
				<Button disabled={markerLoading} variant="primary" onClick={viewComment}>
					{markerLoading && <Loader />}
					{!markerLoading && "View"}
				</Button>
			</CustomModal>
			<div
				onClick={handleMouseClick}
				className={classNames({
					"tracking-div": true,
					disable: !isPinActive,
				})} >
				<div>{children}</div>
				{!loading && !isEmpty(selectedMarker) && (
					<div
						className="custom-marker"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							setTimeout(() => {
								dispatch(clearMarker());
							},300)
						}}
						style={{ position: 'absolute', top: `${selectedMarker.yAxis}%`, left: `${selectedMarker.xAxis}%` }}>
						<div className='displaying-index'>{selectedMarker.number}
						</div>
					</div>
				)}
				{!loading && markers.filter(smarker => smarker.page === page).map((marker, index) => (
					<div
						key={index}
						className="custom-marker"
						onClick={(e) => {
							e.stopPropagation();
							handleShow(marker);
						}}
						style={{ position: 'absolute', top: `${marker.yAxis}%`, left: `${marker.xAxis}%` }}
					>
						<div className='displaying-index'>{marker.number}
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default MarkerWrapper