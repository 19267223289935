import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "./header.scss";
import logonew from "assets/images/logo-t.png";

//this file contains the logic of header

function Header() {
    // sticky 
    const [scroll, setScroll] = useState(window.scrollY);
    const [sticky, setSticky] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY);
            if (scroll > 160) {
                setSticky(true)
            } else {
                setSticky(false)
            }
        })
    }, [scroll])
    // sticky 
    return (
        <>
            <div className="container">
                <header className={`${sticky ? "sticky" : ''}`}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="logo">
                            <Link to="/">
                                <span className='d-lg-block d-none'>
                                    <img src={logonew} alt="whatapitch" />
                                </span>
                                <span className='d-lg-none'>
                                    <svg width="47.455" height="44.843" viewBox="0 0 47.455 44.843">
                                        <defs>
                                            <clipPath id="clipPath">
                                                <rect id="Rectangle_47" data-name="Rectangle 47" width="47.455" height="44.843" transform="translate(0 11.333)" fill="none" />
                                            </clipPath>
                                        </defs>
                                        <g id="Group_54" data-name="Group 54" transform="translate(-343.565 -73.529)">
                                            <g id="Group_44" data-name="Group 44" transform="translate(343.565 62.194)">
                                                <g id="Group_43" data-name="Group 43" transform="translate(0 0.002)" clipPath="url(#clipPath)">
                                                    <path id="Path_706" data-name="Path 706" d="M661.059,256.322l-.627-.714a1.211,1.211,0,0,1,.11-1.7l5.327-4.68a1.211,1.211,0,0,1,1.7.11l.627.714a1.211,1.211,0,0,1-.11,1.7l-5.327,4.68a1.211,1.211,0,0,1-1.7-.11" transform="translate(-625.984 -236.048)" fill="#5e3a1c" />
                                                    <path id="Path_707" data-name="Path 707" d="M652.009,247.306a1.747,1.747,0,0,1-1.317-.595l-.627-.714a1.755,1.755,0,0,1,.16-2.471l5.327-4.68a1.753,1.753,0,0,1,2.471.16l.627.714a1.754,1.754,0,0,1-.16,2.471l-5.327,4.68a1.744,1.744,0,0,1-1.155.435m4.7-7.807a.658.658,0,0,0-.437.164l-5.327,4.68a.665.665,0,0,0-.061.938l.627.714a.665.665,0,0,0,.938.061l5.327-4.68a.668.668,0,0,0,.061-.938l-.627-.714a.66.66,0,0,0-.456-.223h-.045" transform="translate(-616.026 -226.079)" />
                                                    <path id="Path_712" data-name="Path 712" d="M46.445,354.507c5.958-6.154,8.005-14.382,5.789-20.764a6.842,6.842,0,0,1-.246-3.517,4.312,4.312,0,0,0-5.192-5.007,6.644,6.644,0,0,1-3.464-.122c-6.458-2.032-14.637.279-20.608,6.447s-8.005,14.382-5.789,20.764a6.842,6.842,0,0,1,.246,3.517,4.312,4.312,0,0,0,5.191,5.007,6.644,6.644,0,0,1,3.464.122c6.458,2.032,14.637-.279,20.609-6.447" transform="translate(-15.233 -307.598)" fill="#f4d13b" />
                                                    <path id="Path_713" data-name="Path 713" d="M66.2,525.9c-5.971,6.168-14.151,8.478-20.609,6.447a6.642,6.642,0,0,0-3.464-.122,4.311,4.311,0,0,1-5.23-3.652,4.089,4.089,0,0,0,3.175.348,6.573,6.573,0,0,1,3.447-.078c6.322,1.661,14.611-1.123,20.921-7.642,4.719-4.875,7.314-10.788,7.543-16.08l.005.016c2.216,6.382.169,14.609-5.789,20.764" transform="translate(-34.989 -478.992)" fill="#dcb51f" />
                                                    <path id="Path_714" data-name="Path 714" d="M15.436,347.269a16.9,16.9,0,0,1-5.082-.761,5.831,5.831,0,0,0-3.033-.1,5.14,5.14,0,0,1-6.188-5.968,6.033,6.033,0,0,0-.215-3.092,18.569,18.569,0,0,1-.188-10.955,25.4,25.4,0,0,1,16.64-17.171,18.543,18.543,0,0,1,10.976-.146,5.834,5.834,0,0,0,3.033.1,5.14,5.14,0,0,1,6.187,5.968,6.033,6.033,0,0,0,.215,3.092,18.569,18.569,0,0,1,.188,10.955,24.713,24.713,0,0,1-6.164,10.656,24.7,24.7,0,0,1-10.476,6.515,20.276,20.276,0,0,1-5.894.907M8.663,344.6a7.293,7.293,0,0,1,2.188.332c6.394,2.012,14.153-.435,19.765-6.233h0c5.6-5.781,7.8-13.6,5.6-19.917a7.691,7.691,0,0,1-.278-3.941,3.5,3.5,0,0,0-1.151-3.29,3.456,3.456,0,0,0-3.044-.756,7.488,7.488,0,0,1-3.9-.14c-6.394-2.012-14.153.435-19.765,6.233-5.6,5.781-7.8,13.6-5.6,19.917a7.69,7.69,0,0,1,.278,3.941,3.5,3.5,0,0,0,1.151,3.29,3.456,3.456,0,0,0,3.044.756,7.734,7.734,0,0,1,1.707-.192" transform="translate(0 -292.363)" />
                                                    <path id="Path_715" data-name="Path 715" d="M474.1,845.817a1.845,1.845,0,1,1-1.845-1.845,1.845,1.845,0,0,1,1.845,1.845" transform="translate(-446.076 -800.313)" fill="#dcb51f" />
                                                    <path id="Path_716" data-name="Path 716" d="M574.67,696.788a1.845,1.845,0,1,1-1.845-1.845,1.845,1.845,0,0,1,1.845,1.845" transform="translate(-541.444 -658.994)" fill="#dcb51f" />
                                                    <path id="Path_717" data-name="Path 717" d="M298.556,944.56a1.845,1.845,0,1,1-1.845-1.845,1.844,1.844,0,0,1,1.845,1.845" transform="translate(-279.613 -893.948)" fill="#dcb51f" />
                                                    <path id="Path_718" data-name="Path 718" d="M130.435,780.271a1.231,1.231,0,1,1-1.231-1.231,1.231,1.231,0,0,1,1.231,1.231" transform="translate(-121.354 -738.74)" fill="#dcb51f" />
                                                    <path id="Path_719" data-name="Path 719" d="M353.172,780.721a1.206,1.206,0,1,1-1.206-1.206,1.206,1.206,0,0,1,1.206,1.206" transform="translate(-332.615 -739.191)" fill="#dcb51f" />
                                                    <path id="Path_720" data-name="Path 720" d="M569.735,506.328a1.206,1.206,0,1,1-1.206-1.206,1.206,1.206,0,0,1,1.206,1.206" transform="translate(-537.975 -478.992)" fill="#dcb51f" />
                                                    <path id="Path_721" data-name="Path 721" d="M152.261,415.223h0a.827.827,0,0,1-.391-1.159A19.336,19.336,0,0,1,154.1,410.9a19.657,19.657,0,0,1,8.1-5.625.828.828,0,1,1,.566,1.556,18,18,0,0,0-7.37,5.105,17.779,17.779,0,0,0-2.077,2.93.827.827,0,0,1-1.055.353" transform="translate(-143.913 -384.266)" fill="#fff" />
                                                    <path id="Path_722" data-name="Path 722" d="M402.324,385.879h0a.827.827,0,0,1,.658-1.011c.351-.062.8-.133,1.317-.195a.828.828,0,0,1,.925.752h0a.828.828,0,0,1-.727.892c-.483.058-.9.124-1.224.181a.827.827,0,0,1-.949-.618" transform="translate(-381.489 -364.768)" fill="#fff" />
                                                    <path id="Path_723" data-name="Path 723" d="M342.475,495.823a15.242,15.242,0,1,1-15.242-15.242,15.242,15.242,0,0,1,15.242,15.242" transform="translate(-295.852 -455.721)" fill="#e0b707" />
                                                    <path id="Path_724" data-name="Path 724" d="M312,496.664a16.073,16.073,0,1,1,11.365-4.708A15.968,15.968,0,0,1,312,496.664m0-30.484a14.411,14.411,0,1,0,14.411,14.411A14.427,14.427,0,0,0,312,466.18" transform="translate(-280.619 -440.489)" />
                                                    <path id="Path_725" data-name="Path 725" d="M394.036,550.36a12.267,12.267,0,1,1-12.267-12.267,12.267,12.267,0,0,1,12.267,12.267" transform="translate(-350.388 -510.257)" fill="#ffdd4d" />
                                                    <path id="Path_726" data-name="Path 726" d="M400.915,578.16a12.269,12.269,0,0,1-24.118,2.062,11.311,11.311,0,1,0,17.708-13.838,12.271,12.271,0,0,1,6.41,11.775" transform="translate(-357.305 -537.086)" fill="#f9d021" />
                                                    <path id="Path_727" data-name="Path 727" d="M375.856,525.928a13.095,13.095,0,1,0,3.835,9.259,13.009,13.009,0,0,0-3.835-9.259m-8.093,6.451-.007,0a.538.538,0,0,1-.332-.5v-7.505a.538.538,0,0,1,.6-.536,11.385,11.385,0,0,1,5.574,2.312.54.54,0,0,1,.054.809l-5.3,5.3a.539.539,0,0,1-.588.116m-2.327,0-.007,0a.539.539,0,0,1-.588-.116l-5.3-5.3a.54.54,0,0,1,.054-.809,11.385,11.385,0,0,1,5.574-2.312.538.538,0,0,1,.6.536v7.505a.538.538,0,0,1-.332.5m-1.649,1.645,0,.007a.538.538,0,0,1-.5.332h-7.505a.538.538,0,0,1-.536-.6,11.385,11.385,0,0,1,2.312-5.574.54.54,0,0,1,.81-.054l5.3,5.3a.538.538,0,0,1,.116.588M365.43,538l.007,0a.538.538,0,0,1,.332.5V546a.538.538,0,0,1-.6.536,11.384,11.384,0,0,1-5.574-2.312.54.54,0,0,1-.054-.81l5.3-5.3a.538.538,0,0,1,.588-.116m2.327,0,.007,0a.538.538,0,0,1,.588.116l5.3,5.3a.54.54,0,0,1-.054.81,11.385,11.385,0,0,1-5.575,2.312.538.538,0,0,1-.6-.536V538.5a.538.538,0,0,1,.332-.5m1.649-1.645,0-.007a.538.538,0,0,1,.5-.332h7.505a.538.538,0,0,1,.536.6,11.385,11.385,0,0,1-2.312,5.574.54.54,0,0,1-.81.054l-5.3-5.3a.539.539,0,0,1-.116-.588m8.005-1.994h-7.505a.538.538,0,0,1-.5-.332l0-.007a.539.539,0,0,1,.116-.588l5.3-5.3a.54.54,0,0,1,.81.054,11.385,11.385,0,0,1,2.312,5.574.538.538,0,0,1-.536.6m-21.629,1.655h7.505a.538.538,0,0,1,.5.332l0,.007a.539.539,0,0,1-.116.588l-5.3,5.3a.54.54,0,0,1-.81-.054,11.384,11.384,0,0,1-2.312-5.574.538.538,0,0,1,.536-.6" transform="translate(-335.215 -495.085)" fill="#fff5c4" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </Link>
                        </div>
                        <nav className="">
                            <Link to="/about">About </Link>
                            <Link to="/pricing">Pricing </Link>
                            <Link to="/#">For Experts</Link>
                            {/* <Link to="/#">Resources</Link> */}
                        </nav>
                        <div className='text-end'>
                            <button onClick={() => navigate('/auth')} className='btn btn-primary sm pulseBtn'>Start</button>
                        </div>
                    </div>
                </header>
            </div>
        </>
    )
}

export default Header;
