import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getCardDetails } from "actions/cards";
type CardDetails = {
    paymentProvider: string,
    cardToken: string,
    last4: string,
    expiryMonth: string,
    expiryYear: string,
    cardType: string
};
type InitialState = {
    card: CardDetails[],
    loading: boolean,
    error: string
};
const initialState: InitialState = {
    card: [],
    loading: false,
    error: ''
};


const cardSlice = createSlice({
    name: 'cardStore',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getCardDetails.pending, (state: InitialState) => {
            state.loading = true;
        });
        builder.addCase(getCardDetails.fulfilled, (state: InitialState, action: PayloadAction<any>) => {
            state.loading = false;
            state.card = action.payload;
        });
        builder.addCase(getCardDetails.rejected, (state: InitialState, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload;
            throw new Error('Error', { cause: action.payload });
        });
    }
});

export default cardSlice.reducer;