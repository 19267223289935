import { useAppSelector } from "hooks";
import { Outlet, Navigate } from "react-router-dom";

//this file contains the logic of private routes i.e. login is compulsory

const PrivateRoute = () => {
    const { isAuthenticated } = useAppSelector(({ auth }) => auth);
    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;