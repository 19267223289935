import CustomFormikField from "components/CustomFormikField/CustomFormikField";
import { ErrorMessage, Field } from "formik";

const ExperienceInfo = ({ setFieldValue }) => {
    return (
        <div>
            <div className="form-group extra-mb">
                <label htmlFor="#" className="control-label big">I am actively involved as an</label>

                <div className="checkbox   mt-10">
                    <label>
                        <Field type="checkbox" name="startupExperience" value={"Investor in startups"} />
                        <span>
                            Investor in startups
                        </span>
                    </label>
                    <label>
                        <Field type="checkbox" name="startupExperience" value={"Advisor to startups"} />
                        <span>
                            Advisor to startups
                        </span>
                    </label>
                    <label>
                        <Field type="checkbox" name="startupExperience" value={"Founder network"} />
                        <span>
                            Founder network
                        </span>
                    </label>
                    <label>
                        <Field type="checkbox" name="startupExperience" value="Other" />
                        <span>
                            Other
                        </span>
                    </label>
                </div>
                <hr className="border-b-line" />
            </div>
            <div className="form-group extra-mb">
                <label htmlFor="#" className="control-label big">Select 3-6 Keywords to describe your company</label>

                <ul className="d-flex list-unstyled select-multiple-buttons">
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Manufacturing'} />
                            <span>Manufacturing</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Agriculture'} />
                            <span>Agriculture</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Food industry'} />
                            <span>Food industry</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value={'Production'} />
                            <span>Production</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Industrial Sector" />
                            <span>Industrial sector</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Education" />
                            <span>Education</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Engineering" />
                            <span>Engineering</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Warehouse" />
                            <span>Warehouse</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Biotechnology" />
                            <span>Biotechnology</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Management" />
                            <span>Management</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Healthcare" />
                            <span>Healthcare</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="E-commerce" />
                            <span>E-commerce</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Energy" />
                            <span>Energy</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Hospitality industry" />
                            <span>Hospitality industry</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Finance" />
                            <span>Finance</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Aviation" />
                            <span>Aviation</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Tertiary sector of the economy" />
                            <span>Tertiary sector of the economy</span>
                        </label>
                    </li>
                    <li className="checkbox">
                        <label>
                            <Field type="checkbox" name="companyDescription" value="Medical device" />
                            <span>Medical device</span>
                        </label>
                    </li>
                </ul>
                <div className="error-field">
                    <ErrorMessage name="companyDescription" />
                </div>
            </div>
        </div>
    );
};

export default ExperienceInfo;