import { useAppDispatch, useAppSelector } from 'hooks';
import Select from 'react-select';
import { updateProfile } from 'reducers/profile';
import { stringToOption } from 'utils/helpers';

const experienceOptions = [
    { value: 'Investor in startups', label: 'Investor in startups' },
    { value: 'Advisor in startups', label: 'Advisor in startups' },
    { value: 'Founder Network', label: 'Founder Network' },
    { value: 'Other', label: 'Other' }
];

const multiOption = [
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Agriculture', label: 'Agriculture' },
    { value: 'Food industry', label: 'Food industry' },
    { value: 'Production', label: 'Production' },
    { value: 'Industrial sector', label: 'Industrial sector' },
    { value: 'Education', label: 'Education' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Warehouse', label: 'Warehouse' },
    { value: 'Biotechnology', label: 'Biotechnology' },
    { value: 'Management', label: 'Management' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'E-commerce', label: 'E-commerce' },
    { value: 'Hospitality industry', label: 'Hospitality industry' },
    { value: 'Fincance', label: 'Finance' },
    { value: 'Aviation', label: 'Aviation' },
    { value: 'Tertiary sector of the economy', label: 'Tertiary sector of the economy' },
    { value: 'Medical device', label: 'Medical device' }
];

//this file contains the information of experience in settings page.
const ExpereinceSettings = ({ errors }) => {
    const { profile: { startupExperience = [], companyDescription = '', designation = '', description = '' } }: any = useAppSelector(({ profileStore }) => profileStore);
    const dispatch = useAppDispatch();

    const onChange = (value: any, key: string, type: string) => {
        switch (type) {
            case "multi":
                const arr = value.map((v) => v.value);
                dispatch(updateProfile({ type: key, value: arr }));
                break;
            default:
                dispatch(updateProfile({ type: key, value: value }))

        }
    };
    return (
        <div className="company-inner-wrapper">
            <div className="custom-form-wrapper">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Active Involvement</label>
                            <Select
                                options={experienceOptions}
                                isMulti={true}
                                className="custom-select"
                                value={startupExperience.map(v => stringToOption(v))}
                                onChange={(v: any) => onChange(v, "startupExperience", "multi")}
                            />
                            <div className="error p-1">{errors.startupExperience}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Describe Experience</label>
                            <Select
                                options={multiOption}
                                isMulti={true}
                                className="custom-multi-select"
                                placeholder="Describe Experience"
                                value={companyDescription.map(v => stringToOption(v))}
                                onChange={(v: any) => onChange(v, "companyDescription", "multi")}
                            />
                            <div className="error p-1">{errors.companyDescription}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                value={designation}
                                onChange={(e) => { onChange(e.target.value, "designation", "single") }}
                            />
                            <div className="error p-1">{errors.designation}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Describe Yourself</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Describe yourself"
                                value={description}
                                onChange={(e) => { onChange(e.target.value, "description", "single") }}
                            />
                            <div className="error p-1">{errors.description}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpereinceSettings;