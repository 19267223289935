import React, { useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import AOS from "aos";
import TestComponent from 'components/Test';
import Home from "design/landingPage/Home";
import Auth from "design/entry/Auth";
import About from "design/about/About";
import UpdateForgottenPassword from "design/entry/UpdateForgottenPassword";
import ForgetPasswordLink from "design/entry/ForgetPasswordLink";
import Founder from "design/founder/Founder";
import Expert from "design/expertflow/index";
import DashboardLayout from 'design/dashboard/dashboardLayout/DashboardLayout';
import DashboardHome from 'design/dashboard/dashboardHome/DashboardHome';
import DashboardReviews from 'design/dashboard/dashboardReviews/DashboardReviews';
import DashboardPayments from 'design/dashboard/dashboardPayments/DashboardPayments';
import DashboardWallet from "design/dashboard/dashboardWallet/DashboardWallet"
import DashboardSettings from 'design/dashboard/dashboardSettings/DashboardSettings';
import Billing from 'design/founder/billing/Billing';
import DeckInfo from 'design/founder/deck-info/DeckInfo';
import './App.css';
import Services from 'views/expert/billing/ExpertBilling';

declare global {
  interface Window {
    AOS: any; // Adjust 'any' based on the actual type of AOS
  }
}

function App() {
  useEffect(() => {
    window.AOS =
      window.AOS || ``
    AOS.init({
      delay: 60, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      disable: "mobile",
      once: false,
      mirror: true,
    });
    document.addEventListener("mousemove", () => {
      window.AOS =
        window.AOS ||
        AOS.init({
          delay: 60, // values from 0 to 3000, with step 50ms
          duration: 600, // values from 0 to 3000, with step 50ms
          disable: "mobile",
          once: false,
          mirror: true,
        });
    });
    return () => {
      document.removeEventListener("mousemove", () => {
        window.AOS = {};
      });
    };
  }, []);


  return (
    <HashRouter>
      <Routes>
        <Route path="test" element={<TestComponent />} />
        <Route path="/" element={<Home />}></Route>
        <Route path="auth" element={<Auth />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="forgetPassword/:token" element={<UpdateForgottenPassword />}></Route>
        <Route path="forgetPasswordLink" element={<ForgetPasswordLink />}></Route>
        <Route path="founder" element={<Founder />}></Route>
        <Route path="expert" element={<Expert />}></Route>
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardHome />} />
          <Route path="reviews" element={<DashboardReviews />} />
          <Route path="payments" element={<DashboardPayments />} />
          <Route path="wallet" element={<DashboardWallet />} />
          <Route path="settings" element={<DashboardSettings />} />
        </Route>
        <Route path="founder" element={<Founder />}></Route>
        <Route path="billing" element={<Billing />}></Route>
        <Route path="deck-info" element={<DeckInfo />}></Route>
        <Route path="expert" element={<Expert />}></Route>
        <Route path="services" element={<Services />}></Route>

      </Routes>
    </HashRouter>
  );
}

export default App;
