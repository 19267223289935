import React, { CSSProperties } from 'react';

//this file display the image through attachment when creating the pitch.

var styles = {
	attachmentImage: {
		maxHeight: "inherit",
		maxWidth: "inherit",
		minHeight: "inherit",
		minWidth: "inherit",
		objectFit: "cover",
		color: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		height: "100%",
		flexDirection: "column"
	} as CSSProperties,
	imageStyle: {
		height: "inherit",
		objectFit: "cover"
	} as CSSProperties
};

function ImagePreview({ attachmentSrc, backgroundColor = "black", name }: { attachmentSrc: string | null, backgroundColor?: string, name?: string }) {

	return (
		<>
			<div style={{ ...styles.attachmentImage, background: backgroundColor }}>
				{attachmentSrc && (
				  <img src={attachmentSrc} alt="post-attachment" style={styles.imageStyle} />
				)}
			</div>
		</>

	)
}

export default ImagePreview;