import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEmpty } from 'lodash';
import { jwt_decode, setAxiosHeaders } from "utils/helpers";
import { loginAction, founderSignupAction } from 'actions/authActions';

type userProps = {
    id?: string,
    companyDescription?: string[],
    email?: string,
    iat?: number,
    userType?: string,
    website?: string
}

type InitialState = { token: string, error: string, loading: boolean, user: userProps, isAuthenticated: boolean, backUpToken: string };
const initialState: InitialState = {
    token: '',
    error: '',
    user: {},
    isAuthenticated: false,
    backUpToken: "",
    loading: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setAuthToken: (state, action) => {
            state.token = action.payload;
            state.isAuthenticated = !isEmpty(action.payload);
        },
        logoutUser: (state) => {
            state.token = '';
            state.isAuthenticated = false;
            state.user = {};
            localStorage.removeItem('jwtToken');
            setAxiosHeaders(state.token || state.backUpToken);
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setBackUpToken: (state) => {
            state.backUpToken = state.token;
        },
        clearBackUpToken: (state) => {
            state.backUpToken = "";
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loginAction.pending, (state: InitialState) => {
            state.loading = true;
        })
        builder.addCase(loginAction.fulfilled, (state: InitialState, action: PayloadAction<string>) => {
            state.loading = false;
            state.token = action.payload;
            state.isAuthenticated = !isEmpty(action.payload)
            state.user = jwt_decode(action.payload);
            localStorage.setItem('jwtToken', action.payload);
            setAxiosHeaders(action.payload);
        })
        builder.addCase(loginAction.rejected, (state: InitialState, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload
        });
        builder.addCase(founderSignupAction.pending, (state: InitialState) => {
            state.loading = true;
        })
        builder.addCase(founderSignupAction.fulfilled, (state: InitialState, action: PayloadAction<string>) => {
            state.loading = false;
            state.token = action.payload;
            state.isAuthenticated = !isEmpty(action.payload)
            state.user = jwt_decode(action.payload);
            localStorage.setItem('jwtToken', action.payload);
            setAxiosHeaders(action.payload);
        })
        builder.addCase(founderSignupAction.rejected, (state: InitialState, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload
        });
    }
});

export default authSlice.reducer;
export const { setUser, setAuthToken, logoutUser, setLoading, setBackUpToken, clearBackUpToken } = authSlice.actions;