import { useContext, useEffect, useRef } from "react";
import { getServicesAction } from 'actions/billingActions';
import { useAppDispatch, useAppSelector } from "hooks";
import { setFounderService } from 'reducers/billingReducer';
import Loader from 'components/loader/Loader';
import BillingContext from "contexts/billingContext";
import { Link } from "react-router-dom";

const Plan = () => {
    const dispatch = useAppDispatch();
    const { services = [], loading } = useAppSelector(({ service }) => service);
    const { billingDetail } = useContext(BillingContext);
    const radioRefs = useRef<Array<HTMLDivElement | null>>([]);
    const serviceIndex = 0;

    const manageServices = async () => {
        try {
            const response = await dispatch(getServicesAction({}));
            if (response.type.includes('rejected')) throw response;
            else {
                const { payload } = response;
                if (payload.length > 0) dispatch(setFounderService(payload[serviceIndex]));
                const firstRadioButtonRef = radioRefs.current[serviceIndex];
                if (firstRadioButtonRef && firstRadioButtonRef.querySelector('input[type="radio"]')) {
                    (firstRadioButtonRef.querySelector('input[type="radio"]') as HTMLInputElement).click();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        manageServices();
        // eslint-disable-next-line
    }, []);

    const planRadioClick = (event: { target: any }) => {
        const radio = event.target;
        const parentElement = radio.parentElement;
        const serviceId = radio.value;
        const service = services.find(obj => obj._id === serviceId);
        document.querySelectorAll('.planLabel').forEach((element) => {
            element.classList.remove('selected');
        });
        parentElement.classList.add('selected');
        dispatch(setFounderService(service));
    };

    const renderServices = () => {
        return services.map(({ _id, description, type, price, currency }: any, index: number) => {
            // eslint-disable-next-line
            const [currencyName, currencySign] = currency.split(':');
            const className = (index === 0) ? 'planLabel rm-bottom-borders' : 'planLabel rm-top-borders';
            return (
                <div key={_id} className="radio" ref={(el) => (radioRefs.current[index] = el as HTMLInputElement)}>
                    <label className={className}>
                        <input disabled={billingDetail} type="radio" value={_id} name="size1" className='radio-input' onClick={planRadioClick} />
                        <span>{description} <span className='amount'>{currencySign} {price} <span>Review</span></span></span>
                    </label>
                </div>
            )
        })
    };

    const handleContactUs = () => {
        const subject = encodeURIComponent("Contact Us");
        const body = encodeURIComponent("Hi,\n\nPlease provide your query or feedback below:\n\n[Your message here]\n\nBest regards,\n[Your Name]");
        window.location.href = `mailto:info@whatapitch.ai?subject=${subject}&body=${body}`;
    }



    return (
        <div className="col-lg-7 col-md-6 choose-your-plan" data-aos="fade-up" data-aos-easing="ease-in-sine">
            <h2>Choose your plan</h2>
            <form action="" className="mb-30">
                <div className="radio-buttons">
                    {(loading) ? <div className='radio'> <Loader /> </div> : renderServices()}
                    <div className="radio" style={{ display: 'none' }}>
                        <label id="add-bottom-borders" className='planLabel rm-top-borders' style={{ cursor: 'not-allowed' }}>
                            <input disabled={true} type="radio" name="size1" className='radio-input' onClick={planRadioClick} />
                            <span>Circulate to my existing network<span className='amount'>$ 45 <span>Review</span></span></span>
                        </label>
                    </div>
                </div>
            </form>
            <h2>Other</h2>
            <form action="">
                <div className="radio-buttons">
                    <div style={{ display: 'none' }} className="radio">
                        <label className='otherLabel rm-bottom-borders' style={{ cursor: 'not-allowed' }}>
                            <input disabled={true} type="radio" name="size1" className='radio-input' onClick={() => { }} />
                            <span>One-on-one pitch practice (45 Min Session)<span className='amount'>$ 195 / <span>Session</span></span></span>
                        </label>
                    </div>
                    <div className="radio">
                        <label className='otherLabel rm-bottom-borders' style={{cursor:'default'}}>
                            <input disabled={true} type="radio" name="size1" className='radio-input' onClick={() => { }} />
                            <span>I'll bring the content just Design My Deck<span className='amount' style={{cursor:'pointer'}} onClick={handleContactUs}>Contact Us</span></span>
                        </label>
                    </div>
                    <div className="radio">
                        <label className='otherLabel no-borders' style={{cursor:'default'}} >
                            <input disabled={true} type="radio" name="size1" className='radio-input' onClick={() => { }} />
                            <span>Coach me through and design my deck<span className='amount' style={{cursor:'pointer'}} onClick={handleContactUs}>Contact Us</span></span>
                        </label>
                    </div>
                    <div className="radio">
                        <label id="add-bottom-borders" className='otherLabel rm-top-borders' style={{cursor:'default'}} >
                            <input disabled={true} type="radio" name="size1" className='radio-input' onClick={() => { }} />
                            {/* <span>Live Pitch Practice<span className='amount'>$ 195 / <span>Session</span></span></span> */}
                            <span>Live Pitch Practice<span className='amount' style={{cursor:'pointer'}} onClick={handleContactUs}>Contact Us</span></span>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Plan;