import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTransactions } from "actions/transactions";

type Transactions = {
    userId: string,
    cardId: string,
    amount: number,
    service: string,
    status: string,
    paymentIntentId: string,
    currency: string
};
type InitialState = {
    transactions: Transactions[],
    loading: boolean,
    error: string
};

const initialState: InitialState = {
    transactions: [],
    loading: false,
    error: ''
};

const transactionSlice = createSlice({
    name: 'transactions',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getTransactions.pending, (state: InitialState) => {
            state.loading = true;
        });
        builder.addCase(getTransactions.fulfilled, (state: InitialState, action: PayloadAction<any>) => {
            state.loading = false;
            state.transactions = action.payload;
        });
        builder.addCase(getTransactions.rejected, (state: InitialState, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload
            throw new Error('Error', { cause: action.payload })
        });
    }
});

export default transactionSlice.reducer;
