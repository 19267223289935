import { createContext, RefObject, createRef, Dispatch, SetStateAction } from "react";
import { MarkerProps } from "utils/types";

//"This file contains the review context information"

interface ReviewContextType {
    setPage: Dispatch<SetStateAction<number>>,
    setIsPinActive: Dispatch<SetStateAction<boolean>>,
    toggleIsPinActive: () => void;
	markers: MarkerProps[];
	isPinActive: Boolean;
    videoRef: RefObject<HTMLVideoElement>;
    page: number,
	commentInputRef: RefObject<HTMLTextAreaElement>;
}

const defaultContext: ReviewContextType = {
    toggleIsPinActive: () => {},
    setIsPinActive: () => {},
    setPage: () => {},
    page: 1,
    videoRef: createRef<HTMLVideoElement>(),
    markers: [],
    isPinActive: false,
    commentInputRef: createRef<HTMLTextAreaElement>()
}

const ReviewContext = createContext<ReviewContextType>(defaultContext);

export default ReviewContext;