import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import AppDesign from './App-design';
import store from './store';
import "_core/_assets/sass/styles.scss";
const SWITCH = process.env.REACT_APP_DESIGN_SWITCH;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store} >
    {(SWITCH === 'dev') ? <App/> : <AppDesign />}
  </Provider>
);
