import blackChecklist from "assets/images/svg/ios-checkmark-circle.svg";
import whiteChecklist from "assets/images/svg/ios-checkmark-circle-white.svg";
import "./index.scss";
import Stats from "components/stats/Stats";

//This file contains the logic to display the packages.


function Packages({ hide = false, isActive = true }) {

    return (
        <>
            <section className='packages'>
                <div className="container" data-aos={isActive ? "fade-right": ""} data-aos-easing={isActive ? "ease-in-sine" : ""}>
                    {!hide && (
                        <h2 className='sectionTitle font-bold'>The easiest way to <span>perfect your pitch</span> and get back to changing the world.</h2>
                    )}
                    <div className="packagesWrapper">
                        <div className="packageListingWrapper">
                            <div className='packageListing'>
                                <h3>Roast My Video Pitch</h3>
                                <p>Prepare for the hot seat infront of investors.</p>
                                <ul className="list-unstyled">
                                    <li><img src={blackChecklist} alt="blackChecklist" /> Record your pitch.</li>
                                    <li><img src={blackChecklist} alt="blackChecklist" /> Connect with a fundraising expert.</li>
                                    <li style={{ marginBottom: '0' }}><img src={blackChecklist} alt="blackChecklist" /> Perfect your pitch and get back to changing the world.</li>
                                    <li style={{ marginBottom: '0', fontSize: '0' }}> Record your pitch.</li>
                                </ul>
                                <div className="button-row left-button">
                                    <button className='btn btn-primary'>$145 (One-Time Fee)</button>
                                </div>
                                {/* <h5>Professional Deck Design + $1,450 One Time</h5> */}
                            </div>
                            <div className='packageListing highlight'>
                                <h3>Roast My Pitch Deck</h3>
                                <p>A clear snapshot of your venture and the investment opportunity.</p>
                                <ul className="list-unstyled">
                                    <li><img src={whiteChecklist} alt="whiteChecklist" /> Upload your deck.</li>
                                    <li><img src={whiteChecklist} alt="whiteChecklist" /> Connect with a fundraising expert.</li>
                                    <li><img src={whiteChecklist} alt="whiteChecklist" /> Perfect your pitch and get back to changing the world.</li>
                                </ul>
                                <div className="button-row">
                                    <button className='btn btn-default'>$95 (One-Time Fee)</button>
                                </div>
                                {/* <h5>Professional Deck Design + $1,450 One Time</h5> */}
                            </div>
                            <div className='packageListing'>
                                <h3>One-on-One Coaching</h3>
                                <p>Work with an investing expert to craft or refine your pitch.</p>
                                <ul className="list-unstyled">
                                    <li><img src={blackChecklist} alt="blackChecklist" /> Connect with a fundraising expert.</li>
                                    <li><img src={blackChecklist} alt="blackChecklist" /> Schedule a one-on-one video session (45 Minute Session).</li>
                                    <li><img src={blackChecklist} alt="blackChecklist" /> Perfect your pitch and get back to changing the world.</li>
                                </ul>
                                <div className="button-row">
                                    <button className='btn btn-primary'>Coming Soon</button>
                                </div>
                                {/* <h5>Professional Deck Design + $1,450 One Time</h5> */}
                            </div>
                        </div>
                        <h5>Professional Deck Design + $1,450 One Time</h5>
                    </div>
                </div>
                <Stats />
            </section>
        </>
    )
}
export default Packages
