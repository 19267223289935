import ListItem from './listItem/ListItem';
import './dashboardPayments.scss';
import { getTransactions } from 'actions/transactions';
import { getCardDetails } from 'actions/cards';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import Loader from 'components/loader/Loader';


//this file contains the data of founder dashboard payment page
function DashboardPayments() {
	const { loading, transactions } = useAppSelector(({ transactions }) => transactions);
	const { card } = useAppSelector(({ cards }) => cards);
	const user: any = useAppSelector(({ auth }) => auth.user);
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(getTransactions({ userId: user.id }));
		dispatch(getCardDetails({ userId: user.id }));
		// eslint-disable-next-line
	}, [])

	const renderCardDetails = () => {
		const { last4, expiryMonth, expiryYear, cardType } = card[0] || {};
		return (
			<div className="card">
				<h5>Card Number <span>**** **** **** {last4 || '****'}</span></h5>
				<div className='row'>
					<div className='col-sm-5'> <h5>Card Expire <span>{expiryMonth || '**'} / {expiryYear || '****'}</span></h5></div>
					<div className='col-sm-5'><h5>Card Type <span>{cardType || '****'}</span></h5> </div>
				</div>
			</div>
		);
	};
	return (
		<div className="dashboard-home">
			<div className="header-container">
				<div className="header">
					<h1 className="title">
						Payments
					</h1>
				</div>
			</div>
			<div className="dashboard-home__content">
				<div className="payment-wrapper">
					{renderCardDetails()}
				</div>
				<div className={`dashboard-list ${loading && 'align-items-center'}`}>
					{(loading) ? <Loader /> :
						transactions.map((transaction: any) => {
							return (<ListItem
								expertId={transaction.pitch.expertId}
								expert={transaction.pitch.expert}
								amount={transaction.totalAmount ? transaction.totalAmount: transaction.amount}
								service={transaction.services}
								pitch={transaction.pitch}
								user={transaction.user}
							/>);
						})
					}
				</div>
			</div>
		</div>
	);
}

export default DashboardPayments;
