import healthCare from "assets/images/healthCare.png";
import marketplace from "assets/images/marketplace.png";
import SaaS from "assets/images/SaaS.png";
import AI from "assets/images/AI-new.png";
// import AI from "assets/images/AI.png";
import fintech from "assets/images/fintech.png";
import More from "assets/images/more.png";
import Slider from "react-slick";
import "./index.scss";

//This file contains the logic to display the technologies.

function Technologies() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        arrows: false,
        slidesToScroll: 6,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToScroll: 5,
                slidesToShow: 5,
                dots : true,
              }
            },
            {
              breakpoint: 1300,
              settings: {
                slidesToScroll: 4, 
                slidesToShow: 4,
                dots : true,
                arrows: false
              }
            },
            {
                breakpoint: 1024,
                settings: {
                  slidesToScroll: 3,
                  slidesToShow: 3,
                  dots : true,
                  arrows: false
                }
              },
            {
              breakpoint: 767,
              settings: {
                slidesToScroll: 2,
                slidesToShow: 2,
                dots : true,
                arrows: false
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots : true,
                arrows: false
              }
            }
        ]
      };
    return (
        <>
            <section className='technologies'>
                <div className="container" data-aos="fade-right" data-aos-easing="ease-in-sine">
                    <Slider {...settings}>
                        <div className='technologies_item'>
                            <img src={healthCare} alt="healthCare medtech" />
                            <div className="technologies_item--name">
                                <span>Healthcare Medtech</span>
                            </div>
                        </div>
                        <div className='technologies_item'>
                            <img src={marketplace} alt="marketplace" />
                            <div className="technologies_item--name">
                                <span>Marketplace</span>
                            </div>
                        </div>
                        <div className='technologies_item'>
                            <img src={SaaS} alt="SaaS" />
                            <div className="technologies_item--name">
                                <span>SaaS</span>
                            </div>
                        </div>
                        <div className='technologies_item'>
                            <img src={AI} alt="AI" />
                            <div className="technologies_item--name dark-gradient">
                                <span>Artificial Inteligence</span>
                            </div>
                        </div>
                        <div className='technologies_item'>
                            <img src={fintech} alt="fintech" />
                            <div className="technologies_item--name">
                                <span>FinTech</span>
                            </div>
                        </div>
                        <div className='technologies_item'>
                            <img src={More} alt="More" />
                            <div className="technologies_item--name">
                                <span>More</span>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Technologies;
