import { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import pitchLogo from "assets/images/pitchLogo.png";
import './paymentListItem.scss';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

//This file contains the pitches list on the founder dashboard payment page.
function ListItem({ pitch, amount, service, user, expert, expertId, _id: transactionId }: any) {
    const { name, website, status, type } = pitch || {};
    const { companyDescription = [] } = user || {};
    const [absoluteImageUrl, setImage] = useState("");

    useEffect(() => {
        if (expertId) {
            const absoluteImageUrl = expert?.profileImageUrl ? `${IMAGE_URL}/${expert?.profileImageUrl}` : "/user.svg";
            setImage(absoluteImageUrl);
        }

    }, [expert, expertId])

    const renderIndustryExperts = () => {
        return companyDescription.map((ie: string) => {
            return (
                <li className="checkbox">
                    <label>
                        <input type="checkbox" name="size1" />
                        <span>{ie}</span>
                    </label>
                </li>
            );
        })
    };
    return (
        <>
            <div key={transactionId} className="payment-list-item" style={{ margin: '10px' }}>
                <div className="payment-list-item__left">
                    <div className="item-info">
                        <div className="item-img">
                            <img src={pitchLogo} alt="item" />
                        </div>
                        <div className="item-content">
                            <p className="name">{name || '--'}</p>
                            <p className="link" data-tooltip-id={website}>{website || '--'}</p>
                            <p className="review-by">Plan: Review my pitch {type}</p>
                        </div>
                    </div>
                    <div className="target-experts">
                        <p className="name">Industry</p>
                        <div className="label-container">
                            <ul className="d-flex list-unstyled select-multiple-buttons">
                                {renderIndustryExperts()}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="payment-list-item__right">
                    <div className="first-half">
                        <div className="experts-container">
                            <p className="name t-name mb-10">Target experts</p>
                            {!expertId && (
                                <div className='expert expert-not-av'>N/A</div>
                            )}
                            {expertId && (
                                <div className="expert">
                                    <div className="expert-icon">
                                        <img src={absoluteImageUrl} alt="user" />
                                    </div>
                                    <p>{expert.name}</p>
                                </div>
                            )}
                        </div>
                        <div className="status-container">
                            <p className="name">Status</p>
                            <div className="expert">
                                <p>{status}</p>
                            </div>
                        </div>
                    </div>
                    <div className="second-half">
                        <div className="experts-container payment-container">
                            <p className="name">Payment</p>
                            <div className="expert">
                                <p>$ {amount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactTooltip
                id={website}
                content={website}
                variant="light"
                style={{ color: "#222", fontSize: "14px", left: "0", zIndex: '100000', backgroundColor: '#eee' }}
                place='top-start'
                offset={0}
            />
        </>

    );
}

export default ListItem;
