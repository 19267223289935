import React, { CSSProperties } from 'react';

//this file display the video through attachment when creating the pitch.

var styles = {
	attachmentVideo: {
		maxHeight: "inherit",
		maxWidth: "inherit",
		minHeight: "inherit",
		minWidth: "inherit",
		objectFit: "contain",
		color: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		height: "100%",
		width: "100%",
		flexDirection: "column"
	} as CSSProperties,
	overlay: {
		maxHeight: "inherit",
		maxWidth: "inherit",
		minHeight: "inherit",
		minWidth: "inherit",
		opacity: "0.6",
		background: "",
		position: "absolute",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		cursor: "pointer",
	} as CSSProperties,
	videoName: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: '5px',
		fontWeight: 600
	} as CSSProperties
};

function VideoPreview({ attachmentSrc, backgroundColor = "black", name }: { attachmentSrc: string | null, backgroundColor?: string, name?: string }) {

	return (
		<>
			<div style={{ ...styles.attachmentVideo, background: backgroundColor }}>
				{attachmentSrc && (
					<video src={attachmentSrc} style={{ ...styles.attachmentVideo}} controls />
				)}
			</div>
		</>

	)
}

export default VideoPreview