import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import "./styles.scss";
import Stats from "components/stats/Stats";
import Reviews from "components/reviews/Reviews";
import whoweare from "assets/images/who-we-are.png";
import signup1 from "assets/images/signup-1.png";
import signup2 from "assets/images/signup-2.png";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { reviewArrayProps } from "utils/types";

const reviewsArray: reviewArrayProps[] = [
  {id: 1, content: "Whatapitch simplifies fundraising.", heading: "Angel Investor"},
  {id: 2, content: "Essential for my investment strategy.", heading: "VC Partner"},
  {id: 3, content: "Noteworthy commitment to quality.", heading: "SaaS Founder"},
  {id: 4, content: "Changed how I connect with startups.", heading: "Impact Investor"}
];

//this file contains the information of about page

function About() {
  const location = useLocation();

  useEffect(() => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if(location.pathname === '/about') {
      if(body && html) {
        body.classList.remove('body-height');
        html.style.overflowY = 'auto';
      }
    }
    return () => {
      if(body && html) {
        body.classList.add('body-height');
        html.style.overflowY = 'hidden';
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scroll({left: 0, top: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <div className="innerWrapper">
        <div className="abountUs-bg-color-gradient-1">
          <Header />
          <section className="mainBanner" >
            <div className="container text-center" data-aos="fade-in" data-aos-easing="ease-in-sine">
              <h1 className="font-medium">About <span>Us</span></h1>
              <p><span className="about-heading">As founders, we HATED fundraising more than anything.</span> <span className="heading-line">This was the tool we wish we had to streamline our pitch and get back to building.</span></p>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item"><a href="/#">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
              </nav>
            </div>
          </section>
        </div>
        <section className="WhoWeare pb-30 abountUs-bg-color-gradient-2" >
          <div className="container">
            <div className="row gx-2 who-we-are-container">
              <div className="col-md-6 image-container" data-aos="fade-right" data-aos-easing="ease-in-sine">
                <div className="image">
                  <img src={whoweare} alt="Who We Are" />
                </div>
              </div>
              <div className="col-md-6 content-container" data-aos="fade-left" data-aos-easing="ease-in-sine">
                <h3>Who We Are</h3>
                <h4>Built By the venture community, for the venture community.</h4>
                <p>
                At Whatapitch.ai, we're more than just a platform – we're a community built by the venture community, for the venture community. As founders ourselves, we intimately understand the challenges of fundraising. It's not just a hurdle; it's a major distraction from the real work of changing the world. We recognized the necessity of refining our pitches, building confidence, and swiftly closing rounds, all while juggling the demands of running a startup.
                </p>
                <p>Similarly, as investors, we've seen countless promising ventures struggle to articulate their vision effectively. We've felt the desire to offer feedback and support, but often lacked the right platform to do so efficiently. That's why we decided to take matters into our own hands and create Whatapitch.ai – the solution we wish we had when navigating the complexities of fundraising.</p>
                <div className="button-row">
                  <button className='btn btn-primary'>Perfect My Pitch</button>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6" data-aos="fade-right" data-aos-easing="ease-in-sine">
                <h3>About Company</h3>
                <h4>Trusted By Many Tech Start ups</h4>
              </div>
              <div className="col-md-6" data-aos="fade-left" data-aos-easing="ease-in-sine">
                <p>
                Our mission is simple: to empower entrepreneurs to tell their stories with clarity and conviction, while providing investors and advisors with a seamless way to engage and offer valuable insights. By fostering collaboration and incentivizing meaningful feedback, we're leveling the playing field and driving innovation forward, one pitch at a time.
                </p>
              </div>
            </div>
            {/* <Partners /> */}
          </div>
        </section>
        <div className="abountUs-bg-color-gradient-3">
          <section className="OurFounders" >
            <div className="container" data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="card">
                <h2 className="sectionTitle lg mb-30">Our Founder Promise</h2>
                <div className="row justify-content-between flex-md-row flex-column-reverse">
                  <div className="col-xl-8 col-md-8 description" data-aos="fade-right" data-aos-easing="ease-in-sine">
                    <p>
                    Whatapitch is born from the frustration of founders just like you. We've spent countless hours refining our decks and pitches to connect quickly with decision-makers. Our promise is to continue refining our tools to speed up your fundraising journey, so you can focus on changing the world.
                    </p>
                  </div>
                  <div className="col-xl-3 col-md-4" data-aos="fade-left" data-aos-easing="ease-in-sine">
                    <div className="image">
                      <img src={signup1} alt="Our Founder Promise" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="OurExperts">
            <div className="container">
              <h2 className="sectionTitle" data-aos="fade-in" data-aos-easing="ease-in-sine">Our Expert Promise</h2>
              <div className="row justify-content-between gx-2">
                <div className="col-md-3" data-aos="fade-right" data-aos-easing="ease-in-sine">
                  <div className="image">
                    <img src={signup2} alt="Our Expert Promise" />
                  </div>
                </div>
                <div className="col-md-8 description" data-aos="fade-left" data-aos-easing="ease-in-sine">
                  <p>
                  As investors ourselves, we understand the importance of providing meaningful feedback when reviewing new opportunities. However, finding the time to offer comprehensive insights can be challenging. That's why we've designed Whatapitch to incentivize and facilitate the delivery of valuable feedback. With our platform, you can trust that your expertise will be valued and rewarded, empowering you to make a meaningful impact on the ventures you review.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="OurTestimonials abountUs-bg-color-gradient-4">
          <div className="container">
            <h2 className="sectionTitle mb-16" data-aos="fade-in" data-aos-easing="ease-in-sine">Our Testimonials</h2>
            <h3 className="subTitle mb-20" data-aos="fade-in" data-aos-easing="ease-in-sine">What Clients Saying about us</h3>
            <Reviews reviews={reviewsArray} />
            <Stats />
          </div>
        </section>
        <Footer />
      </div>

    </>
  )
}

export default About;
