import Modal from 'react-bootstrap/Modal';
import AccountOnboarding from './AccountOnboarding';

const BillingModal = ({ show, setShow }) => {

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Wallet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ zoom: '110%' }}>
                        {<AccountOnboarding />}
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BillingModal;