import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { sendChangePasswordLink } from 'actions/forgetPasswordActions';
import logonew from "assets/images/logo-t.png";
import "./styles.scss";
import { useState } from 'react';
import * as Yup from 'yup';
import TextError from 'components/TextError';

type FormTypes = {
  email: string
};

const validationSchema = Yup.object({
  email: Yup.string().required('Email is required').email('Invalid email format')
});

function ForgetPasswordLink() {
  const [response, setResponse] = useState('');

  const onSubmit = async (values: FormTypes, { setSubmitting, setErrors }: any) => {
    try {
      const { email } = values;
      const data: any = await sendChangePasswordLink({ email });
      setResponse('');
      if (data.error) throw data;
      else {
        setErrors({ form: '' });
        setResponse(data.data);
      }
    } catch (data: any) {
      console.log('error')
      setErrors({ form: data.error });
    }
  }

  return (
    <>
      <div className="auth-screen">
        <div className="container">
          <div className="fullLogo text-center">
            <Link to="/">
              <img src={logonew} alt="whatapitch" />
            </Link>
          </div>
          <section>
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-5 col-md-6" data-aos="fade-up" data-aos-easing="ease-in-sine">
                <div className="card forgetPW">
                  <h3>Forget Password</h3>
                  <Formik
                    initialValues={{ email: '' }}
                    validationSchema={validationSchema}
                    validateOnBlur={true}
                    validateOnChange={false}
                    onSubmit={onSubmit}
                  >
                    {
                      ({ errors }: any) => (
                        <Form>
                          <div className="form-group" style={{marginBottom: '0'}}>
                            <label htmlFor="#" className="control-label">Email</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Enter your email address"
                              id="email"
                              name="email"
                            />
                          </div>
                          <div className="error-field">
                            <ErrorMessage name="email" component={TextError} />
                          </div>
                          {errors.form && <div className='error'>{errors.form}</div>}
                          {response && <div >{response}</div>}
                          <div className="button-row">
                            <button type={"submit"} className="btn btn-primary">Send</button>
                          </div>
                          <div className="text-center">
                            Go back to&nbsp;
                            <Link to="/auth" className="forgot-pw">
                              Sign in
                            </Link>
                          </div>
                        </Form>
                      )
                    }
                  </Formik>
                </div>
              </div>
            </div>
          </section>
          <div className="copyright text-center">
            © 2024 WhataPitch. All Rights Reserved
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPasswordLink;
