import { getAccountValidity } from "actions/billingActions";
import { updateUser } from "actions/settings";
import Loader from "components/loader/Loader";
import { useAppDispatch, useAppSelector } from "hooks";
import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { setExpertService } from "reducers/billingReducer";

const ExpertWallet = ({ setShow }) => {
    const { expertService = [], verificationLoading } = useAppSelector(({ service }) => service);
    const { user }: any = useAppSelector(({ auth }) => auth);
    const [planAmount, setPlanAmount] = useState(0);
    const [response, setResponse] = useState('');
    const [stripeCharges, setStripeCharges] = useState(0);
    const charges = Number(process.env.REACT_APP_SERVICE_CHARGES) || 50;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useMemo(() => {
        const accAmount = expertService.reduce((acc, obj) => {
            const amount = acc + obj.price;
            return amount;
        }, 0);
        setPlanAmount(accAmount);
    }, [expertService]);

    useEffect(() => {
    
      return () => {
        dispatch(setExpertService([]));
      }
    }, [])
    

    const onSubmitWallet = async () => {
        try {
            setResponse('');
            const response = await dispatch(getAccountValidity({}));
            if (!response.payload) {
                setResponse('Add a wallet in order to proceed');
            } else {
                let provide_services: any = [];

                expertService.forEach(expert => {
                    provide_services = [...provide_services, expert.type];
                });

                await updateUser({ provide_services: provide_services }, user.id);

                dispatch(setExpertService([]));
                //Move to dashboard
                navigate('/dashboard');
            }
        } catch (error: any) {
            console.log(error.cause);
        }
    };

    const expensiveCalculation = (price) => {
        let result = (price * 0.029) + 0.30;
        let finalResult = parseFloat(result.toFixed(2));

        return finalResult;
    }

    return (
        <div className="col-lg-5 col-md-6 services-section-container" data-aos="fade-left" data-aos-easing="ease-in-sine">
            <div className="card expert-paymentMethod">
                <span>
                    <h3 className='sm'>Services</h3>
                    <div className='services-wrapper'>
                        <div className="service-charges-section">
                            <p>Total Review My Pitch</p>
                            <p className="heading">USD<span>$ {planAmount}</span></p>
                        </div>
                        {expertService.map(eServ => (
                            <div key={eServ._id} className="service-per-request">
                                <div className="service-charges-detail">
                                    <p style={{ marginBottom: 0 }}>{eServ.type === "video" ? "Video" : "Deck"} Per Review</p>
                                    <p>$ {eServ.price}</p>
                                </div>
                                <div className="service-charges-detail service-charges-other charges">
                                    <p>Tax Charges</p>
                                    <p>- ${expensiveCalculation(eServ.price)}</p>
                                </div>
                                <div className="service-charges-detail service-charges-other charges">
                                    <p>Whatapitch service charges (50%)</p>
                                    <p>- $ {((eServ.price - expensiveCalculation(eServ.price)) * charges) / 100}</p>
                                </div>
                                <div className="service-charges-detail service-charges-other finalAmount">
                                    <p>Total Amount (Your Earnings)</p>
                                    <p>- $ {((eServ.price - expensiveCalculation(eServ.price)) * charges) / 100}</p>
                                </div>
                            </div>
                        ))}
                        {/* <div className="services-wrapper__left">
                            <p>Total Review my pitch</p>
                            {expertService.map(eServ => (
                                <div key={eServ._id}>
                                    <p style={{ marginBottom: 0 }}>{eServ.type === "video" ? "Video" : "Deck"} Per Review</p>
                                </div>
                            ))}
                        </div>
                        <div className="services-wrapper__right">
                            <p>USD<span>$ {planAmount}</span></p>
                            {expertService.map(eServ => (
                                <>
                                    <div style={{marginBottom:0}} key={eServ._id}>
                                        $ {eServ.price}
                                    </div>
                                </>
                            ))}
                        </div> */}
                    </div>
                    {/* {expertService.length !== 0 && (
                        <>
                            <div className="company-service-charge">
                                <span className="left">
                                    <span>Tax charges</span>
                                </span>
                                <span>- ${expensiveCalculation}</span>
                            </div>
                            <div className="company-service-charge">
                                <span className="left">
                                    <span>Whatapitch service charges</span>
                                    <span>(50% Charges)</span>
                                </span>
                                <span>- $ {((planAmount - stripeCharges) * charges) / 100}</span>
                            </div>
                            <div className="total-service-wrapper">
                                <div className="expert-earning">
                                    <span className="left">
                                        <span>Total Amount</span>
                                        <span>(Your Earnings)</span>
                                    </span>
                                    <span>$ {((planAmount - stripeCharges) * charges) / 100}</span>
                                </div>
                            </div>
                        </>
                    )} */}
                </span>

                <div className="wallet">
                    <h3 className='sm'>Add your wallet</h3>
                    <p>Add your credit card details below, This card will be saved to your account and can be removed at any time.</p>
                    <div className="button-row">
                        {/* <button className="btn btn-secondary">Skip</button> */}
                        <button onClick={() => setShow(true)} className="btn btn-primary">Add Wallet</button>
                        <button onClick={() => onSubmitWallet()} className="btn btn-primary">{(verificationLoading) ? <Loader /> : 'Get started'}</button>
                    </div>
                </div>
                {response && (
                    <div className="error" style={{ marginTop: '10px' }}>{response}</div>
                )}
            </div>
            <div className="copyright text-center">
                © 2024 WhataPitch. All Rights Reserved
            </div>
        </div>
    );
};

export default ExpertWallet;