import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "axios-instance";

//this file contains the founder's dashboard API

export const getPitchesAction = createAsyncThunk<any, any>('pitch/detail', async (obj, { rejectWithValue }) => {
    try {
        const { _id, type, value, name, userType } = obj;

        var filterData = {
            userId: _id
        }

        if (type === "type") {
            filterData = {
                ...filterData,
                [type]: value
            }
        }

        if (type === "status") {
            filterData = {
                ...filterData,
                [type]: value
            }
        }

        if (userType === 'founder') {
            const { data } = await axiosInstance.get('/api/dashboard', {
                params: {
                    name: name,
                    ...filterData
                }
            });
            return data.data;
        } else {
            const { data } = await axiosInstance.get("/api/dashboard/expert", {
                params: {
                    name: name,
                    ...filterData
                }
            });
            return data.data;
        }

    } catch (error: any) {
        const { response } = error;
        return rejectWithValue({ message: response.data.error, status: response.status })
    }
});

export const getFounderStatsAction = createAsyncThunk<any, any>('founder/stats', async (obj, { rejectWithValue }) => {
    try {
        const { _id } = obj;

        var filterData = {
            userId: _id
        }

        const { data } = await axiosInstance.get('/api/dashboard/founderStats', {
            params: {
                ...filterData
            }
        });

        return data.data;
    } catch (error: any) {
        const { response } = error;
        return rejectWithValue({ message: response.data.error, status: response.status })
    }
});
