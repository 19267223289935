import { createSlice } from "@reduxjs/toolkit";
type InitialState = {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
};
const initialState: InitialState = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
};
const passwordSlice = createSlice({
    name: 'passwordStore',
    initialState: initialState,
    reducers: {
        updatePasswordStore: (state, action) => {
            switch (action.payload.type) {
                case 'oldPassword':
                    state.oldPassword = action.payload.value;
                    break
                case 'newPassword':
                    state.newPassword = action.payload.value;
                    break;
                case 'confirmPassword':
                    state.confirmPassword = action.payload.value;
            }
        }
    }
});

export default passwordSlice.reducer;
export const { updatePasswordStore } = passwordSlice.actions;