import CustomFormikField from "components/CustomFormikField/CustomFormikField";

const TitleInfo = ({ setFieldValue }) => {
    return (
        <div>
            <div className="form-group extra-mb">
                <label htmlFor="#" style={{ opacity: 0 }} className="control-label big">Which title do you now hold?</label>
                <CustomFormikField
                    name="designation"
                    type="text"
                    className="form-control only-bottom-b"
                    placeholder="Enter your current designation."
                    setFieldValue={setFieldValue}
                />
            </div>
            <div className="form-group extra-mb">
                <label htmlFor="#" style={{ opacity: 0 }} className="control-label big">Describe yourself! Be sure to share anything that a founder may find useful in getting to know you</label>
                <CustomFormikField
                    name="description"
                    type="text"
                    className="form-control only-bottom-b"
                    placeholder="Describe yourself"
                    setFieldValue={setFieldValue}
                />
            </div>
        </div>
    );
};

export default TitleInfo;