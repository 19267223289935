import React, { useState, useRef, useEffect, useContext } from 'react';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';
import ReviewContext from 'contexts/reviewContext';

//This file contains the logic to display the document in a slideshow on the review page.

function ImageSlideShow({ imageSlider, MarkerComponent }) {
	const [currentImage, setCurrentImage] = useState(0);
	const { setPage, page } = useContext(ReviewContext);
	const [loading, setLoading] = useState(false);
	const playerRef: any = useRef();

	useEffect(() => {
		setLoading(true);
		playerRef.current.src = imageSlider[currentImage].url;

		//eslint-disable-next-line
	}, [currentImage])

	const handlePrev = () => {
		if (currentImage !== 0) {
			setCurrentImage(currentImage - 1);
			setPage(page - 1);
		}
	}

	const handleNext = () => {
		if (currentImage !== imageSlider.length - 1) {
			setCurrentImage(currentImage + 1);
			setPage(page + 1);
		}
	}

	const handleImageLoad = () => {
		setLoading(false); // Set loading to false once the image is loaded
	}

	const handleImageError = () => {
		setLoading(false); // Set loading to false in case of an error
	}


	return (
		<div className="image">
			{imageSlider.length !== 0 && (
				<div className='slider'>
					<CaretLeftFill className='svg-icon' onClick={handlePrev} />
					<div className='slider-number'>
						<span>SLIDE</span>
						<span>{page}</span>
						<span>OF</span>
						<span>{imageSlider.length}</span>
					</div>
					<CaretRightFill className='svg-icon' onClick={handleNext} />
				</div>
			)}
			<MarkerComponent loading={loading}>
				<div className={`image-container ${loading? 'loading': ''}`}>
					<img alt="reviewer-doc" ref={playerRef} onLoad={handleImageLoad} onError={handleImageError} className={loading ? 'blur' : ''} />
				</div>
			</MarkerComponent>
		</div>
	)
}

export default ImageSlideShow