import { useState } from "react";
import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import axiosInstance from "axios-instance";

const AccountOnboarding = () => {
    const [stripeConnectInstance] = useState<any>(() => {
        const fetchClientSecret = async () => {
            try {
                const { data } = await axiosInstance(`${process.env.REACT_APP_BASE_URL}/api/payments/accountSession`, { method: "POST" });
                const { client_secret: clientSecret } = data;
                return clientSecret;
            } catch ({ response: { data } }: any) {
                console.log('An error has occured', data);
            }
        };
        return loadConnectAndInitialize({
            publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
            fetchClientSecret: fetchClientSecret,
            appearance: {
                overlays: 'dialog',
                variables: {
                    colorPrimary: '#625afa',
                },
            },
        });
    });

    return (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding
                onExit={() => {
                    console.log("The account has exited onboarding");
                }}
                collectionOptions={{
                    fields: 'eventually_due',
                    futureRequirements: 'include',
                }}
            />
        </ConnectComponentsProvider>
    );
};

export default AccountOnboarding;
