import { Link, useLocation } from 'react-router-dom';
import logonew from "assets/images/svg/new-logo.svg";
import Plan from 'views/founder/billing/Plan';
import Card from 'views/founder/billing/Card';
import 'views/founder/billing/style.scss';
import TransactionSuccessfull from './transactionComplete/TransactionSuccessfull';
import BillingContext from 'contexts/billingContext';
import { useEffect, useState } from 'react';


function Billing() {
	const [billingDetail, setBillingDetail] = useState<boolean>(false);
	const location = useLocation();

	useEffect(() => {
	  const body = document.querySelector('body');
	  const html = document.querySelector('html');
	  if(location.pathname.includes('billing')) {
		if(body && html) {
		  body.classList.add('body-height');
		  html.style.overflowY = 'hidden';
		}
	  }
	  return () => {
		if(body && html) {
		  body.classList.remove('body-height');
		  html.style.overflowY = 'auto';
		}
	  }
	}, [location.pathname]);

	return (
		<>
			<BillingContext.Provider value={{ setBillingDetail, billingDetail }}>
				<div className="billing-screen">
					<div className="container">
						<div className="fullLogo text-center">
							<Link to="/">
								<img src={logonew} alt="whatapitch" />
							</Link>
						</div>
						<section>
							<div className="row align-items-flex-start g-5">
								<Plan />
								{!billingDetail && (
									<Card />
								)}
								{billingDetail && (
									<TransactionSuccessfull />
								)}
							</div>
						</section>
					</div>
				</div>
			</BillingContext.Provider>
		</>
	)
}

export default Billing;