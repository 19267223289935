import logonew from "assets/images/svg/new-logo.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { useEffect, useRef, useState } from 'react';
import { Formik, Form } from "formik";
import { founderSignupAction } from 'actions/authActions'
import PersonalInfo from "views/founder/signup/PersonalInfo";
import VentureInfo from "views/founder/signup/VentureInfo";
import StageInfo from "views/founder/signup/StageInfo";
import RoundInfo from "views/founder/signup/RoundInfo";
import * as Yup from 'yup';
import { useAppDispatch } from "hooks";
import { isEmpty, toLower } from "lodash";

const initialValues = {
  founderName: '',
  linkedinProfile: '',
  companyName: '',
  companyWebsite: '',
  isIncorporated: true,
  incorporationDetails: '',
  stage: 0,
  roundSize: null,
  roundProgress: '',
  companyDescription: null,
};

const slidesHeadings = ['Founder - Tell us about yourself', 'Founder - Tell us about your company', 'Founder - Tell us about your stage', 'Founder - Tell us about your round'];


const PersonalSchema = Yup.object({
  founderName: Yup.string().required('Name is required').max(20, 'Maximum characters limit should be 20')
    .min(3, "Minimum characters limit should be 03"),
  linkedinProfile: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?linkedin\.com(?:\/[a-zA-Z0-9_-]+)?\/[a-zA-Z0-9_-]+\/?$|^$/,
    'Enter valid linkedin url format e.g. https://www.linkedin.com/'
  )
});
const VentureSchema = Yup.object({
  companyName: Yup.string().required('Company name is required').max(20, 'Maximum characters limit should be 20')
    .min(3, "Minimum characters limit should be 03"),
  companyWebsite: Yup.string().required('Company website is required').url('Enter valid url format e.g. https://abc.com')
});
const StageSchema = Yup.object({
  incorporationDetails: Yup.string().required('Incorporation Details are required'),
  isIncorporated: Yup.string().required('Incorporation field is required')
});

const RoundSchema = Yup.object({
  roundSize: Yup.string().required("Round size is required")
  .matches(/^\d{1,3}(,\d{3})*$/, "Round Size should be a valid number only")
  .typeError('Round Size should be a number'),
  roundProgress: Yup.string().required('Round Progress is required'),
  companyDescription: Yup.array().required('Company description is required')
});

function Founder() {
  const sliderRef = useRef<any>();
  const location = useLocation();
  let { email = '', password = '' } = location.state || {};
  email = toLower(email);
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(email) || isEmpty(password)) navigate('/authInfo', { state: { type: "founder" } });
    // eslint-disable-next-line
  }, []);

  const updateProgressBar = (i: any) => {
    const nextSlide = i + 1;
    const calc = nextSlide * 20;

    const progressBar = document.querySelector('.progress') as HTMLElement;
    const progressBarLabel = document.querySelector('.slider__label') as HTMLElement;

    progressBar.style.backgroundSize = calc + '% 100%';
    progressBar.setAttribute('aria-valuenow', calc.toString());
    progressBarLabel.textContent = calc + '% completed';
  };

  var settings: any = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    draggable: false
  };

  const normalizeValues = (values: any) => {
    const stage = ['Idea', 'Pre-Seed', 'Seed', 'Series-A', 'Series-B', 'Series-C', 'Beyond']
    values.stage = stage[values.stage - 1] || 0;
    values.isIncorporated = (values.isIncorporated === 'true') ? true : false;
    values.name = values.founderName || '';
    values.userType = 'founder';
    return values;
  }
  const onSubmit = async (values: any, { setErrors }: any) => {
    try {
      const normalizedValues = normalizeValues(values);
      const founderData = { email, password, ...normalizedValues };
      const response = await dispatch(founderSignupAction(founderData));
      if (response.type.includes('rejected')) throw response;
      else {
        setErrors({ form: '' });
        navigate('/billing');
      }
    } catch (error: any) {
      setErrors({ form: error.payload })
    }

  }

  const onClick = async (values: any, setErrors: Function, touched: any, setTouched: Function) => {
    try {
      if (currentSlide === 0) {
        await PersonalSchema.validate(values, { abortEarly: false });
      } else if (currentSlide === 1) {
        await VentureSchema.validate(values, { abortEarly: false });
      } else if (currentSlide === 2) {
        await StageSchema.validate(values, { abortEarly: false });
      } else if (currentSlide === 3) {
        await RoundSchema.validate(values, { abortEarly: false });
        return onSubmit(values, { setErrors });
      }
      sliderRef.current.slickNext();
    } catch (error: any) {
      console.log(error)
      const errors: { [key: string]: string } = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e) => {
          errors[e.path || ''] = e.message;
        });
      }
      setErrors(errors);
    }
  }

  return (
    <>
      <div className="signup_flow">
        <div className="container-fluid">
          <div className="fullLogo">
            <Link to="/" className="justify-content-start">
              <img src={logonew} alt="whatapitch" />
            </Link>
          </div>
          <section>
            <div className="card " data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="card-header">
                <h1>{slidesHeadings[currentSlide]}</h1>
              </div>
              <div className="progress-wrapper">
                <div className="progress" role="progressbar" aria-valuemin={25} aria-valuemax={100}>
                  <span className="slider__label"></span>
                </div>
                <ul className="list-unstyled">
                  <li className="completed"></li>
                  <li className={currentSlide > 0 ? 'completed' : ''}></li>
                  <li className={currentSlide > 1 ? 'completed' : ''}></li>
                  <li className={currentSlide > 2 ? 'completed' : ''}></li>
                </ul>
              </div>

              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur={true}
                  onSubmit={onSubmit}
                  initialTouched={{ founderName: true }}
                >
                  {({ errors, setTouched, touched, values, setErrors, setFieldValue }: any) => (
                    <Form className="founder-form">
                      <div className="slider">
                        <Slider {...settings} ref={sliderRef} afterChange={(current) => { updateProgressBar(current); setCurrentSlide(current); }} >
                          <PersonalInfo setFieldValue={setFieldValue} setTouched={setTouched} />
                          <VentureInfo setFieldValue={setFieldValue} />
                          <StageInfo setFieldValue={setFieldValue} />
                          <RoundInfo setFieldValue={setFieldValue} />
                        </Slider>
                      </div>
                      <div className="button-row not-stack justify-content-start">
                        {currentSlide !== 0 && (
                          <button type="button" className='btn btn-secondary list-inline-item sm prev-button' onClick={() => sliderRef.current.slickPrev()}>Back</button>
                        )}
                        <button type={"button"} className='btn btn-primary list-inline-item sm next-button' onClick={() => onClick(values, setErrors, touched, setTouched)} >{(currentSlide === 3) ? 'Submit' : 'Next'}</button>
                      </div>
                      {errors.form && <div className="error">{errors.form}</div>}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Founder
