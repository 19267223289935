import { createTransfer, getTransferStatus } from "actions/billingActions";
import { updatePitchStatus } from "actions/pitchActions";
import Loader from "components/loader/Loader";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import moment from 'moment';
import { useNavigate } from "react-router";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

//"This file contains the logic to send the payment to the expert after feedback has been given."

const FounderFeedback = () => {
    const { selectedPitch = {} }: any = useAppSelector(({ founderDasboard }) => founderDasboard);
    const { feedbackData: { updatedDate: feedbackDate } } = useAppSelector(({ reviews }) => reviews);
    const navigate = useNavigate();
    const [component, setComponent] = useState('form');
    const [isTransferred, setTransferStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    const getStatusAPI = async () => {
        try {
            const status = await getTransferStatus({ pitch: selectedPitch._id });
            setTransferStatus(status);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getStatusAPI();
    }, []);

    const onSubmitClick = async () => {
        try {
            setLoading(true);
            const status = 'unpaid';
            const { expert: { _doc: { _id: expertId } } } = selectedPitch || {};
            await updatePitchStatus(selectedPitch._id, status, expertId);
            setLoading(false);
            navigate('/dashboard');
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
    };

    const renderPositiveResponse = () => {
        return (
            <div className="review-feedback-accepted">
                <p>Thanks for the response, payment has been sent to the {selectedPitch.expert._doc.name}</p>
            </div>
        )
    };
    const renderNegativeResponse = () => {
        return (
            <div className="review-feedback-rejected">
                <textarea placeholder="Please provide detailed reason"></textarea>
                <div className="button-container">
                    <button onClick={() => onSubmitClick()}>{(loading) ? <Loader /> : 'Submit'}</button>
                </div>
            </div>
        );
    };
    const sendPaymentToExpertAPI = async () => {
        try {
            setLoading(true);
            const { transactionId: charge, amount, chargeId: source_transaction,
                _id: pitch, expert: { _doc: { stripeConnectAccountId, _id: user } } }: any = selectedPitch;
            const status = 'paid';

            const response = await createTransfer({
                charge, pitch, source_transaction,
                amount, user, stripeConnectAccountId
            });
            await updatePitchStatus(selectedPitch._id, status, user);
            setLoading(false);
            console.log(response);
            setComponent('yes');
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    const onClick = (type) => {
        switch (type) {
            case 'yes':
                sendPaymentToExpertAPI();
                break;
            default:
                setComponent('no');
        }
    };
    const loaderStyles = {
        width: '20px',
        height: '20px' 
    };
    const renderFeedbackForm = () => {
        const { expert: { _doc: { name = '', profileImageUrl = '' } = {} } = {} as any } = selectedPitch || {};
        const absoluteImageUrl = profileImageUrl ? `${IMAGE_URL}/${profileImageUrl}` : "/user.svg";
        const formattedDate = moment(feedbackDate).local().format('MMMM Do YYYY, hh:mm a');

        return (
            <div className="review-feedback-conatainer">
                <div className="satisfaction-container">
                    <div className="satisfaction-wrapper">
                        <div className="reviwer-info">
                            <div className="icon">
                                <img src={absoluteImageUrl} alt="reviewer" />
                            </div>
                            <div className="reviwer-content">
                                <p className="heading">{name} reviewed the Deck and marked it done</p>
                                <p className='time'>{formattedDate}</p>
                            </div>
                        </div>
                        <p className="confirmation-message">Are you Satisfied to release the payment ?</p>
                        <div className="buttons-container">
                            <button onClick={() => onClick('yes')} className="default">{(loading) ? <Loader lengthStyle={loaderStyles} /> : 'Yes'}</button>
                            <button onClick={() => onClick('no')} disabled={selectedPitch.status === "unpaid"} className="invert">No</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const renderComponent = () => {
        switch (component) {
            case 'form':
                return renderFeedbackForm();
            case 'yes':
                return renderPositiveResponse();
            default:
                return renderNegativeResponse();
        }
    };
    return (
        <div>
            {(!(isTransferred)) && renderComponent()}
        </div>
    );
};

export default FounderFeedback;