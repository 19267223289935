import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "axios-instance";

//this file contains the user profile API

export const getProfile = createAsyncThunk('profile/get', async (obj: any, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`/api/users/${obj.userId}`);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        rejectWithValue(data);
    }
});