import './dashboardReviews.scss';
import DashboardReviewComments from 'components/dashboard-reviews-comments/DashboardReviewComments';
import DashboardReviewViewer from 'components/dashboard-reviews-viewer/DashboardReviewViewer';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from "hooks";
import { getS3Url } from 'actions/uploadActions';
import { setPitchUrl, setMarker, clearMarker } from 'reducers/reviewsReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import ReviewContext from 'contexts/reviewContext';
import { getReviewAction } from "actions/reviewActions";
import { some } from 'lodash';

//This file contains the Review Page data
function DashboardReviews() {
	const dispatch = useAppDispatch();
	const commentInputRef = useRef<HTMLTextAreaElement>(null);
	const [isPinActive, setIsPinActive] = useState<boolean>(false);
	const { selectedPitch } = useAppSelector(({ founderDasboard }) => founderDasboard);
	const { markers } = useAppSelector(({ reviews }) => reviews);
	const videoRef = useRef<any>(null);
	const [page, setPage] = useState<number>(1);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		if (Object.keys(selectedPitch).length === 0) {
			navigate('/dashboard', { replace: true });
			return;
		}

		(async () => {
			try {
				var response = await getS3Url(selectedPitch);

				if (response)
					dispatch(setPitchUrl(response));

			} catch (err) {
				console.log(err);
			}
			setLoading(false);
		})();

		//eslint-disable-next-line
	}, [selectedPitch, navigate])

	useEffect(() => {
		(async () => {
			try {
				await dispatch(getReviewAction(selectedPitch._id));
			} catch (e) {
				console.log(e);
			}
		})();

		//eslint-disable-next-line
	}, [])

	const formatTime = (time) => {
		const seconds = Math.floor(time % 60);
		const minutes = Math.floor(time / 60);
		return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
	};

	const toggleIsPinActive = (): void => {
		setIsPinActive((prev) => {
			if (prev) {
				dispatch(clearMarker());
			}

			return !prev;
		});
		if (commentInputRef.current) {
			commentInputRef.current.disabled = false;
		}

		if (videoRef.current) {
			const video = videoRef.current;
			const currentTime = formatTime(video.currentTime);
			const durationTime = formatTime(video.duration);

			let newMarker = { number: `${currentTime}/${durationTime}` };
			const isMarkerPresent = some(markers, (obj) => obj.number === newMarker.number);

			if (!isMarkerPresent) {
				let allMarkers = [...markers, newMarker]
				dispatch(setMarker({ allMarkers: allMarkers, marker: newMarker }));
			}

			video.pause();
		}
	};

	useEffect(() => {
		if (commentInputRef.current) {
			commentInputRef.current.focus();
		}
	}, [markers]);
	const location = useLocation();
	useEffect(() => {
		const pathArray = location.pathname.split('/');
		const currentPage = pathArray[pathArray.length - 1];
		const html = document.querySelector('html');
		if (currentPage === 'reviews') {
			if (html) {
				html.style.overflowY = "hidden";
			}
		}

		return () => {
			if (html) {
				html.style.overflowY = "auto";
			}
		}
	}, [location]);


	return (
		<ReviewContext.Provider value={{ videoRef, commentInputRef, isPinActive, toggleIsPinActive, markers, page, setPage, setIsPinActive }}>
			<div className="container-fluid container-dashboard-class">
				<div className='reveiws'>
					<div className="row g-3 review-row-container">
						<DashboardReviewViewer loading={loading} name={selectedPitch.name} type={selectedPitch.type} />
						<DashboardReviewComments type={selectedPitch.type} />
					</div>
				</div>
			</div>
		</ReviewContext.Provider>
	);
}

export default DashboardReviews;
