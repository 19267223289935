import React, { useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import AOS from "aos";
import TestComponent from 'components/Test';
import Home from "views/landingPage/Home";
import Auth from "views/entry/Auth";
import About from "views/about/About";
import Pricing from "views/pricing/Pricing";
import UpdateForgottenPassword from "views/entry/UpdateForgottenPassword";
import ForgetPasswordLink from "views/entry/ForgetPasswordLink";
import Founder from "views/founder/Founder";
import Expert from "views/expert/Expert";
import DashboardLayout from 'views/dashboard/dashboardLayout/DashboardLayout';
import DashboardHome from 'views/dashboard/dashboardHome/DashboardHome';
import DashboardReviews from 'views/dashboard/dashboardReviews/DashboardReviews';
import DashboardPayments from 'views/dashboard/dashboardPayments/DashboardPayments';
import DashboardSettings from 'views/dashboard/dashboardSettings/DashboardSettings';
import DashboardWallet from 'views/dashboard/dashboardWallet/DashboardWallet';
import Billing from 'views/founder/billing/Billing';
import DeckInfo from 'views/founder/deck-info/DeckInfo';
import AuthInfo from 'views/founder/signup/AuthInfo';
import PrivateRoute from 'components/defaultLayout/PrivateRoute';
import Services from 'views/expert/billing/ExpertBilling';
import { jwt_decode, setAxiosHeaders } from 'utils/helpers';
import { setAuthToken, setUser } from 'reducers/authReducer';
import { useAppDispatch } from 'hooks';
import './App.css';
import PrivacyPolicy from 'views/about/privacy-policy/PrivacyPolicy';
import TermsOfService from 'views/about/termsOfService/TermsOfService';

declare global {
	interface Window {
		AOS: any; // Adjust 'any' based on the actual type of AOS
	}
}

type DecodedToken = {
	id?: string;
	email?: string;
	iat?: number;
}

function App() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		window.AOS =
			window.AOS || ``
		AOS.init({
			delay: 60, // values from 0 to 3000, with step 50ms
			duration: 600, // values from 0 to 3000, with step 50ms
			disable: "mobile",
			once: false,
			mirror: true,
		});
		document.addEventListener("mousemove", () => {
			window.AOS =
				window.AOS ||
				AOS.init({
					delay: 60, // values from 0 to 3000, with step 50ms
					duration: 600, // values from 0 to 3000, with step 50ms
					disable: "mobile",
					once: false,
					mirror: true,
				});
		});
		return () => {
			document.removeEventListener("mousemove", () => {
				window.AOS = {};
			});
		};
	}, []);

	if (localStorage.jwtToken) {
		setAxiosHeaders(localStorage.jwtToken);
		dispatch(setAuthToken(localStorage.jwtToken));
		const decoded: DecodedToken = jwt_decode(localStorage.jwtToken);
		dispatch(setUser(decoded));
	};

	return (
		<HashRouter>
			<Routes>
				<Route element={<PrivateRoute />}>
					<Route path="billing" element={<Billing />}></Route>
					<Route path="dashboard" element={<DashboardLayout />}>
						<Route index element={<DashboardHome />} />
						<Route path="reviews" element={<DashboardReviews />} />
						<Route path="payments" element={<DashboardPayments />} />
						<Route path="settings" element={<DashboardSettings />} />
						<Route path="wallet" element={<DashboardWallet />} />
					</Route>
					<Route path="deck-info" element={<DeckInfo />}></Route>
					<Route path="expertBilling" element={<Services />}></Route>
				</Route>
				<Route path="expert" element={<Expert />}></Route>
				<Route path="founder" element={<Founder />}></Route>
				<Route path="authInfo" element={<AuthInfo />}></Route>
				<Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
				<Route path="termsOfService" element={<TermsOfService />}></Route>
				<Route path="/" element={<Home />}></Route>
				<Route path="test" element={<TestComponent />} />
				<Route path="auth" element={<Auth />}></Route>
				<Route path="about" element={<About />}></Route>
				<Route path="pricing" element={<Pricing />}></Route>
				<Route path="forgetPassword/:token" element={<UpdateForgottenPassword />}></Route>
				<Route path="forgetPasswordLink" element={<ForgetPasswordLink />}></Route>
			</Routes>
		</HashRouter>
	);
}

export default App;
