import { useRef, useEffect, useContext } from 'react';
import ReviewContext from 'contexts/reviewContext';
import './style.scss';

//this file contains the logic to display the video in reviewer page.

function VideoPlayer({ attachmentSrc }) {
    const { videoRef} = useContext(ReviewContext);
    const progressBarRef = useRef<any>(null);

    useEffect(() => {
        const video: any = videoRef.current;
        const progressBar = progressBarRef.current;

        const updateProgressBar = () => {
            if (video.duration) {
                const percentage = (video.currentTime / video.duration) * 100;
                progressBar.style.backgroundSize = `${percentage}% 100%`;
            }
        };

        video.addEventListener('timeupdate', updateProgressBar);

        return () => {
            video.removeEventListener('timeupdate', updateProgressBar);
        };

        //eslint-disable-next-line
    }, []);

    const handleProgressBarClick = (e) => {
        const bounds = progressBarRef.current.getBoundingClientRect();
        const pos = (e.clientX - bounds.left) / bounds.width;
        if(videoRef.current) 
        videoRef.current.currentTime = pos * videoRef.current.duration;
    };

    return (
        <>
            <video src={attachmentSrc} ref={videoRef} className='video-player' controls />
            <div className='controls d-none'>
                <div
                    ref={progressBarRef}
                    className="progress-bar"
                    onClick={handleProgressBarClick}
                    style={{ position: 'relative', width: '100%', height: '5px', backgroundColor: '#ccc', cursor: 'pointer' }}
                >
                </div>
            </div>
        </>
    )
}

export default VideoPlayer;