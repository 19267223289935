import { useAppDispatch, useAppSelector } from 'hooks';
import './dashboardWallet.scss';
import ListItem from './listItem/ListItem';
import { useEffect, useMemo } from 'react';
import { getAllTransfers, getBankDetails } from 'actions/wallet';
import Loader from 'components/loader/Loader';

//this file contains the information of wallet on experts wallet page.
function DashboardWallet() {
    const dispatch = useAppDispatch();
    const { bank, transfers = [], loading = false }: any = useAppSelector(({ wallet }) => wallet);

    const cacheAmount = useMemo(() => {
        return transfers.reduce((acc: any, curr: any) => {
            acc += curr.charge.amount;
            return acc;
        }, 0);
    }, [transfers]);

    useEffect(() => {
        dispatch(getAllTransfers({}));
        dispatch(getBankDetails({}));
    }, []);

    const renderListItems = () => {
        return transfers.map((transfer: any, index) => {
            return (<ListItem data={transfer} key={transfer._id} />);
        })
    };

    return (
        <div className="dashboard-home">
            <div className="header-container">
                <div className="header">
                    <h1 className="title">
                        Wallet
                    </h1>
                </div>
            </div>
            <div className="dashboard-home__content">
                <div className="wallet-wrapper">
                    <div className="row gx-4">
                        <div className="col-md-4">
                            <div className="card d-flex flex-row justify-content-between">
                                <h4>
                                    ${cacheAmount}
                                    <label htmlFor="">
                                        Total Revenue
                                    </label>
                                </h4>
                                <span className='wallet-icon'>
                                    <svg width="27.143" height="23.477" viewBox="0 0 27.143 23.477">
                                        <g id="wallet" transform="translate(-1.5 -4.5)">
                                            <path id="Path_1334" data-name="Path 1334" d="M28.248,8.51a1.345,1.345,0,0,0-.959-.4h-.958V5.854A1.356,1.356,0,0,0,24.977,4.5H5.486A3.986,3.986,0,0,0,1.5,8.486V23.991a3.986,3.986,0,0,0,3.986,3.986h21.75a1.357,1.357,0,0,0,1.354-1.35l.053-17.156A1.345,1.345,0,0,0,28.248,8.51ZM26.785,26.171H5.486a2.18,2.18,0,0,1-2.18-2.18V8.486a2.18,2.18,0,0,1,2.18-2.18H24.525V8.112H5.563V9.918H26.836Z" />
                                            <path id="Path_1335" data-name="Path 1335" d="M36.75,24.75h1.806v1.806H36.75Z" transform="translate(-14.031 -8.06)" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="detail-list">
                                    <ul className='list-unstyled'>
                                        <li>Bank Name</li>
                                        <li>{bank?.bankName || ''}</li>
                                    </ul>
                                    <ul className='list-unstyled'>
                                        <li>Account number</li>
                                        <li>************{bank?.last4 || ''}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-list">
                    {(loading) ? <Loader /> : renderListItems()}
                </div>
            </div>
        </div>
    );
}

export default DashboardWallet;
