import signup1 from "assets/images/signup-1.png";
import signup2 from "assets/images/signup-2.png";
import { useNavigate } from "react-router";

const Signup = () => {
    const navigate = useNavigate();
    return (
        <div className="col-xxl-6 col-lg-7" data-aos="fade-right" data-aos-easing="ease-in-sine">
            <h3>Sign Up</h3>
            <div className="row gx-3">
                <div className="col-sm-6">
                    <div className="card no-shadow signup-Card">
                        <h4 className="card-title">Founder</h4>
                        <div className="image">
                            <img src={signup1} alt="I am a founder looking to perfect my pitch, find funds and get back to work!" />
                        </div>
                        <p>I am a founder looking to perfect my pitch, find funds and get back to work!</p>
                        <div onClick={() => navigate('/authInfo', { state: { type: "founder" } })} className="button-row">
                            <button className="btn btn-primary yellow">Perfect My Pitch</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card no-shadow signup-Card">
                        <h4 className="card-title">Expert</h4>
                        <div className="image">
                            <img src={signup2} alt="I know a thing or two about raising funds and I can provide valuable feedback." />
                        </div>
                        <p>I know a thing or two about raising funds and I can provide valuable feedback.</p>
                        <div onClick={() => navigate('/authInfo', { state: { type: "expert" } })} className="button-row">
                            <button className="btn btn-primary">Get Paid to Review</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;