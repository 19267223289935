import CustomInputField from 'components/custom-input-field/CustomInputField';
import TextError from 'components/TextError';
import Loader from 'components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { setCreatedPitch } from 'reducers/founderDashboardReducer';

function CreateDeck({ formik, loading }: any) {
	const { name, website, description } = formik.errors;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const textAreaStyles = {
		height: '145px',
		padding: '10px',
		position: 'relative',
		fontSize: '1rem',
		backgroundColor: '#ffffff',
		outline: 'none',
		border: '1px solid #A8A9A8',
		borderRadius: '8px',
		fontWeight: '500',
		color: '#191919',
	}

	const handleDashboardNavigation = () => {
		dispatch(setCreatedPitch({}));
		navigate('/dashboard');
	}

	return (
		<div className="card paymentMethod">
			<h3 className='sm'>Deck information</h3>
			<div className="form-wrapper">
				<div className="form-inner-wrapper">
					<div>
						<div className="form-group">
							<label htmlFor="name" className="control-label">Deck name</label>
							<CustomInputField
								inputClass="form-control"
								inputProps={{
									type: "text",
									placeholder: "Enter the pitch name",
									...formik.getFieldProps("name"),
									error:
										!!formik.errors.name && !!formik.touched.name
											? formik.errors.name
											: null,
								}}
							/>
							{name && formik.touched.name && <TextError>{name}</TextError>}
						</div>
						<div className="form-group">
							<label htmlFor="website" className="control-label">Website</label>
							<CustomInputField
								inputClass="form-control"
								disabled={true}
								inputProps={{
									type: "text",
									placeholder: "Enter the Website Url",
									...formik.getFieldProps("website"),
									error:
										!!formik.errors.website && !!formik.touched.website
											? formik.errors.website
											: null,
								}}
							/>
							{website && formik.touched.website && <TextError>{website}</TextError>}
						</div>
						<div className="form-group">
							<label htmlFor="description" className="control-label">Description</label>
							<textarea id="description" style={textAreaStyles} row="30" placeholder="Enter the pitch desctiption" {...formik.getFieldProps("description")} />
							{description && formik.touched.description &&  <TextError>{description}</TextError>}
						</div>
						<div className="form-group">
							<label htmlFor="#" className="control-label">Industry experts</label>
							<div className='form-control industry'>
								{formik.values.industry_experts.map((companyDes: string, index: number) => <span className='industry-label' key={index}>{companyDes}</span>)}
							</div>
						</div>
					</div>
					<div className="button-row">
						<button style={{maxHeight: '46px'}} type="button" className='btn btn-primary' onClick={handleDashboardNavigation}> Skip</button>
						<button style={{maxHeight: '46px'}} className="btn btn-primary" disabled={loading} type="submit" >{loading ? <Loader /> : "Submit your deck"}</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateDeck