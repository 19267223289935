import React, { useState, useEffect, useContext, useRef } from 'react';
import ReviewContext from 'contexts/reviewContext';
import Comment from "components/dashboard-reviews-comments/comment/Comment";
import { Tab, Tabs } from 'react-bootstrap';
import deck from "assets/images/deck.png";
import CreateComment from './create-comment/CreateComment';
import { useAppDispatch, useAppSelector } from 'hooks';
import { createFeedbackComment, clearMarker, setAllMarker } from 'reducers/reviewsReducer';
import { getFeedbackAction, createComment } from "actions/reviewActions";
import Loader from 'components/loader/Loader';
import { scrollToElementById } from 'utils/helpers';
import useSmoothScroll from 'hooks/useSmoothScroll';
import DashboardUserReviews from 'components/dashboard-user-reviews/DashboardUserReviews';
import { isEmpty } from 'lodash';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

//this file contains the logic of review comments, replies, review and create comment code.

function DashboardReviewComments({ type }) {
	const [isVisible, setIsVisible] = useState(false);
	const [displayText, setDisplayText] = useState('Connect');
	const [displaySVG, setDisplaySVG] = useState(false);
	const [comment, setComment] = useState<string>('');
	const { commentInputRef, setIsPinActive } = useContext(ReviewContext);
	const dispatch = useAppDispatch();
	const { selectedPitch } = useAppSelector(({ founderDasboard }) => founderDasboard);
	const { selectedMarker, markers, comments, loading, feedbackId, reviewer } = useAppSelector(({ reviews }) => reviews);
	const { profile } = useAppSelector(({ profileStore }) => profileStore);
	const commentsContainerRef: any = useRef(null);
	const { smoothScroll } = useSmoothScroll();
	const absoluteImageUrl = reviewer?.profileImageUrl ? `${IMAGE_URL}/${reviewer?.profileImageUrl}` : "/user.svg";

	useEffect(() => {
		(async () => {
			try {
				await dispatch(getFeedbackAction({ id: selectedPitch._id, userId: profile.__t !== "founder" ? profile._id : "" }));
			} catch (er) {
				console.log(er);
			}
		})();

		//eslint-disable-next-line
	}, [])

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
			e.preventDefault();

			let markerValue = {
				...selectedMarker
			}

			try {
				var result = await createComment({ user: profile._id, message: comment, marker: markerValue, pitch: selectedPitch._id, type: type, feedbackId: feedbackId });

				result.data.data.user = profile;
				const allComments = [...(comments || []), result.data.data];


				if (!isEmpty(selectedMarker)) {
					let allMarkers = [...markers, selectedMarker];
					dispatch(setAllMarker({allMarkers: allMarkers}));
				}


				scrollToElementById('#slider-container');
				dispatch(clearMarker());
				dispatch(createFeedbackComment({ allComments: allComments }));

				if (commentInputRef.current)
					commentInputRef.current.value = '';

					setComment("");
				if (commentsContainerRef.current) {
					smoothScroll(commentsContainerRef.current, commentsContainerRef.current.scrollHeight);
				}

				setIsPinActive(false);
			} catch (err) {
				console.log(err);
			}
		}
	}

	const updateText = () => {
		setDisplayText('Connection Sent');
		setDisplaySVG(true);
	};
	const updateText1 = () => {
		setDisplayText('Connection Sent');
		setDisplaySVG(true);
	};

	const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(e.target.value);
	}

	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	const handleTitle = () => {
		if(profile.__t === "founder" && selectedPitch.status !== "reviewed") return "Review";

		if(profile.__t === "expert"  && selectedPitch.status !== "reviewed") return "Rate this Pitch";

 		return "Reviewed";
	}

	const handleDeckReview = (value) => {
		let Decklength = reviewer?.reviewerDecks.filter(deck => deck.type === value).length;

		if(Decklength === 0) {
			return 0;
		}
		
		if(Decklength?.toString().length === 1) {
			return `0${Decklength}`
		}

		return Decklength;
	}


	return (
		<div className="col-lg-6 col-sm-12 background-pink px-3">
			{
				!isVisible ? (
					<div className="display-content">
						<div className="d-flex content-updated align-items-center" >
							{loading && (
								<div>
									<div>-----</div>
								</div>
							)}
							{!loading && reviewer && (
								<div className='d-flex cursor-pointer' onClick={toggleVisibility}>
									<div className='image-expert' >
										<img src={absoluteImageUrl} alt="Danny Tawaii" />
									</div>
									<div className='d-flex flex-column justify-content-center gap-1 ms-2'>
										<h1 className='profile-name'>
											{reviewer?.name}</h1>
										<span className='Cto'>{reviewer?.description}</span>
									</div>
								</div>
							)}
							{!reviewer && (
								<div>
									<div>There is no reviewer assigned to your pitch</div>
								</div>
							)}
							<div>

								<button disabled={!reviewer || loading ? true : false} className='d-none btn btn-primary sm connect-btn-dashboard' onClick={updateText}>{displaySVG ?
									(
										<svg id="check-circle-fill" className="me-2" xmlns="http://www.w3.org/2000/svg" width="16.506" height="16.506" viewBox="0 0 16.506 16.506">
											<g id="Group_190" data-name="Group 190">
												<path id="Path_977" data-name="Path 977" d="M16.506,8.253A8.253,8.253,0,1,1,8.253,0a8.253,8.253,0,0,1,8.253,8.253Zm-4.1-3.126A.774.774,0,0,0,11.3,5.15L7.714,9.715,5.554,7.555A.774.774,0,0,0,4.461,8.648l2.73,2.731A.774.774,0,0,0,8.3,11.358L12.422,6.21a.774.774,0,0,0-.01-1.083Z" fill="#5f2deb" />
											</g>
										</svg>
									) :
									<svg xmlns="http://www.w3.org/2000/svg" width="18.779" height="18.733" viewBox="0 0 18.779 18.733" className="me-2" >
										<g id="adduser" transform="translate(-0.172 -0.288)">
											<path id="Path_27" data-name="Path 27" d="M7.77.288C5.568.288,3.8,1.8,3.8,4.692a7.465,7.465,0,0,0,1.894,5c.445,1.185-.358,1.626-.524,1.689-2.305.846-5,2.379-5,3.9v.57c0,2.076,3.953,2.556,7.621,2.556q1.2,0,2.4-.091a5.675,5.675,0,0,1-.365-7.37,1.817,1.817,0,0,1,.046-1.278A7.439,7.439,0,0,0,11.74,4.692c0-2.891-1.77-4.4-3.971-4.4ZM14.342,9.8a4.609,4.609,0,1,0,4.609,4.609A4.609,4.609,0,0,0,14.342,9.8ZM13.7,11.857h1.278v1.894h1.894v1.323H14.981v1.894H13.7V15.074H11.786V13.751H13.7Z" transform="translate(0)" fill="#fff" />
										</g>
									</svg>
								}

									{displayText}</button>
							</div>
						</div>
						<div className="reveiw-dash-tab">
							<Tabs
								id="uncontrolled-tab-example"
								className="navTabs navTabs-reveiws"
							>
								<Tab eventKey="Notes" title="Notes">
									<div className="profile-inner-wrapper">
										<div className="deck-slider-container" id="slider-container" ref={commentsContainerRef}>
											{loading && <div className='loader'><Loader className='comments-loader' /></div>}
											{!loading && comments.length === 0 && <div>There is no comment available</div>}
											{!loading && comments.length !== 0 && comments.map((comment, index) => <Comment key={comment._id} index={index} type={type} comment={comment} />)}
											<div className='deck-slider border-light-purple'></div>
										</div>
										<CreateComment onKeyDown={handleKeyDown} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleCommentChange(e)} />
									</div>
								</Tab>
								<Tab disabled={!reviewer || loading ? true : false} eventKey="Reviewed" title={handleTitle()} className=''>
									{!loading && <DashboardUserReviews />}
								</Tab>
							</Tabs>
						</div>
					</div>
				) : (<>
					<div className="d-flex justify-content-between profile-reveiw-dashboard align-items-center">
						<h1 className='profile-main-heading mb-0'>Profile</h1>
						<span className='close-cursor'>
							<svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" onClick={toggleVisibility}>
								<g id="cross-circle_curved" transform="translate(-4.5 -4.5)">
									<path id="Path_1336" data-name="Path 1336" d="M32,16,16,32m0-16L32,32m10-8A18,18,0,1,1,24,6,18,18,0,0,1,42,24Z" fill="none" stroke="#ff5e5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
								</g>
							</svg>

						</span>

					</div>
					<hr />
					<div className="d-flex content-updated">
						<div className='d-flex'>
							<div className='image-expert'>
								<img src={absoluteImageUrl} alt="Danny Tawaii" />
							</div>
							<div className='d-flex flex-column ms-3 justify-content-center'>
								<h1 className='profile-name'>
									{reviewer?.name}</h1>
								<span className='Cto'>{reviewer?.designation}</span>
							</div>
						</div>
						<div>
							<button className='d-none btn btn-primary sm connect-btn-dashboard' onClick={updateText1}>
								{displaySVG ?
									(
										<svg id="check-circle-fill" className="me-2" xmlns="http://www.w3.org/2000/svg" width="16.506" height="16.506" viewBox="0 0 16.506 16.506">
											<g id="Group_190" data-name="Group 190">
												<path id="Path_977" data-name="Path 977" d="M16.506,8.253A8.253,8.253,0,1,1,8.253,0a8.253,8.253,0,0,1,8.253,8.253Zm-4.1-3.126A.774.774,0,0,0,11.3,5.15L7.714,9.715,5.554,7.555A.774.774,0,0,0,4.461,8.648l2.73,2.731A.774.774,0,0,0,8.3,11.358L12.422,6.21a.774.774,0,0,0-.01-1.083Z" fill="#5f2deb" />
											</g>
										</svg>
									) :
									<svg xmlns="http://www.w3.org/2000/svg" width="18.779" height="18.733" viewBox="0 0 18.779 18.733" className="me-2" >
										<g id="adduser" transform="translate(-0.172 -0.288)">
											<path id="Path_27" data-name="Path 27" d="M7.77.288C5.568.288,3.8,1.8,3.8,4.692a7.465,7.465,0,0,0,1.894,5c.445,1.185-.358,1.626-.524,1.689-2.305.846-5,2.379-5,3.9v.57c0,2.076,3.953,2.556,7.621,2.556q1.2,0,2.4-.091a5.675,5.675,0,0,1-.365-7.37,1.817,1.817,0,0,1,.046-1.278A7.439,7.439,0,0,0,11.74,4.692c0-2.891-1.77-4.4-3.971-4.4ZM14.342,9.8a4.609,4.609,0,1,0,4.609,4.609A4.609,4.609,0,0,0,14.342,9.8ZM13.7,11.857h1.278v1.894h1.894v1.323H14.981v1.894H13.7V15.074H11.786V13.751H13.7Z" transform="translate(0)" fill="#fff" />
										</g>
									</svg>
								}
								{displayText}</button>
						</div>

					</div>
					<hr />

					<div className="decription-height">
						<div className="d-flex flex-column profile-reveiw-dashboard">
							<h1 className='description-main-heading'>Description</h1>
							<p>{reviewer?.description}</p>
						</div>
						<hr />
						<div className="d-flex justify-content-between align-items-center profile-reveiw-dashboard">
							<h1 className='description-main-heading'>Reviewed Deck Pitches</h1>
							<span className=''><b>{handleDeckReview('deck')}</b></span>
						</div>
						<hr />
						<div className="d-flex justify-content-between align-items-center profile-reveiw-dashboard">
							<h1 className='description-main-heading'>Reviewed Video Pitches</h1>
							<span><b>{handleDeckReview('video')}</b></span>
						</div>
						<hr className='d-none' />
						<div className="content-updated d-none">
							<div className='d-flex'>
								<div className='image-expert'>
									<h1 className='deck-heading'>Deck</h1>
									<img src={deck} alt="Danny Tawaii" className='img-deck' />
								</div>
								<div className='d-flex flex-column ms-3 profile-heading-deck'>
									<h1 className=''>
										Deck Sample</h1>
									<p>www.example.com</p>
									<span className=''>Review pitch deck</span>
								</div>
							</div>
							<div>
								<button className='btn btn-primary sm connect-btn-dashboard detail-btn'>Detail
								</button>
							</div>
						</div>
						<hr />
					</div>
				</>)
			}
		</div>
	)
}

export default DashboardReviewComments