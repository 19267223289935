import { useEffect, useState } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";

import './dashboardLayout.scss';
import DashboardSidebar from "../sidebar/Sidebar";
import DashboardHeader from "../header/Header";
import hamburgerIcon from "../../../assets/images/svg/hamburger-icon.svg";
import useWindowDimensions from "views/useWindowDimensions";

interface ToggleProfileDrawer {
    (): void;
}   


//this file contains the dashboard layout information
function DashboardLayout() {
    const [hideSidebar, setHideSidebar] = useState(true);
    const [isReviewPage, setIsReviewPage] = useState(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
    const toggleProfileDrawer: ToggleProfileDrawer = () => setIsProfileDrawerOpen((prevState) => !prevState);

    const location = useLocation();
    const toggleHideSidebar = () => {
        setHideSidebar((prevState) => !prevState);
    }
    const { width } = useWindowDimensions();

    useEffect(() => {
        const isDashboardPresent = location.pathname.includes('dashboard');
        const html = document.querySelector('html');
        if (isDashboardPresent) {
            if(html) {
                html.style.overflowY = 'hidden';
            }
        } 

        return () => {
            if(html) {
                html.style.overflowY = 'auto';
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        if(width > 1200) {
            setHideSidebar(false);
        } else {
            setHideSidebar(true);
        }
    }, [width]);

    useEffect(() => {
        const currentPage = getLastItemFromUrl();
        if(currentPage === 'reviews') {
            setIsReviewPage(true);
        } else {
            setIsReviewPage(false);
        }
        function getLastItemFromUrl() {
            const parts = location.pathname.split('/');
            const lastItem = parts[parts.length - 1];
            return lastItem;
        }
    }, [location.pathname]);

    return (
        <div className="dashboard">
            <DashboardHeader isProfileDrawerOpen={isProfileDrawerOpen} toggleProfileDrawer={toggleProfileDrawer} />
            <div className={`dashboard-layout ${isReviewPage && 'layout-full'}`}>
                <img src={hamburgerIcon} alt="menu" className={`hamburger-menu ${hideSidebar ? 'hide' : 'show'}`} onClick={toggleHideSidebar} />
                <DashboardSidebar hideSidebar={hideSidebar} />
                <div className={`dashboard-content ${width <= 1200 && 'ml-120'} ${isReviewPage ? 'dashboard-reveiw' : ''}`}>
                    <Outlet context={toggleProfileDrawer satisfies ToggleProfileDrawer} />
                </div>
            </div>
        </div>
    );
}

export function useToggleProfileDrawer() {
    return useOutletContext<ToggleProfileDrawer>();
}

export default DashboardLayout;
