import axiosInstance from "axios-instance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { clearBackUpToken } from "reducers/authReducer";

type FormValues = {
	name: string;
	website: string;
	industry_experts: string[];
	attachment: File | null,
	description: string,
	pitchId?: string,
	id?: string,
	s3Url: string,
	isS3Uploaded: boolean
	status: string,
	type?: string,
	attachmentType?: string
};
type ErrorString = {
	error: string
}

interface uploadValues {
	file: File,
	folderName: string,
	pitchId: string,
	attachmentType: string
}

interface updateSyncProps extends uploadValues {
	isS3Uploaded: boolean,
	s3Url: string
}


//this file contains the pitch API's

export const uploadS3AndUpdatePitch = createAsyncThunk<any, any>("update/s3pitch", async (obj: uploadValues, { rejectWithValue }) => {
	try {

		const { file, folderName, pitchId, attachmentType } = obj;
		const formData = new FormData();

		if (pitchId) {
			formData.append('file', file);
			formData.append('pitchId', pitchId);
		}

		const isImageAndVideo = ((type: any) => {
			if (type.includes("image")) {
				return true;
			}

			if (type.includes("video")) {
				return true;
			}

			return false;
		})(attachmentType);

		var response: any = ""

		if (isImageAndVideo) {
			response = await axiosInstance.post(`/api/pitch/s3uploadPitch?folderName=${folderName}`, formData);
		}

		if (!isImageAndVideo)
			response = await axiosInstance.post(`/api/pitch/s3uploadPdfPitch?folderName=${folderName}`, formData);

		const { data }: any = response;

		return { ...data, pitchId: pitchId };
	} catch (error: any) {
		console.log('error', error);
		const { response } = error;
		return rejectWithValue({ message: response.data.error, status: response.status })
	}
});

export const updatePitchAction = async (obj: FormValues) => {
	try {
		const { id } = obj;

		const response = await axiosInstance.put(`/api/pitch/${id}`, obj);

		return response.data;
	} catch (error: any) {
		throw new Error("Error", { cause: error.response.data.error });
	}
};


export const uploadLocalFile = createAsyncThunk<any, any>('update/pitch', async (obj: FormValues, { rejectWithValue }) => {
	try {
		const { attachment, pitchId, isS3Uploaded, s3Url } = obj;
		let formData = new FormData();

		if (attachment && pitchId) {
			formData.append("file", attachment);
			formData.append("pitchId", pitchId);
			formData.append("status", "open");
			formData.append("s3Url", s3Url);
		}

		var response = "";

		if (isS3Uploaded) {
			response = await axiosInstance.put(`/api/pitch/local/uploadDocFilePitch`, formData);
		}

		if (!isS3Uploaded) {
			response = await axiosInstance.put(`/api/pitch/local/uploadFilePitch`, formData);
		}

		const { data }: any = response;

		if (data.error) {
			return rejectWithValue(data.error);
		}

		return { ...data, pitchId: pitchId };
	} catch (error: any) {
		console.log('error', error);
		const { response } = error;
		return rejectWithValue({ message: response.data.error, status: response.status })
	}
});



export const uploadAndSyncPitches = createAsyncThunk('pitches/uploadAndSync', async (obj: updateSyncProps, { dispatch, getState, rejectWithValue }) => {
	try {

		const { s3Url, file, folderName, pitchId, attachmentType, isS3Uploaded } = obj;

		const s3Object = {
			file: file,
			folderName: folderName,
			pitchId: pitchId,
			attachmentType: attachmentType
		}

		const localObject = {
			attachment: file,
			pitchId: pitchId,
			isS3Uploaded: isS3Uploaded,
			s3Url: s3Url
		}
		let s3Response:any = "";

		if (file && !s3Url) {
			s3Response = await dispatch(uploadS3AndUpdatePitch(s3Object)).unwrap();
		}

		const localResponse = await dispatch(uploadLocalFile(localObject)).unwrap();

		dispatch(clearBackUpToken());

		return {
			s3Response: s3Response.data,
			localResponse: localResponse.data,
			pitchId: obj.pitchId
		}

	} catch (error) {
		return rejectWithValue(error);
	}
})

export const updatePitchStatus = async (pid: string, status: string, expertId: string) => {
	try {
		const { data } = await axiosInstance.post('/api/dashboard/status', { pid, status, expertId });
		return data?.data;
	} catch ({ response: { data } }: any) {
		const error = (data as ErrorString).error || '';
		throw error;
	}
};