import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as Yup from 'yup';
import ProfileSettings from './ProfileSettings';
import CompanySettings from './CompanySettings';
import ChangePassword from './ChangePassword';
import ExperienceSettings from './ExperienceSetting';
import { getProfile } from 'actions/profile';
import { updatePasswordStore } from 'reducers/password';
import './dashboardSettings.scss';
import { updatePasswordAPI, updateUser } from 'actions/settings';
import Loader from 'components/loader/Loader';

const founderSchema = Yup.object({
    email: Yup.string().trim().lowercase().email('Invalid email').required('Email is required'),
    name: Yup.string().trim().lowercase().required('Name is required').max(20, 'Maximum characters limit should be 20')
        .min(3, "Minimum characters limit should be 03"),
    linkedinProfile: Yup.string().trim().url('Enter valid url format e.g. https://abc.com'),
    companyName: Yup.string().trim().lowercase().required('Company name is required').max(20, 'Maximum characters limit should be 20')
        .min(3, "Minimum characters limit should be 03"),
    companyWebsite: Yup.string().required('Company website is required').url('Enter valid url format e.g. https://abc.com'),
    incorporationDetails: Yup.string().trim().required('Field is required'),
    roundSize: Yup.string().required("Round size is required")
        .matches(/^\d{1,3}(,\d{3})*$/, "Round Size should be a valid number format like 1,000 or 100,000 or 10,100,100")
        .typeError('Round Size should be a number'),
    roundProgress: Yup.string().required('Round Progress is required'),
    companyDescription: Yup.array().min(1, 'Company description is required').required('Company description is required')
});

const expertSchema = Yup.object({
    email: Yup.string().trim().lowercase().email('Invalid email').required('Email is required'),
    name: Yup.string().trim().lowercase().required('Name is required').max(20, 'Maximum characters limit should be 20')
        .min(3, "Minimum characters limit should be 03"),
    linkedinProfile: Yup.string().trim().url('Enter valid url format e.g. https://abc.com'),
    companyDescription: Yup.array().min(1, 'Company description is required').required('Company description is required')
});

const passwordSchema = Yup.object({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string().trim()
        .min(8, "Password must be between 8 to 20 characters")
        .max(20, "Password must be between 8 to 20 characters")
        .matches(
            /^(?=.*[0-9])/,
            "password must contain at least one numeric value"
        )
        .matches(
            /^(?=.*[!=%^~():/<>;{}|_+@#$%^&*.,])/,
            "password must contain at least one special character"
        )
        .matches(
            /^(?=.*[A-Z])/,
            "password must contain at least one capital character"
        )
        .required("New password is required"),
    confirmPassword: Yup.string().required('Confirm password is required')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match')
})


//this file contains the settings information
function DashboardSettings() {
    const dispatch = useAppDispatch();
    const { auth: { user }, profileStore: { profile }, passwordStore } = useAppSelector((state) => state);
    const [errors, setErrors] = useState({});
    const [activeKey, setActiveKey] = useState('profile');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');
    const [apiError, setAPIError] = useState('');
    const [isFetchProfile, setIsFetchProfile] = useState(false);
    const [name, setName] = useState('');

    useEffect(() => {
        setName(profile.name || '');
    }, [profile])

    const getLoggedInUserProfile = async () => {
        try {
            await dispatch(getProfile({ userId: user.id }));
        } catch (error: any) {
            console.log('Error in getting user profile', error.cause);
        }
    };
    useEffect(() => {
        getLoggedInUserProfile();
        // eslint-disable-next-line
    }, [isFetchProfile]);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});
        const { oldPassword, newPassword } = passwordStore;
        try {
            if (activeKey === 'password') {
                setAPIError('');
                await passwordSchema.validate(passwordStore, { abortEarly: false });
                const response = await updatePasswordAPI({ email: user.email, oldPassword, newPassword });
                if (response.error) throw response.error;
                else {
                    //profile is updated
                    setLoading(false);
                    if (typeof response === "string") setResponse(response);
                    dispatch(updatePasswordStore({ type: "oldPassword", value: "" }));
                    dispatch(updatePasswordStore({ type: "newPassword", value: "" }));
                    dispatch(updatePasswordStore({ type: "confirmPassword", value: "" }));
                    updateProfileStore();
                }
            } else {
                const profileCopy = { ...profile, name };
                const userSchema = (user.userType === 'founder') ? founderSchema : expertSchema;
                await userSchema.validate(profileCopy, { abortEarly: false });
                setAPIError('');
                const response = await updateUser(profileCopy, user.id);
                if (response.error) throw response.error;
                else {
                    //Profile is updated
                    setLoading(false);
                    if (typeof response === "string") setResponse('User is updated');
                    updateProfileStore();
                }
            }
            setErrors({});
        } catch (error: any) {
            setLoading(false);
            setResponse('');
            console.log(error)
            const errors: { [key: string]: string } = {};
            if (error instanceof Yup.ValidationError) {
                error.inner.forEach((e) => {
                    errors[e.path || ''] = e.message;
                });
                setErrors(errors);
            } else {
                setAPIError(error);
            }
        }
    };

    const updatePassword = (type: string, value: string) => {
        dispatch(updatePasswordStore({ type: type, value: value }));
    };

    const setTabKey = (key: any) => {
        setActiveKey(key);
        setResponse('');
    }

    const updateProfileStore = () => {
        setIsFetchProfile(!isFetchProfile);
    };

    const renderUserTab = () => {
        return (user.userType === "founder")
            ?
            (<Tab eventKey="company" title="Company Settings">
                <CompanySettings errors={errors} />
            </Tab>)
            :
            (<Tab eventKey="experience" title="Experience Settings">
                <ExperienceSettings errors={errors} />
            </Tab>)
    };
    return (
        <div className="dashboard-setting">
            <div className="header-container">
                <div className="header">
                    <h1 className="title">
                        Setting
                    </h1>
                </div>
            </div>
            <form>
                <Tabs
                    defaultActiveKey={activeKey}
                    id="uncontrolled-tab-example"
                    className=" navTabs"
                    onSelect={(key: any) => setTabKey(key)}
                >
                    <Tab eventKey="profile" title="Profile Settings">
                        <ProfileSettings
                            errors={errors}
                            updateProfileStore={updateProfileStore}
                            setName={setName}
                            name={name}
                        />
                    </Tab>
                    {renderUserTab()}
                    <Tab eventKey="password" title="Password Settings">
                        <ChangePassword
                            errors={errors}
                            onChange={updatePassword}
                        />
                    </Tab>
                </Tabs>
                <div className="button-row not-stack">
                    <button className="btn btn-secondary sm">Cancel</button>
                    <button
                        className="btn btn-primary sm"
                        onClick={(e) => onSubmit(e)}
                    >
                        {(loading) ? <Loader /> : 'Save'}
                    </button>
                    <div>{response}</div>
                    <div className="error">{apiError}</div>
                </div>
            </form>
        </div>
    );
};

export default DashboardSettings;
