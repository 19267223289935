import CustomFormikField from 'components/CustomFormikField/CustomFormikField';
import { Field, ErrorMessage } from 'formik';
const VentureInfo = ({ setFieldValue }) => {

    return (
        <div>
            <div className="form-group extra-mb">
                <label htmlFor="#" className="control-label big" style={{opacity: 0}}>What's the name of your company?</label>
                <CustomFormikField
                    type="text"
                    className="form-control only-bottom-b"
                    placeholder="Enter Your Company Name"
                    name="companyName"
                    id="companyName"
                    setFieldValue={setFieldValue}
                />
                <div className="error-field">
                    <ErrorMessage name="companyName" />
                </div>
            </div>
            <div className="form-group extra-mb">
                <label htmlFor="#" className="control-label big" style={{opacity: 0}}>Which website does your company have?</label>
                <CustomFormikField
                    type="text"
                    className="form-control only-bottom-b"
                    placeholder="Enter Your Company Website"
                    id="companyWebsite"
                    name="companyWebsite"
                    setFieldValue={setFieldValue}
                />
                <div className="error-field">
                    <ErrorMessage name="companyWebsite" />
                </div>
            </div>
        </div>
    );
};

export default VentureInfo;