import { Link, useParams } from 'react-router-dom';
import "./styles.scss";
import logonew from "assets/images/logo-t.png";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { updatePassword } from 'actions/forgetPasswordActions';
import * as Yup from 'yup';
import TextError from './TextError';
import { useState } from 'react';

interface Formtype {
  newPassword: string,
  confirmPassword: string,
  password?: string
}

const validationSchema = Yup.object({
  newPassword: Yup.string().required('New password is required'),
  confirmPassword: Yup.string().required('Confirm password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
});

function UpdateForgottenPassword() {
  const params = useParams();
  const token = params.token || '';
  const [response, setResponse] = useState('');

  const onSubmit = async (values: Formtype, { setErrors }: any) => {
    try {
      const data = await updatePassword(token, values.newPassword);
      if (data.error) throw data;
      else {
        setErrors({ form: '' });
        setResponse(data.data);
      }
    } catch (data: any) {
      console.error(data);
      setErrors({ form: data.error });
    }
  }
  return (
    <>
      <div className="auth-screen">
        <div className="container">
          <div className="fullLogo text-center">
            <Link to="/">
              <img src={logonew} alt="whatapitch" />
            </Link>
          </div>
          <section>
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-5 col-md-6" data-aos="fade-up" data-aos-easing="ease-in-sine">
                <div className="card forgetPW">
                  <h3>Forget Password</h3>
                  <Formik
                    initialValues={{
                      newPassword: '',
                      confirmPassword: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {
                      ({ errors }: any) => (
                        <Form>
                          <div className="form-group">
                            <label htmlFor="#" className="control-label">New Password*</label>
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Enter your new password"
                              id="newPassword"
                              name="newPassword"
                            />
                            <ErrorMessage name='newPassword' component={TextError} />
                          </div>
                          <div className="form-group">
                            <label htmlFor="#" className="control-label">Confirm Password*</label>
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Confirm your password"
                              id="confirmPassword"
                              name="confirmPassword"
                            />
                            <ErrorMessage name='confirmPassword' component={TextError} />
                            {errors.form && <div className='error'>{errors.form}</div>}
                            {response && <div>{response}</div>}
                          </div>
                          <div className="button-row">
                            <button type="submit" className="btn btn-primary">Save</button>
                          </div>
                          <div className="text-center">
                            Go back to&nbsp;
                            <Link to="/auth" className="forgot-pw">
                              Sign in
                            </Link>
                          </div>
                        </Form>
                      )
                    }
                  </Formik>
                </div>
              </div>
            </div>
          </section>
          <div className="copyright text-center">
            © 2024 WhataPitch. All Rights Reserved
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateForgottenPassword;
