import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "axios-instance";
type ErrorString = {
    error: string
}

// this file contains the authentication API's

export const loginAction = createAsyncThunk<any, any>('auth/login', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post('/api/login', obj);
        if (data.token) return data.token;
    } catch (error: any) {
        console.log('error', error);
        const { response } = error;
        return rejectWithValue(response.data.error);
    }
});

export const founderSignupAction = createAsyncThunk<any, any>('auth/signup', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post('/api/register', obj);
        if (data.token) return data.token;
    } catch (error: any) {
        console.log('error', error);
        const { response } = error;
        return rejectWithValue(response.data.error);
    }
});

export const getUserDuplicationStatus = async (obj: any) => {
    try {
        const { data } = await axiosInstance.get(`/api/getUserDuplicationStatus/${obj.email}`);
        return data?.data;
    } catch ({ response: { data } }: any) {
        const error = (data as ErrorString).error || '';
        throw error;
    }
};