import React from 'react';

//This file contains the logic to create reply.


type createCommentProps = {
    replyInputRef?: React.RefObject<HTMLTextAreaElement>;
    onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

function CreateReply({replyInputRef, onKeyDown, onChange}: createCommentProps) {
  return (
    <div className="pin-container mt-1">
        <textarea ref={replyInputRef} onChange={onChange} onKeyDown={onKeyDown} placeholder='Type your notes here' />
    </div>
  )
}

export default CreateReply;