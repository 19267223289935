import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardForm from 'views/founder/billing/CardForm';
import { useAppSelector } from 'hooks';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const Card = () => {
    const { founderService: { price, currency, description, tax } } = useAppSelector(({ service }) => service);
     // eslint-disable-next-line
    const [currencyName, currencySign] = currency.split(':');
    const renderBillingDetails = () => {
        return (
            <>
                <h3 className='sm'>Billing</h3>
                <div className='billing-wrapper'>
                    <div className="billing-wrapper__left">
                        <p>{description}</p>
                        <p>per review</p>
                    </div>
                    <div className="billing-wrapper__right">
                        <p>USD<span>{currencySign} {price}</span></p>
                    </div>
                </div>
                <div className='billing-wrapper'>
                    <div className="billing-wrapper__left">
                        <p>Subtotal</p>
                        <p>Tax</p>
                    </div>
                    <div className="billing-wrapper__right">
                        <p>USD<span>{currencySign} {price}</span></p>
                        <p className='sm'>USD<span>$ {tax}</span></p>
                    </div>
                </div>
                <div className='billing-wrapper'>
                    <div className="billing-wrapper__left">
                        <p>Amount</p>
                    </div>
                    <div className="billing-wrapper__right">
                        <p>USD<span>{currencySign} {price}</span></p>
                    </div>
                </div>
            </>
        );
    };
    const renderCardForm = () => {
        return (
            <>
                <h3 className='sm'>Payment method</h3>
                <Elements stripe={stripePromise}><CardForm /></Elements>
            </>
        );
    };

    return (
        <div className="col-lg-5 col-md-6" data-aos="fade-up" data-aos-easing="ease-in-sine">
            <div className="card paymentMethod">
                {renderBillingDetails()}
                {renderCardForm()}
            </div>
            <div className="copyright text-center">
                © 2024 WhataPitch. All Rights Reserved
            </div>
        </div>
    )
};

export default Card;