import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logonew from "../../../assets/images/svg/new-logo.svg";
import "../deck-info/style.scss";
import DeckUpload from './deck-upload/DeckUpload';
import CreateDeck from './create-deck/CreateDeck';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppSelector, useAppDispatch } from "hooks";
import { useEffect } from 'react';
import { updatePitchAction, uploadAndSyncPitches } from 'actions/pitchActions';
import { setCreatedPitch } from 'reducers/founderDashboardReducer';
import { setBackUpToken } from 'reducers/authReducer';

interface FileLike extends Blob {
	readonly lastModified: number;
	readonly name: string;
}

declare module 'yup' {
	interface MixedSchema {
		videoDuration(errorMessage: string): MixedSchema;
	}
}

Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'videoDuration', function (errorMessage: string) {
	return this.test('video-duration', errorMessage, function (value: any) {
		return new Promise((resolve, reject) => {
			// Ensure the value is a File or Blob
			const file: FileLike | null = value instanceof File ? value : null;

			if (!file || !file.type.includes('video')) {
				resolve(true); // Not a video file or no file at all, pass validation
				return;
			}

			const video = document.createElement('video');
			video.preload = 'metadata';

			video.onloadedmetadata = function () {
				window.URL.revokeObjectURL(video.src);
				const duration = video.duration / 60; // Convert seconds to minutes

				if (duration > 15) {
					reject(new Yup.ValidationError(errorMessage, value, 'attachment'));
				} else {
					resolve(true);
				}
			};

			video.onerror = function () {
				reject(new Yup.ValidationError('Could not load video', value, 'attachment'));
			};

			video.src = URL.createObjectURL(file as Blob);
		});
	});
});


const validationSchema = Yup.object({
	name: Yup
		.string()
		.required('Name is required').trim().min(3, "Minimum character should be 03.").max(20, "Maximum characters should be 20."),
	website: Yup.string().url('Invalid URL format').required('Website url is required').trim(),
	description: Yup.string().trim(),
	attachment: Yup.mixed().required('Please attach the media').videoDuration("Video must be less than 15 minutes long")
});

type FormValues = {
	name: string;
	website: string;
	industry_experts: string[];
	attachment: File | null,
	description: string
}

function DeckInfo() {
	const { user } = useAppSelector((state) => state.auth);
	const { createdPitch } = useAppSelector((state) => state.founderDasboard);
	const [loading, setLoading] = useState(false);
	const [s3Key, setS3Key] = useState("");
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (Object.keys(createdPitch).length === 0)
			navigate('/dashboard');

	}, [createdPitch, navigate])

	const formik = useFormik<FormValues>({
		initialValues: {
			name: "",
			website: user && user.website ? user?.website : "",
			industry_experts: [],
			attachment: null,
			description: "",
		},
		validateOnChange: false,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const { attachment } = values;
			setLoading(true);

			try {
				dispatch(setBackUpToken());

				let s3Url = "";

				if (s3Key) {
					const lastSlashIndex = s3Key.lastIndexOf('/');
					s3Url = s3Key.substring(0, lastSlashIndex);
				}

				await updatePitchAction({ id: createdPitch.pitchId, ...values, s3Url: s3Url, isS3Uploaded: s3Key ? true : false, status: "uploading" });

				if (attachment)
					dispatch(uploadAndSyncPitches({
						file: attachment,
						folderName: createdPitch.serviceType,
						pitchId: createdPitch.pitchId,
						attachmentType: attachment.type,
						isS3Uploaded: s3Key ? true : false,
						s3Url: s3Url
					}));

				dispatch(setCreatedPitch({}));
				setLoading(false);
				navigate("/dashboard");
			} catch (e: any) {
				console.log(e.cause);
				setLoading(false);
				if(e.cause.includes('Name')) {
					let message = e.cause.split(":");
					formik.setFieldError('name', message[2]);
				}
			}
		}
	})


	useEffect(() => {
		if (user && Object.keys(user).length !== 0) {
			formik.setFieldValue('industry_experts', [...(user.companyDescription ?? [])]);
		}

		//eslint-disable-next-line
	}, [user])

	return (
		<>
			<div className="deck-info">
				<div className="container">
					<div className="fullLogo text-center">
						<Link to="/">
							<img src={logonew} alt="whatapitch" />
						</Link>
					</div>
					<section>
						<form noValidate onSubmit={formik.handleSubmit}>
							<div className="row g-5">
								<DeckUpload formik={formik} serviceType={createdPitch.serviceType} headerProps={{ disableHeader: true, disableFileName: true }} setS3Key={setS3Key} />
								<div className="col-lg-5" data-aos="fade-left" data-aos-easing="ease-in-sine">
									<CreateDeck formik={formik} loading={loading} />
									<div className="copyright text-center">
										© 2024 WhataPitch. All Rights Reserved
									</div>
								</div>
							</div>
						</form>
					</section>
				</div>
			</div>
		</>
	)
}



export default DeckInfo