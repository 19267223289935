import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./authInfo.scss";
import { useState } from 'react';
import logonew from "assets/images/svg/new-logo.svg";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TextError from 'components/TextError';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import CustomFormikField from 'components/CustomFormikField/CustomFormikField';
import { useAppDispatch } from 'hooks';
import { founderSignupAction, getUserDuplicationStatus } from 'actions/authActions';

interface Formtype {
	email: string,
	password: string
	confirmPassword: string,
}

interface SignIn {
	showPassword: boolean,
	showConfirmPassword: boolean
}

const validationSchema = Yup.object({
	email: Yup
		.string()
		.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Invalid email format')
		.required('Email is required'),
	password: Yup.string()
		.min(8, "Password must be between 8 to 20 characters")
		.max(20, "Password must be between 8 to 20 characters")
		.matches(
			/^(?=.*[0-9])/,
			"password must contain at least one numeric value"
		)
		.matches(
			/^(?=.*[!=%^~():/<>;{}|_+@#$%^&*.,])/,
			"password must contain at least one special character"
		)
		.matches(
			/^(?=.*[A-Z])/,
			"password must contain at least one capital character"
		)
		.required("New password is required"),
	confirmPassword: Yup.string().required('Confirm password is required')
		.oneOf([Yup.ref('password')], 'Passwords must match')
});

function AuthInfo() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [displayPassword, setDisplayPassword] = useState<SignIn>({
		showPassword: false, showConfirmPassword: false
	});
	const [setErrors, errors] = useState('');
	const { state: { type } } = useLocation();

	const onSubmit = async (values: Formtype, { setErrors }: any) => {
		try {
			const { email, password } = values;
			const founderData = { email, password, userType: type };
			const response = await getUserDuplicationStatus({ email });
			if (response.error) throw response.error;
			else {
				setErrors({ form: '' });
				(type === 'founder') ? navigate('/founder', { state: values }) :
					navigate('/expert', { state: values });
			}
		} catch (error: any) {
			setErrors({ form: error });
		}
	}

	const handlePassword = (type?: string | MouseEvent) => {
		const { showPassword, showConfirmPassword } = displayPassword;

		if (type === "password") {
			setDisplayPassword(prev => ({ ...prev, showPassword: !showPassword }));
			return;
		}

		setDisplayPassword(prev => ({ ...prev, showConfirmPassword: !showConfirmPassword }));
	}

	return (
		<>
			<div className="auth-screen">
				<div className="container">
					<div className="fullLogo text-center create-account-logo-container">
						<Link to="/">
							<img src={logonew} alt="whatapitch" />
						</Link>
					</div>
					<section className="create-account-section">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-5 col-md-6" data-aos="fade-up" data-aos-easing="ease-in-sine">
								<div className="card forgetPW">
									<h3 className="signup-heading">{type === "founder" ? "Setup your Founder Account" : "Setup your Expert Account"}</h3>
									<Formik
										initialValues={{
											email: '',
											password: '',
											confirmPassword: ''
										}}
										validationSchema={validationSchema}
										validateOnChange={true}
										onSubmit={onSubmit}
									>
										{
											({ errors, setFieldValue }: any) => (
												<Form>
													<div className="form-group">
														<label style={{display: 'none'}} htmlFor="#" className="control-label">Email*</label>
														<CustomFormikField
															type="text"
															placeholder="Enter your email address"
															id="email"
															name="email"
															setFieldValue={setFieldValue}
															isLower={true}
															className={"form-control"}
														/>
														<div className="error-field">
															<ErrorMessage name='email' component={TextError} />
														</div>
													</div>
													<div className="form-group">
														<label style={{opacity: '0'}} htmlFor="#" className="control-label">Password*</label>
														<div className="signup-password">
															<Field
																type={displayPassword.showPassword ? "text" : "password"}
																className="form-control password-field"
																placeholder="Enter your password"
																id="password"
																name="password"
															/>
															<div className="eye-password" onClick={() => handlePassword('password')}>
																{!displayPassword.showPassword && <EyeFill />}
																{displayPassword.showPassword && <EyeSlashFill />}
															</div>
														</div>
														<div className="error-field">
															<ErrorMessage name='password' component={TextError} />
														</div>
													</div>
													<div className="form-group">
														<label style={{opacity: '0'}} htmlFor="#" className="control-label">Confirm Password*</label>
														<div className="signup-password">
															<Field
																type={displayPassword.showConfirmPassword ? "text" : "password"}
																className="form-control password-field"
																placeholder="Confirm your password"
																id="confirmPassword"
																name="confirmPassword"
																style={{marginBottom: '10px'}}
															/>
															<div className="eye-password" onClick={() => handlePassword('confirm')}>
																{!displayPassword.showConfirmPassword && <EyeFill />}
																{displayPassword.showConfirmPassword && <EyeSlashFill />}
															</div>
														</div>
														<div className="error-field">
															<ErrorMessage name='confirmPassword' component={TextError} />
														</div>
													</div>
													<div className="button-row">
														<button type="submit" className="btn btn-primary">submit</button>
													</div>
													<div className="error">{errors.form}</div>
												</Form>
											)
										}
									</Formik>
								</div>
							</div>
						</div>
					</section>
					<div className="copyright text-center">
						© 2024 WhataPitch. All Rights Reserved
					</div>
				</div>
			</div>
		</>
	)
}

export default AuthInfo;
