import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "axios-instance";

//this file contains the wallet API

export const getAllTransfers = createAsyncThunk<any, any>('wallet/get', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`/api/transfers`);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        rejectWithValue(data);
    }
});

export const getBankDetails = createAsyncThunk<any, any>('wallet/getBank', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`/api/banks`);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        rejectWithValue(data);
    }
});