import { useEffect, useState, useContext } from "react";
import {
    useElements, useStripe,
    CardNumberElement, CardCvcElement, CardExpiryElement
} from "@stripe/react-stripe-js";
import { useAppSelector, useAppDispatch } from "hooks";
import { createCharge } from "actions/billingActions";
import Loader from "components/loader/Loader";
import BillingContext from "contexts/billingContext";
import { setCreatedPitch } from "reducers/founderDashboardReducer";
type Payload = {
    userId: string,
    token: string,
    amount: string,
    serviceId: string
};

const cardValidationInitialValue = {
    cardNumber: '',
    cardExpiry: '',
    cvv: '',
    amount: ''
};

const CardForm = () => {
    const { setBillingDetail } = useContext(BillingContext);
    const elements = useElements();
    const stripe = useStripe();
    const dispatch = useAppDispatch();
    const { auth: { user }, service: { founderService: selectedService } }: any = useAppSelector((state) => state);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [cardValidation, setCardValidation] = useState(cardValidationInitialValue);
    const [isError, setIsError] = useState(true);
    const [response, setResponse] = useState('');

    const stripeSubmit = async (e: any) => {
        try {
            e.preventDefault();
            if (isError) return;
            setLoading(true);
            if (!stripe || !elements) {
                return;
            }
            const cardElement: any | null = elements.getElement(CardNumberElement);
            if (!cardElement) {
                return;
            }

            const { token } = await (stripe as any)?.createToken(cardElement);
            if (token) {
                const payload: Payload = {
                    token: token.id,
                    userId: user.id,
                    amount: selectedService.price,
                    serviceId: selectedService._id
                }
                const response = await createCharge(payload);
                dispatch(setCreatedPitch({ pitchId: response.transaction.pitch, serviceType: response.transaction.services }));

                if (response.error) throw response.error;
                else {
                    setLoading(false);
                    setError('');
                    if (typeof response === 'string') setResponse(response);
                    //Move to Upload video or upload Pitch screen.
                    setBillingDetail?.(true);
                }
            } else {
                // eslint-disable-next-line
                throw 'Fill out the fields correctly';
            }
        } catch (error: any) {
            setLoading(false);
            setResponse('');
            if (typeof error === 'string') setError(error);
            else if (typeof error.error === 'string') setError(error.error);
        }
    };

    useEffect(() => {
        const isError = !Object.values(cardValidation).every((v) => v === '');
        setIsError(isError);
    }, [cardValidation])

    const onChange = (type: string, { error = {} }: any) => {
        const { message = '' } = error;
        setCardValidation(preValidation => ({ ...preValidation, [type]: message }));
    };

    const renderCustomForm = () => {
        return (
            <form className="needs-validation" onSubmit={stripeSubmit}>
                <div className="form-group">
                    <label htmlFor="cardNumber" className="control-label">Card Number</label>
                    <div className="stripe-input is-invalid">
                        <CardNumberElement
                            options={{
                                showIcon: true,
                                iconStyle: 'solid'
                            }}
                            onChange={(e) => onChange('cardNumber', e)}
                        />
                    </div>
                    {cardValidation.cardNumber && <div className="error p-2">{cardValidation.cardNumber}</div>}
                </div>
                <div className="form-group-wrapper">
                    <div className="form-group">
                        <label htmlFor="expiration" className="control-label">Expiry Month</label>
                        <div className="stripe-input is-invalid">
                            <CardExpiryElement
                                onChange={(e) => onChange('cardExpiry', e)}
                            />
                        </div>
                        {cardValidation.cardExpiry && <div className="error p-2">{cardValidation.cardExpiry}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="cvv" className="control-label">CVC</label>
                        <div className="stripe-input is-invalid">
                            <CardCvcElement
                                onChange={(e) => onChange('cvv', e)}
                            />
                        </div>
                        {cardValidation.cvv && <div className="error p-2">{cardValidation.cvv}</div>}
                    </div>
                </div>
                <div className="button-row">
                    <button type="submit" className="btn btn-primary">
                        {(loading) ? <Loader /> : `Proceed the payment`}
                    </button>
                </div>
                {error && <div className="container error p-2">{error}</div>}
                {cardValidation.amount && <div className="error">{cardValidation.amount}</div>}
                {response && <div className="container p-2">{response}</div>}
            </form>
        )
    };
    return renderCustomForm();
};

export default CardForm;