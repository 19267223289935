import './profileDrawer.scss';

import expert1 from "assets/images/expert-1.png";
import { useState } from 'react';


//this file contains the user profile information 
function ProfileDrawer(props: any) {
    const [displaySVG, setDisplaySVG] = useState(false);
    const [displayText, setDisplayText] = useState('Connect');

    const updateText = () => {
        setDisplayText('Connection Sent');
        setDisplaySVG(true);
    };
    return (
        <div className="profile-reveiw-drawer">
            <div className="profile-header">
                <p className="title">Profile</p>
                <svg onClick={() => props.toggleProfileDrawer()} xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
                    <g id="cross-circle_curved" transform="translate(-4.5 -4.5)">
                        <path id="Path_1336" data-name="Path 1336" d="M32,16,16,32m0-16L32,32m10-8A18,18,0,1,1,24,6,18,18,0,0,1,42,24Z" fill="none" stroke="#ff5e5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                    </g>
                </svg>
            </div>
            <div className="profile-data">
                <div className="user-data">
                    <div className="image">
                        <img src={expert1} alt="user" />
                    </div>
                    <div className="name-data">
                        <p className="name">Max Mullan</p>
                        <p className="desiginatin">CTO at Instacart</p>
                    </div>
                </div>
                <button className='btn btn-primary sm connect-btn-dashboard' onClick={updateText}>
                    {
                    displaySVG ?
                        <svg id="check-circle-fill" className="me-2" xmlns="http://www.w3.org/2000/svg" width="16.506" height="16.506" viewBox="0 0 16.506 16.506">
                            <g id="Group_190" data-name="Group 190">
                                <path id="Path_977" data-name="Path 977" d="M16.506,8.253A8.253,8.253,0,1,1,8.253,0a8.253,8.253,0,0,1,8.253,8.253Zm-4.1-3.126A.774.774,0,0,0,11.3,5.15L7.714,9.715,5.554,7.555A.774.774,0,0,0,4.461,8.648l2.73,2.731A.774.774,0,0,0,8.3,11.358L12.422,6.21a.774.774,0,0,0-.01-1.083Z" fill="#5f2deb" />
                            </g>
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.779" height="18.733" viewBox="0 0 18.779 18.733" className="me-2" >
                            <g id="adduser" transform="translate(-0.172 -0.288)">
                                <path id="Path_27" data-name="Path 27" d="M7.77.288C5.568.288,3.8,1.8,3.8,4.692a7.465,7.465,0,0,0,1.894,5c.445,1.185-.358,1.626-.524,1.689-2.305.846-5,2.379-5,3.9v.57c0,2.076,3.953,2.556,7.621,2.556q1.2,0,2.4-.091a5.675,5.675,0,0,1-.365-7.37,1.817,1.817,0,0,1,.046-1.278A7.439,7.439,0,0,0,11.74,4.692c0-2.891-1.77-4.4-3.971-4.4ZM14.342,9.8a4.609,4.609,0,1,0,4.609,4.609A4.609,4.609,0,0,0,14.342,9.8ZM13.7,11.857h1.278v1.894h1.894v1.323H14.981v1.894H13.7V15.074H11.786V13.751H13.7Z" transform="translate(0)" fill="#fff" />
                            </g>
                        </svg>
                    }
                    {displayText}
                </button>
            </div>
            
            <div className="d-flex flex-column profile-reveiw-description">
                <h1 className='description-main-heading'>Description</h1>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing.
                </p>
            </div>
        </div>
    );
}

export default ProfileDrawer;
