import { createContext, Dispatch, SetStateAction } from 'react';

//This file contains the billing context information.

interface BillingContextType {
  setBillingDetail?:  Dispatch<SetStateAction<boolean>> | null;
  billingDetail?: boolean
}

const BillingContext = createContext<BillingContextType>({setBillingDetail:null});

export default BillingContext;