import axiosInstance from "axios-instance";


//this file contains the forgot password API

export const sendChangePasswordLink = async (obj: object) => {
    try {
        const { data } = await axiosInstance.post(`/api/settings/forgetPasswordLink`, obj);
        return data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        return data;
    }
};

export const updatePassword = async (token: string, password: string) => {
    try {
        const { data } = await axiosInstance.put(`/api/settings/updateForgottenPassword`, { password, token });
        return data;
    } catch ({ response: { data } }: any) {
        console.error(data)
        return data;
    }
};