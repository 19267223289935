import pitchLogo from "assets/images/pitchLogo.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import './walletListItem.scss';

function ListItem({ data }: any) {
    const { user: { companyDescription = [] }, pitch: { type, name: pitchName, website }, charge: { amount } } = data;
    const plan = (type === "deck") ? "Review My Pitch Deck" : "Review My Video Pitch";

    const renderIndustry = () => {
        return companyDescription.map((industry: string) => {
            return (<li className="checkbox">
                <label>
                    <input type="checkbox" name="size1" />
                    <span>{industry}</span>
                </label>
            </li>);
        })
    };

    return (
        <>
            <div className="wallet-list-item">
                <div className="wallet-list-item__left">
                    <div className="item-info">
                        <div className="item-img">
                            <img src={pitchLogo} alt="item" />
                        </div>
                        <div className="item-content">
                            <p className="name">{pitchName}</p>
                            <p className="link" data-tooltip-id={website}>{website}</p>
                            <p className="review-by">Plan: {plan}</p>
                        </div>
                    </div>
                    <div className="target-experts">
                        <p className="name">Industry</p>
                        <div className="label-container">
                            <ul className="d-flex list-unstyled select-multiple-buttons">
                                {renderIndustry()}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="wallet-list-item__right">
                    <div className="first-half">
                        <div className="experts-container">
                            <p className="name">Pitch</p>
                            <div className="expert">
                                <p className="cursor">{plan}</p>
                            </div>
                        </div>
                        <div className="status-container">
                            <p className="name">Amount</p>
                            <div className="expert">
                                <p>${amount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactTooltip
                id={website}
                content={website}
                variant="light"
                style={{ color: "#222", fontSize: "14px", left: "0", zIndex: '100000', backgroundColor: '#eee' }}
                place='top-start'
                offset={0}
            />
        </>
    );
}

export default ListItem;
