import { useEffect, useState } from "react";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

//This file contains the logic to display reply.

function Reply({ reply }) {
  const {user} = reply;
  const [formattedTime, setFormatTime] = useState("");
  const absoluteImageUrl = user.profileImageUrl ? `${IMAGE_URL}/${user.profileImageUrl}`: "/user.svg";

  useEffect(() => {
      const date = new Date(reply.createdAt);
      const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

      setFormatTime(formattedTime);
  },[reply])

  return (
    <div className="deck-messages-reply">
      <div className="d-flex pt-2">
        <div className="message-image">
        <img src={absoluteImageUrl} alt="userProfile" />
        </div>
        <div className="ms-2 mb-2 content-mes">
          <p className="name">{user.name}</p>
          <p className="date">{formattedTime}</p>
        </div>

      </div>
      <div className="message-content">
        <p>
          {reply.message}
        </p>
      </div>
    </div>
  )
}

export default Reply