import { ReactNode } from 'react';
import { FileUploader } from "react-drag-drop-files";

//"This file contains the logic to drag and drop or browse files in the upload deck."

type dragAndDropType = {
    children?: ReactNode, name?: string, multiple?: boolean, lable?: string, required?: boolean, disabled?: boolean, hoveTitle?: string,
    classes?: string, types?: Array<string>, onTypeError?: Function, maxSize?: number, minSize?: number, onDrop?: Function,
    onSelect?: Function, handleChange?: Function, isChildAvailable?: boolean,
}

function DragAndDrop({ children, isChildAvailable = true, ...rest }: dragAndDropType) {

    return (
        <>
            {isChildAvailable && (
                <FileUploader {...rest}>
                    {children}
                </FileUploader>
            )}
            {!isChildAvailable && (
                <FileUploader {...rest} />
            )}
        </>
    )
}

export default DragAndDrop