import { useState, useEffect, useContext } from "react";
import { useAppSelector, useAppDispatch } from "hooks";
import Loader from 'components/loader/Loader';
import MarkerWrapper from 'components/marker-wrapper/MarkerWrapper';
import VideoPlayer from "components/video-player/VideoPlayer";
import { clearMarker, setPitchUrl } from "reducers/reviewsReducer";
import { useNavigate } from "react-router-dom";
import ImageSlideShow from "components/image-slideshow/ImageSlideShow";
import ReviewContext from "contexts/reviewContext";

//This file contains the logic to display document, video and image on review page.

function DashboardReviewViewer({ loading, name, type }: { name: string, type: string, loading: boolean }) {
	const { reviewerPitch } = useAppSelector(({ reviews }) => reviews);
	const { user: { userType = '' } = {} }: any = useAppSelector((auth) => auth);
	const { fileData, pngImages } = reviewerPitch;
	const dispatch = useAppDispatch();
	const [fileType, setFileType] = useState("image");
	const [imageLoading, setImageLoading] = useState(true);
	const navigate = useNavigate();
	const { setPage } = useContext(ReviewContext);

	useEffect(() => {
		if (type === "video") {
			setFileType("video")
			return;
		}

		//eslint-disable-next-line
	}, [])

	useEffect(() => {

		return () => {
			setFileType('video');
			setPage(1);
			dispatch(setPitchUrl(""));
			dispatch(clearMarker());
		}

		//eslint-disable-next-line
	}, [])

	const handleImageLoad = () => {
		setImageLoading(false);
	}

	const handleImageError = () => {
		setImageLoading(false); // Set loading to false in case of an error
	}

	return (
		<div className="col-lg-6 col-sm-12 reviews-div">
			<h1 className='reveiw-main-heading'>Reviews</h1>
			<hr />
			<div className="reveiw-direction">
				<h2 className='heading-2 d-flex align-items-center' onClick={() => navigate('/dashboard')}>
					<svg xmlns="http://www.w3.org/2000/svg" className='mr-1' width="20" height="18" viewBox="0 0 23.585 18.531" >
						<g id="arrow" transform="translate(25.185 24.932) rotate(180)">
							<path id="Path_26" data-name="Path 26" d="M14.729,6.894a1.685,1.685,0,0,1,2.382,0l7.581,7.581a1.685,1.685,0,0,1,0,2.382l-7.581,7.581a1.685,1.685,0,1,1-2.382-2.382l4.561-4.705h-16a1.685,1.685,0,1,1,0-3.369h16l-4.56-4.705A1.685,1.685,0,0,1,14.729,6.894Z" fill="#040404" />
						</g>
					</svg>

					<span className='deck-name'>{name}</span></h2>
				{(userType === 'expert') && <p className='plan-text'>
					<svg width="19" height="19" viewBox="0 0 19 19">
						<g id="Group_126" data-name="Group 126">
							<path id="Path_851" data-name="Path 851" d="M9.5,19A9.5,9.5,0,0,1,2.782,2.783,9.5,9.5,0,1,1,16.217,16.217,9.438,9.438,0,0,1,9.5,19M9.5.982A8.518,8.518,0,1,0,18.018,9.5,8.527,8.527,0,0,0,9.5.982" fill="#5f2deb"></path>
							<path id="Path_852" data-name="Path 852" d="M68.96,66.61l0,0a.318.318,0,0,1-.2-.294V61.879a.318.318,0,0,1,.356-.317,6.729,6.729,0,0,1,3.295,1.366.319.319,0,0,1,.032.478l-3.135,3.135a.318.318,0,0,1-.348.069m-1.375,0,0,0a.318.318,0,0,1-.348-.069L64.1,63.407a.319.319,0,0,1,.032-.478,6.729,6.729,0,0,1,3.295-1.366.318.318,0,0,1,.356.317v4.436a.318.318,0,0,1-.2.294m-.975.972,0,0a.318.318,0,0,1-.294.2H61.879a.318.318,0,0,1-.317-.356,6.729,6.729,0,0,1,1.366-3.295.319.319,0,0,1,.478-.032l3.135,3.135a.318.318,0,0,1,.069.348m.971,2.35,0,0a.318.318,0,0,1,.2.294v4.436a.318.318,0,0,1-.356.317,6.729,6.729,0,0,1-3.295-1.366.319.319,0,0,1-.032-.478L67.234,70a.318.318,0,0,1,.348-.069m1.375,0,0,0a.318.318,0,0,1,.348.069l3.135,3.135a.319.319,0,0,1-.032.478,6.729,6.729,0,0,1-3.295,1.366.318.318,0,0,1-.356-.317V70.226a.318.318,0,0,1,.2-.294m.975-.972,0,0a.318.318,0,0,1,.294-.2h4.436a.318.318,0,0,1,.317.356,6.729,6.729,0,0,1-1.366,3.295.319.319,0,0,1-.478.032L70,69.308a.318.318,0,0,1-.069-.348m4.732-1.179H70.227a.318.318,0,0,1-.294-.2l0,0A.318.318,0,0,1,70,67.234L73.134,64.1a.319.319,0,0,1,.478.032,6.729,6.729,0,0,1,1.366,3.295.318.318,0,0,1-.317.356m-12.784.978h4.436a.318.318,0,0,1,.294.2l0,0a.318.318,0,0,1-.069.348l-3.135,3.135a.319.319,0,0,1-.478-.032,6.729,6.729,0,0,1-1.366-3.295.318.318,0,0,1,.317-.356" transform="translate(-58.771 -58.771)" fill="#5f2deb"></path>
						</g>
					</svg>
					Plan: {type === "deck" ? "Review my pitch deck" : "Review my video pitch"}
				</p>}
			</div>
			<hr />
			<div className='viewer-container-dashboard'>
				{loading && <div className='loader'><Loader className='reviewer-loader' /></div>}
				{!loading && fileType !== "video" && pngImages.length === 0 && (
					<div className="image">
						<MarkerWrapper loading={imageLoading}>
							<div className={`image-container ${imageLoading ? 'loading' : ''}`}>
								<img src={fileData.url} alt="Who We Are" onLoad={handleImageLoad} onError={handleImageError} className={imageLoading ? 'blur' : ''} />
							</div>
						</MarkerWrapper>
					</div>
				)}
				{!loading && fileType !== "video" && pngImages.length !== 0 && (
					<ImageSlideShow imageSlider={pngImages} MarkerComponent={MarkerWrapper} />
				)}
				{!loading && fileType === "video" && (
					<div>
						<VideoPlayer attachmentSrc={fileData.url} />
					</div>
				)}
			</div>
		</div>
	)
}

export default DashboardReviewViewer