import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPitchesAction, getFounderStatsAction } from 'actions/founderDashboard';
import { uploadAndSyncPitches } from "actions/pitchActions";
import { Stats, pitchType } from "utils/types";

type createdPitchProp = {
    pitchId: string,
    serviceType: string,
}

const initialState = {
    pitchError: '',
    pitch: [] as pitchType[],
    pitchLoading: true,
    statsLoading: true,
    statsError: '',
    selectedPitch: {} as pitchType,
    createdPitch: {} as createdPitchProp,
    stats: {} as Stats,
    uploadingError: '',
};

const founderSlice = createSlice({
    name: "founderDashboard",
    initialState,
    reducers: {
        setSelectedPitch: (state, action: PayloadAction<any>) => {
            state.selectedPitch = action.payload;
        },
        setCreatedPitch: (state, action: PayloadAction<any>) => {
            state.createdPitch = action.payload;
        },
        updatePitch: (state, action: PayloadAction<any>) => {
            const { _id, pitchData } = action.payload;

            const pitchIndex = state.pitch.findIndex((pit) => pit._id === _id);
            if (pitchIndex !== -1) {
                const currentPitch = state.pitch[pitchIndex];

                state.pitch[pitchIndex] = {
                    ...currentPitch,
                    ...pitchData
                };
            }
        },
        deletePitch: (state, action : PayloadAction<any>) => {
            const {_id} = action.payload;
            
            const pitchCopy = [...state.pitch];
            var result = pitchCopy.filter(pit => pit._id !== _id);

            state.pitch = result;
        },
        clearFounderReducer: (state) => {
            state.pitch = initialState.pitch;
            state.pitchError = initialState.pitchError;
            state.pitchLoading = initialState.pitchLoading;
            state.statsLoading = initialState.statsLoading;
            state.statsError = initialState.statsError;
            state.selectedPitch = initialState.selectedPitch;
            state.createdPitch = initialState.createdPitch;
            state.stats = initialState.stats;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(getPitchesAction.pending, (state: any) => {
            state.pitchLoading = true;
        })
        builder.addCase(getPitchesAction.fulfilled, (state: any, action: PayloadAction<string>) => {
            state.pitchLoading = false;
            state.pitch = action.payload;
        })
        builder.addCase(getPitchesAction.rejected, (state: any, action: PayloadAction<string>) => {
            state.pitchLoading = false;
            state.pitchError = action.payload
            throw new Error("Error", { cause: action.payload });
        });
        builder.addCase(getFounderStatsAction.pending, (state: any) => {
            state.statsLoading = true;
        })
        builder.addCase(getFounderStatsAction.fulfilled, (state: any, action: PayloadAction<string>) => {
            state.statsLoading = false;
            state.stats = action.payload;
        })
        builder.addCase(getFounderStatsAction.rejected, (state: any, action: PayloadAction<string>) => {
            state.statsLoading = false;
            state.statsError = action.payload
            throw new Error("Error", { cause: action.payload });
        })
        builder.addCase(uploadAndSyncPitches.fulfilled, (state: any, action: PayloadAction<any>) => {
            const { pitchId, s3Response, localResponse } = action.payload;

            const pitchIndex = state.pitch.findIndex((pit) => pit._id === pitchId);
            if (pitchIndex !== -1) {
                const currentPitch = state.pitch[pitchIndex];

                state.pitch[pitchIndex] = {
                    ...currentPitch,
                    localUrl: localResponse.localUrl,
                    isLocalUploaded: localResponse.isLocalUploaded,
                    s3Url: !s3Response ? localResponse.s3Url : s3Response.s3Url,
                    isS3Uploaded: !s3Response ? localResponse.isS3Uploaded : s3Response.isS3Uploaded,
                    status: "open"
                };
            }
        })
    }
})

export default founderSlice.reducer;
export const { setSelectedPitch, setCreatedPitch, updatePitch, deletePitch, clearFounderReducer } = founderSlice.actions;