import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAccountValidity, getServicesAction } from 'actions/billingActions';

type Service = {
    _id: string,
    name: string,
    price: number,
    type: string,
    currency: string,
    description: string,
    tax: number
}
type ServiceType = Service[];
type InitialType = {
    services: ServiceType,
    founderService: Service,
    expertService: ServiceType,
    isAccountValidate: boolean,
    loading: boolean,
    verificationLoading: boolean,
    error: string
};
const initialState: InitialType = {
    services: [],
    founderService: {
        _id: "",
        name: "",
        price: 0,
        type: "",
        currency: "",
        description: "",
        tax: 0
    },
    expertService: [],
    isAccountValidate: false,
    loading: false,
    verificationLoading: false,
    error: ''
}

const serviceSlice = createSlice({
    name: 'service',
    initialState: initialState,
    reducers: {
        setFounderService: (state: InitialType, action: PayloadAction<any>) => {
            state.founderService = action.payload;
        },
        setExpertService: (state: InitialType, action: PayloadAction<any>) => {
            state.expertService = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(getServicesAction.pending, (state: InitialType, action: PayloadAction<string>) => {
            state.loading = true;
        });
        builder.addCase(getServicesAction.fulfilled, (state: InitialType, action: PayloadAction<any>) => {
            state.loading = false;
            state.services = action.payload;
        });
        builder.addCase(getServicesAction.rejected, (state: InitialType, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(getAccountValidity.pending, (state: InitialType) => {
            state.verificationLoading = true;
        });
        builder.addCase(getAccountValidity.fulfilled, (state: InitialType, action: PayloadAction<boolean>) => {
            state.verificationLoading = false;
            state.isAccountValidate = action.payload;
        });
        builder.addCase(getAccountValidity.rejected, (state: InitialType, action: PayloadAction<any>) => {
            state.verificationLoading = false;
            throw new Error("Error", { cause: action.payload });
        })
    }
})

export default serviceSlice.reducer;
export const { setFounderService, setExpertService } = serviceSlice.actions;