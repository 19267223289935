import './transection_successfull.scss';
import { useNavigate } from 'react-router-dom';

function TransactionSuccessfull() {
	const navigate = useNavigate();
	
	return (
		<div className="col-lg-5 col-md-6" data-aos-easing="ease-in-sine">
			<div className='card transection-wrapper'>
				<div className="inner-wrapper">

					<span className='paymentIcon'>
						<svg xmlns="http://www.w3.org/2000/svg" width="84.834" height="84.102" viewBox="0 0 84.834 84.102">
							<g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-0.992 -0.945)">
								<path id="Path_1332" data-name="Path 1332" d="M83,39.335v3.68A40,40,0,1,1,59.279,6.455" transform="translate(0)" fill="none" stroke="#5f2deb" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
								<path id="Path_1333" data-name="Path 1333" d="M65.5,6l-40,40.039-12-12" transform="translate(17.499 5.015)" fill="none" stroke="#5f2deb" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
							</g>
						</svg>
					</span>

					<h3 className='sm'>Transaction Completed Successfully</h3>
					<p>Thank you for your billing.</p>
				</div>

				<div className="button-row">
					<button className="btn btn-secondary" onClick={() => navigate('/deck-info')}>Upload your deck</button>
				</div>
			</div>
		</div>
	)
}

export default TransactionSuccessfull