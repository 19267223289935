import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getScales, updateFeedbackAction } from "actions/reviewActions";
import { ScaleProps } from 'utils/types';
import { setUserScale } from 'reducers/reviewsReducer';
import classNames from 'classnames';
import Loader from 'components/loader/Loader';
import { updatePitch } from 'reducers/founderDashboardReducer';
import FounderFeedback from './FounderFeedback';

//This file contains the logic to display or create the pitch feedback.

function DashboardUserReviews() {
	const { scales, userScales, feedbackId } = useAppSelector(({ reviews }) => reviews);
	const { profile } = useAppSelector(({ profileStore }) => profileStore);
	const { selectedPitch } = useAppSelector(({ founderDasboard }) => founderDasboard);
	const { user: { userType } }: any = useAppSelector(({ auth }) => auth);
	const [loading, setLoading] = useState(false);
	const [allScales, setScales] = useState<ScaleProps[]>([]);
	const [responseSubmit, setResponseSubmit] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (userScales.length !== 0) {
			setResponseSubmit(true);
		}

		setScales([...userScales]);
	}, [userScales])

	useEffect(() => {
		if (scales.length !== 0) return;

		(async () => {
			try {
				await dispatch(getScales());
			} catch (err) {
				console.log(err);
			}
		})();

		//eslint-disable-next-line
	}, [dispatch])

	const handleScale = (scale: ScaleProps) => {
		setScales(currentScales => {
			const index = currentScales.findIndex(ascale => ascale.key === scale.key);
			if (index !== -1) {
				return currentScales.filter((_, i) => i !== index);
			} else {
				return [...currentScales, scale];
			}
		});

	}


	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await updateFeedbackAction({ scales: allScales, _id: feedbackId, pitchId: selectedPitch._id });
			dispatch(setUserScale(allScales));
			dispatch(updatePitch({ _id: selectedPitch._id, pitchData: { status: "reviewed" } }));
			setResponseSubmit(true);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<>
			<div className='review-content'>
				{profile.__t === "founder" && userScales.length !== 0 && (
					<h1 className='reveiw-congratulations'>Congratulations your pitch got reviewed</h1>
				)}

				{profile.__t === "founder" && userScales.length === 0 && (
					<h1 className='reveiw-congratulations'>Kindly wait for expert to review your pitch</h1>
				)}

				{profile.__t !== "founder" && (
					<h1 className='reveiw-congratulations'>{responseSubmit ? "You reviewed this pitch" : "Please Review the deck"}</h1>
				)}

				{scales.map((scale, index) =>
					<div key={index} className="checkbox mt-25">
						<label className={classNames({
							"check-not-allowed": responseSubmit || profile.__t === "founder"
						})}>
							<input type="checkbox" name="checkbox" checked={allScales && allScales.some(ascale => ascale.key === scale.key)} onClick={() => handleScale(scale)} />
							<span>{scale.optionName}</span>
						</label>
					</div>
				)}

				{profile.__t !== "founder" && !responseSubmit && (
					<button disabled={responseSubmit || loading} className='btn btn-primary mt-25 mb-5' type="button" onClick={handleSubmit}>{loading ? <Loader /> : "Submit"}</button>
				)}
				{(userType === 'founder' && ['reviewed', 'unpaid'].includes(selectedPitch.status)) && <FounderFeedback />}
			</div>
		</>
	)
}

export default DashboardUserReviews;
