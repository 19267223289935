import { Field } from "formik";

// this is the custom formik field

const CustomFormikField = (props: any) => {
    const { setFieldValue, isLower = false, name, handleChange } = props;
    return (
        <Field
            name={name}
            render={({ field }) => (
                <input
                    {...field}
                    {...props}
                    autoComplete={"off"}
                    onChange={(e) => {
                        if (handleChange) {
                            handleChange(e, field);
                        } else {
                            field.onChange(e)
                        }
                    }}
                    onBlur={(e) => {
                        let value = e.target.value.trim(); // Remove leading and trailing spaces
                        if (isLower) value = value.toLowerCase(); // Convert to lowercase
                        setFieldValue(field.name, value); // Update field value
                        field.onBlur(e);
                    }}
                />
            )}
        />
    );
};

export default CustomFormikField;