
import axiosInstance from "axios-instance";
import { pitchType } from "utils/types";
const baseURL = process.env.REACT_APP_BASE_URL;


//this file contains the document upload, get and delete API

export const uploadToS3 = async (file: File, folderName: string) => {
  try {

    const formData = new FormData();
    formData.append('file', file);
    const response = await axiosInstance.post(`/api/upload?folderName=${folderName}`, formData);
    return response.data;

  } catch ({ response: { data } }: any) {
    return data;
  }
};

export const getS3Url = async (obj: pitchType) => {

  const { s3Url, isS3Uploaded, isLocalUploaded, localUrl } = obj;

  try {
    var response = "";

    if (isS3Uploaded) {
      var res = await axiosInstance.get(`/api/upload`, {
        params: {
          s3Url
        }
      });
      response = res.data.url;
    }

    if (isLocalUploaded && !isS3Uploaded) {
      response = `${baseURL}/${localUrl}`;
    }

    return response;
  } catch (error: any) {
    console.log(error);
    throw new Error("Error", { cause: error.response });
  }
}

export const deleteFileS3 = async (url: string | null) => {
  try {
    const response = await axiosInstance.delete('/api/upload', { data: { url } });

    return response;
  } catch ({ response: { data } }: any) {
    return data;
  }
}