import { useAppDispatch, useAppSelector } from "hooks";
import { updateProfile } from "reducers/profile";
import { uploadProfileImage } from "actions/settings";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

//this file contains the information of user profile on settings page.
const ProfileSettings = ({ errors, updateProfileStore, setName, name }: any) => {

    const { profile: { email, linkedinProfile, profileImageUrl } }: any = useAppSelector(({ profileStore }) => profileStore);
    const dispatch = useAppDispatch();
    const absoluteImageUrl = profileImageUrl ? `${IMAGE_URL}/${profileImageUrl}`: "/user.svg";

    const onChange = (e: any, type: string) => {
        switch (type) {
            case 'name':
                //Not updating the name directly as its visible on the dashboard.
                setName(e.target.value)
                break;
            default:
                dispatch(updateProfile({ type: type, value: e.target.value }));
        }
    }

    const onSelectProfileImage = async (event: any) => {
        try {
            if (event.target.files && event.target.files[0]) {
                const formData = new FormData();
                formData.append('file', event.target.files[0]);
                await uploadProfileImage(formData);
                updateProfileStore();
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="profile-inner-wrapper">
            <div className="img-uploader">
                <div className="img-uploader_left">
                    <img src={absoluteImageUrl} alt={'whatapitch'} />
                </div>
                <div className="img-uploader_right">
                    <p>Upload your Profile Picture</p>
                    <div className="upload-btn-wrapper">
                        <button className="btn btn-primary sm">Browse</button>
                        {/* <input type="file" name="myfile" onChange={onSelectProfileImage} /> */}
                        <input type="file" name="myfile" onChange={onSelectProfileImage} />
                    </div>
                </div>
            </div>
            <div className="custom-form-wrapper">
                <div className="row">
                <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Full name</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Jhon Smith"
                                value={name}
                                onChange={(e) => onChange(e, 'name')}
                            />
                            <div className="error p-1">{errors.name}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">LinkedIn Profile</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="LinkedIn.com/sidaisnjkdass"
                                value={linkedinProfile}
                                onChange={(e) => onChange(e, 'linkedinProfile')}
                            />
                            <div className="error p-1">{errors.linkedinProfile}</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="#" className="control-label">Email address</label>
                            <input
                                name='email'
                                id='email'
                                type="email"
                                className="form-control"
                                placeholder="xyz@example.com"
                                value={email}
                                onChange={(e) => onChange(e, 'email')}
                            />
                            <div className="error p-1">{errors.email}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default ProfileSettings;