import { Link } from 'react-router-dom';
import logonew from "assets/images/logo-t.png";
import "./footer.scss";

//this file contains the footer code.

function Footer() {

    return (
        <>
            <footer>
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-xl-4 col-md-12 mb-15">
                            <div className='mb-40'>
                                <img  src={logonew} alt="logo" />
                            </div>
                            <p className='text-xl mb-28'>
                                The easiest way to <span>perfect your pitch</span> and get back to changing the world.
                            </p>
                            <p className='font-medium'>
                            © 2024 WhataPitch. All Rights Reserved
                            </p>
                        </div>
                        <div className="col-xl-5 col-md-6">
                            <div className="row">
                                <div className="col-6">
                                    <h5>WhatAPitch</h5>
                                    <ul className='list-unstyled'>
                                        <li>
                                            <Link to="/#">For Founders</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">For Experts</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Pricing</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Sign Up</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6">
                                    <h5>About Us</h5>
                                    <ul className='list-unstyled'>
                                        <li>
                                            <Link to="/#">Who We are</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Our Founders Promise</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Our Expert Promise</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Testimonials</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/termsOfService">Terms of Service</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Blog</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6">
                                    <h5>Fundraising <div>Resources</div></h5>
                                    <ul className='list-unstyled'>
                                        <li>
                                            <Link to="/#">Deck Design</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Logo Design</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Masterclass</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Pitch Deck Templates</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Pitch Deck Reviews</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Blog / Resources</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Referral Program</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6">
                                    <h5>Expert <div>Resources</div></h5>
                                    <ul className='list-unstyled'>
                                        <li>
                                            <Link to="/#">Blog / Resources</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Referral Program</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Qualifications and Requirements</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Masterclass</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Review Templatess</Link>
                                        </li>
                                        <li>
                                            <Link to="/#">Early-Stage Insights</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div>
                                <h5>Social Media</h5>
                                <ul className="social-links list-unstyled">
                                    <li>
                                        <Link to="/#">
                                            <i className="fa-brands fa-linkedin"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/#"><i className="fa-brands fa-square-x-twitter"></i></Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/" target="_blank"><i className="fa-brands fa-youtube"></i></Link>
                                    </li> */}
                                    <li>
                                        <Link to="/#"><i className="fa-brands fa-instagram"></i></Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='custom-top-margin'>
                                <h5>Join The Community</h5>
                                <p className='font-medium'>Fundraising insights from both sides of the spectrum.</p>
                                <input type="text" className="form-control sm" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
