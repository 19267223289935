import { ErrorMessage } from 'formik';
import { useEffect } from 'react';
import TextError from 'components/TextError';
import CustomFormikField from 'components/CustomFormikField/CustomFormikField';

const PersonalInfo = ({ setTouched, setFieldValue }: any) => {
    useEffect(() => {
        setTouched({
            founderName: true,
            companyName: true,
            companyWebsite: true,
            isIncorporated: true,
            incorporationDetails: true,
            roundSize: true,
            roundProgress: true,
            companyDescription: true
        });
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="form-group extra-mb">
                <label htmlFor="name" className="control-label big" style={{opacity: 0}}>Hey! What is your name?</label>
                <CustomFormikField
                    type="text"
                    name='founderName'
                    id='founderName'
                    className="form-control only-bottom-b"
                    placeholder="Enter your full name."
                    setFieldValue={setFieldValue}
                />
                <div className="error-field">
                    <ErrorMessage name='founderName' component={TextError} />
                </div>
            </div>
            <div className="form-group extra-mb">
                <label htmlFor="linkedinProfile" className="control-label big" style={{opacity: 0}}>Hey! What is your name?</label>
                <CustomFormikField
                    type="text"
                    className="form-control only-bottom-b"
                    placeholder="Enter your LinkedIn profile URL."
                    name="linkedinProfile"
                    id="linkedinProfile"
                    setFieldValue={setFieldValue}
                    style={{marginBottom: '20px'}}
                />
                < ErrorMessage name='linkedinProfile' component={TextError} />
            </div>
        </>
    );
};

export default PersonalInfo;