import Accordion from 'react-bootstrap/Accordion';
import "./index.scss";

function Faqs({ faqs }) {

  //This file contains the logic to display the faqs.

  return (
    <>
      <section className='Faqs'>
        <div className="container">
          <h2 className='sectionTitle'>Frequently Asked Questions</h2>
          <div className="accordionWrapper">
            <Accordion alwaysOpen>
              {faqs.map((faq, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{faq.question}</Accordion.Header>
                  <Accordion.Body>
                    <p>{faq.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faqs
