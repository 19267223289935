import Loader from "components/loader/Loader";
import { useAppDispatch, useAppSelector } from "hooks";
import { setExpertService } from "reducers/billingReducer";

const ExpertPlan = ({ services, loading }: any) => {
    const { expertService = [] } = useAppSelector(({ service }) => service);
    const dispatch = useAppDispatch();

    const planCheckboxClick = (event: { target: any; }) => {
        const checkbox = event.target;
        const parentElement = checkbox.parentElement;
        const serviceId = checkbox.value;
        const obj = services.find(obj => obj._id === serviceId);
        let newServices: any = [];
        if (checkbox.checked) {
            parentElement.classList.add('selected');
            newServices = [...expertService, obj];
            dispatch(setExpertService(newServices));
        } else {
            parentElement.classList.remove('selected');
            newServices = expertService.filter(s => s._id !== obj._id);
            dispatch(setExpertService(newServices));
        }
    };
    const renderServices = () => {
        return services.map(({ _id, description, currency, price }: any, index: number) => {
            const [currencyName, currencySign] = currency.split(":");
            const className = (index === 0) ? 'planLabel rm-bottom-borders' : 'planLabel rm-top-borders';
            return (<div key={_id} className="check">
                <label className={className}>
                    <input type="checkbox" name="size1" value={_id} className='radio-input' onClick={planCheckboxClick} />
                    <span>{description} <span className='amount'>{currencySign} {price} <span>/Review</span></span></span>
                </label>
            </div>);
        });
    };
    return (
        <div className="col-lg-7 col-md-6" data-aos="fade-right" data-aos-easing="ease-in-sine">
            <h2>Select the services you are interested in providing</h2>
            <form action="">
                <div className="check-buttons">
                    {(loading) ? <Loader /> : renderServices()}
                </div>
            </form>
        </div>
    )
};

export default ExpertPlan;