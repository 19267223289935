import { Link } from 'react-router-dom';
import expert1 from "assets/images/expert-1.png";
import expert2 from "assets/images/expert-2.png";
import expert3 from "assets/images/expert-3.png";
import expert4 from "assets/images/expert-4.png";
import expert5 from "assets/images/expert-5.png";
import Slider from "react-slick";
import "./index.scss";

//This file contains the logic to display the experts.

function Experts() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        arrows: false,
        slidesToScroll: 5,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToScroll: 4,
              slidesToShow: 4,
              dots : true,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToScroll: 3, 
              slidesToShow: 3,
              dots : true,
              arrows: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToScroll: 2,
              slidesToShow: 2,
              dots : true,
              arrows: false
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots : true,
              arrows: false
            }
          }
        ]
      };
    return (
        <>
            <section className='experts' data-aos="fade-right" data-aos-easing="ease-in-sine">
                <div className="container">
                    <h2 className='sectionTitle mb-16 lg'>Top Experts</h2>
                    <h3 className='subTitle'>Access the world’s best founders, investors and industry advisors.</h3>

                    <Slider {...settings}>
                        <div className='experts_listing'>
                            <div className="experts_listing--img image-hover">
                                <img src={expert1} alt="Danny Tawaii" />   
                            </div>
                            <div className="experts_listing--desc">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Danny Tawaii</h5>
                                    <div className="rating">
                                        <svg width="19" height="19" viewBox="0 0 19 19">
                                            <g id="Group_126" data-name="Group 126">
                                                <path id="Path_851" data-name="Path 851" d="M9.5,19A9.5,9.5,0,0,1,2.782,2.783,9.5,9.5,0,1,1,16.217,16.217,9.438,9.438,0,0,1,9.5,19M9.5.982A8.518,8.518,0,1,0,18.018,9.5,8.527,8.527,0,0,0,9.5.982" fill="#5f2deb"/>
                                                <path id="Path_852" data-name="Path 852" d="M68.96,66.61l0,0a.318.318,0,0,1-.2-.294V61.879a.318.318,0,0,1,.356-.317,6.729,6.729,0,0,1,3.295,1.366.319.319,0,0,1,.032.478l-3.135,3.135a.318.318,0,0,1-.348.069m-1.375,0,0,0a.318.318,0,0,1-.348-.069L64.1,63.407a.319.319,0,0,1,.032-.478,6.729,6.729,0,0,1,3.295-1.366.318.318,0,0,1,.356.317v4.436a.318.318,0,0,1-.2.294m-.975.972,0,0a.318.318,0,0,1-.294.2H61.879a.318.318,0,0,1-.317-.356,6.729,6.729,0,0,1,1.366-3.295.319.319,0,0,1,.478-.032l3.135,3.135a.318.318,0,0,1,.069.348m.971,2.35,0,0a.318.318,0,0,1,.2.294v4.436a.318.318,0,0,1-.356.317,6.729,6.729,0,0,1-3.295-1.366.319.319,0,0,1-.032-.478L67.234,70a.318.318,0,0,1,.348-.069m1.375,0,0,0a.318.318,0,0,1,.348.069l3.135,3.135a.319.319,0,0,1-.032.478,6.729,6.729,0,0,1-3.295,1.366.318.318,0,0,1-.356-.317V70.226a.318.318,0,0,1,.2-.294m.975-.972,0,0a.318.318,0,0,1,.294-.2h4.436a.318.318,0,0,1,.317.356,6.729,6.729,0,0,1-1.366,3.295.319.319,0,0,1-.478.032L70,69.308a.318.318,0,0,1-.069-.348m4.732-1.179H70.227a.318.318,0,0,1-.294-.2l0,0A.318.318,0,0,1,70,67.234L73.134,64.1a.319.319,0,0,1,.478.032,6.729,6.729,0,0,1,1.366,3.295.318.318,0,0,1-.317.356m-12.784.978h4.436a.318.318,0,0,1,.294.2l0,0a.318.318,0,0,1-.069.348l-3.135,3.135a.319.319,0,0,1-.478-.032,6.729,6.729,0,0,1-1.366-3.295.318.318,0,0,1,.317-.356" transform="translate(-58.771 -58.771)" fill="#5f2deb"/>
                                            </g>
                                        </svg>
                                        5.0
                                    </div>
                                </div>
                                <p>Former VP, Global Branding at Nike</p>
                            </div>
                        </div>
                        <div className='experts_listing'>
                            <div className="experts_listing--img image-hover">
                                <img src={expert2} alt="Cyrus Massoumi" />   
                            </div>
                            <div className="experts_listing--desc">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Cyrus Massoumi</h5>
                                    <div className="rating">
                                        <svg width="19" height="19" viewBox="0 0 19 19">
                                            <g id="Group_126" data-name="Group 126">
                                                <path id="Path_851" data-name="Path 851" d="M9.5,19A9.5,9.5,0,0,1,2.782,2.783,9.5,9.5,0,1,1,16.217,16.217,9.438,9.438,0,0,1,9.5,19M9.5.982A8.518,8.518,0,1,0,18.018,9.5,8.527,8.527,0,0,0,9.5.982" fill="#5f2deb"/>
                                                <path id="Path_852" data-name="Path 852" d="M68.96,66.61l0,0a.318.318,0,0,1-.2-.294V61.879a.318.318,0,0,1,.356-.317,6.729,6.729,0,0,1,3.295,1.366.319.319,0,0,1,.032.478l-3.135,3.135a.318.318,0,0,1-.348.069m-1.375,0,0,0a.318.318,0,0,1-.348-.069L64.1,63.407a.319.319,0,0,1,.032-.478,6.729,6.729,0,0,1,3.295-1.366.318.318,0,0,1,.356.317v4.436a.318.318,0,0,1-.2.294m-.975.972,0,0a.318.318,0,0,1-.294.2H61.879a.318.318,0,0,1-.317-.356,6.729,6.729,0,0,1,1.366-3.295.319.319,0,0,1,.478-.032l3.135,3.135a.318.318,0,0,1,.069.348m.971,2.35,0,0a.318.318,0,0,1,.2.294v4.436a.318.318,0,0,1-.356.317,6.729,6.729,0,0,1-3.295-1.366.319.319,0,0,1-.032-.478L67.234,70a.318.318,0,0,1,.348-.069m1.375,0,0,0a.318.318,0,0,1,.348.069l3.135,3.135a.319.319,0,0,1-.032.478,6.729,6.729,0,0,1-3.295,1.366.318.318,0,0,1-.356-.317V70.226a.318.318,0,0,1,.2-.294m.975-.972,0,0a.318.318,0,0,1,.294-.2h4.436a.318.318,0,0,1,.317.356,6.729,6.729,0,0,1-1.366,3.295.319.319,0,0,1-.478.032L70,69.308a.318.318,0,0,1-.069-.348m4.732-1.179H70.227a.318.318,0,0,1-.294-.2l0,0A.318.318,0,0,1,70,67.234L73.134,64.1a.319.319,0,0,1,.478.032,6.729,6.729,0,0,1,1.366,3.295.318.318,0,0,1-.317.356m-12.784.978h4.436a.318.318,0,0,1,.294.2l0,0a.318.318,0,0,1-.069.348l-3.135,3.135a.319.319,0,0,1-.478-.032,6.729,6.729,0,0,1-1.366-3.295.318.318,0,0,1,.317-.356" transform="translate(-58.771 -58.771)" fill="#5f2deb"/>
                                            </g>
                                        </svg>
                                        5.0
                                    </div>
                                </div>
                                <p>Founder, CEO, Dr. B, Former Founder, ZocDoc. Humbition Ventures</p>
                            </div>
                        </div>
                        <div className='experts_listing'>
                            <div className="experts_listing--img image-hover">
                                <img src={expert3} alt="Jon Weiss" />   
                            </div>
                            <div className="experts_listing--desc">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Jon Weiss</h5>
                                    <div className="rating">
                                        <svg width="19" height="19" viewBox="0 0 19 19">
                                            <g id="Group_126" data-name="Group 126">
                                                <path id="Path_851" data-name="Path 851" d="M9.5,19A9.5,9.5,0,0,1,2.782,2.783,9.5,9.5,0,1,1,16.217,16.217,9.438,9.438,0,0,1,9.5,19M9.5.982A8.518,8.518,0,1,0,18.018,9.5,8.527,8.527,0,0,0,9.5.982" fill="#5f2deb"/>
                                                <path id="Path_852" data-name="Path 852" d="M68.96,66.61l0,0a.318.318,0,0,1-.2-.294V61.879a.318.318,0,0,1,.356-.317,6.729,6.729,0,0,1,3.295,1.366.319.319,0,0,1,.032.478l-3.135,3.135a.318.318,0,0,1-.348.069m-1.375,0,0,0a.318.318,0,0,1-.348-.069L64.1,63.407a.319.319,0,0,1,.032-.478,6.729,6.729,0,0,1,3.295-1.366.318.318,0,0,1,.356.317v4.436a.318.318,0,0,1-.2.294m-.975.972,0,0a.318.318,0,0,1-.294.2H61.879a.318.318,0,0,1-.317-.356,6.729,6.729,0,0,1,1.366-3.295.319.319,0,0,1,.478-.032l3.135,3.135a.318.318,0,0,1,.069.348m.971,2.35,0,0a.318.318,0,0,1,.2.294v4.436a.318.318,0,0,1-.356.317,6.729,6.729,0,0,1-3.295-1.366.319.319,0,0,1-.032-.478L67.234,70a.318.318,0,0,1,.348-.069m1.375,0,0,0a.318.318,0,0,1,.348.069l3.135,3.135a.319.319,0,0,1-.032.478,6.729,6.729,0,0,1-3.295,1.366.318.318,0,0,1-.356-.317V70.226a.318.318,0,0,1,.2-.294m.975-.972,0,0a.318.318,0,0,1,.294-.2h4.436a.318.318,0,0,1,.317.356,6.729,6.729,0,0,1-1.366,3.295.319.319,0,0,1-.478.032L70,69.308a.318.318,0,0,1-.069-.348m4.732-1.179H70.227a.318.318,0,0,1-.294-.2l0,0A.318.318,0,0,1,70,67.234L73.134,64.1a.319.319,0,0,1,.478.032,6.729,6.729,0,0,1,1.366,3.295.318.318,0,0,1-.317.356m-12.784.978h4.436a.318.318,0,0,1,.294.2l0,0a.318.318,0,0,1-.069.348l-3.135,3.135a.319.319,0,0,1-.478-.032,6.729,6.729,0,0,1-1.366-3.295.318.318,0,0,1,.317-.356" transform="translate(-58.771 -58.771)" fill="#5f2deb"/>
                                            </g>
                                        </svg>
                                        5.0
                                    </div>
                                </div>
                                <p>Angel Investor, Founder of New Buffalo (Acquired by Guildan)</p>
                            </div>
                        </div>
                        <div className='experts_listing'>
                            <div className="experts_listing--img image-hover">
                                <img src={expert4} alt="Norm Brodsky" />   
                            </div>
                            <div className="experts_listing--desc">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Norm Brodsky</h5>
                                    <div className="rating">
                                        <svg width="19" height="19" viewBox="0 0 19 19">
                                            <g id="Group_126" data-name="Group 126">
                                                <path id="Path_851" data-name="Path 851" d="M9.5,19A9.5,9.5,0,0,1,2.782,2.783,9.5,9.5,0,1,1,16.217,16.217,9.438,9.438,0,0,1,9.5,19M9.5.982A8.518,8.518,0,1,0,18.018,9.5,8.527,8.527,0,0,0,9.5.982" fill="#5f2deb"/>
                                                <path id="Path_852" data-name="Path 852" d="M68.96,66.61l0,0a.318.318,0,0,1-.2-.294V61.879a.318.318,0,0,1,.356-.317,6.729,6.729,0,0,1,3.295,1.366.319.319,0,0,1,.032.478l-3.135,3.135a.318.318,0,0,1-.348.069m-1.375,0,0,0a.318.318,0,0,1-.348-.069L64.1,63.407a.319.319,0,0,1,.032-.478,6.729,6.729,0,0,1,3.295-1.366.318.318,0,0,1,.356.317v4.436a.318.318,0,0,1-.2.294m-.975.972,0,0a.318.318,0,0,1-.294.2H61.879a.318.318,0,0,1-.317-.356,6.729,6.729,0,0,1,1.366-3.295.319.319,0,0,1,.478-.032l3.135,3.135a.318.318,0,0,1,.069.348m.971,2.35,0,0a.318.318,0,0,1,.2.294v4.436a.318.318,0,0,1-.356.317,6.729,6.729,0,0,1-3.295-1.366.319.319,0,0,1-.032-.478L67.234,70a.318.318,0,0,1,.348-.069m1.375,0,0,0a.318.318,0,0,1,.348.069l3.135,3.135a.319.319,0,0,1-.032.478,6.729,6.729,0,0,1-3.295,1.366.318.318,0,0,1-.356-.317V70.226a.318.318,0,0,1,.2-.294m.975-.972,0,0a.318.318,0,0,1,.294-.2h4.436a.318.318,0,0,1,.317.356,6.729,6.729,0,0,1-1.366,3.295.319.319,0,0,1-.478.032L70,69.308a.318.318,0,0,1-.069-.348m4.732-1.179H70.227a.318.318,0,0,1-.294-.2l0,0A.318.318,0,0,1,70,67.234L73.134,64.1a.319.319,0,0,1,.478.032,6.729,6.729,0,0,1,1.366,3.295.318.318,0,0,1-.317.356m-12.784.978h4.436a.318.318,0,0,1,.294.2l0,0a.318.318,0,0,1-.069.348l-3.135,3.135a.319.319,0,0,1-.478-.032,6.729,6.729,0,0,1-1.366-3.295.318.318,0,0,1,.317-.356" transform="translate(-58.771 -58.771)" fill="#5f2deb"/>
                                            </g>
                                        </svg>
                                        5.0
                                    </div>
                                </div>
                                <p>Investor, NY Times Best Selling Author.</p>
                            </div>
                        </div>
                        <div className='experts_listing'>
                            <div className="experts_listing--img image-hover">
                                <img src={expert5} alt="Phil Kaplan" />   
                            </div>
                            <div className="experts_listing--desc">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Phil Kaplan</h5>
                                    <div className="rating">
                                        <svg width="19" height="19" viewBox="0 0 19 19">
                                            <g id="Group_126" data-name="Group 126">
                                                <path id="Path_851" data-name="Path 851" d="M9.5,19A9.5,9.5,0,0,1,2.782,2.783,9.5,9.5,0,1,1,16.217,16.217,9.438,9.438,0,0,1,9.5,19M9.5.982A8.518,8.518,0,1,0,18.018,9.5,8.527,8.527,0,0,0,9.5.982" fill="#5f2deb"/>
                                                <path id="Path_852" data-name="Path 852" d="M68.96,66.61l0,0a.318.318,0,0,1-.2-.294V61.879a.318.318,0,0,1,.356-.317,6.729,6.729,0,0,1,3.295,1.366.319.319,0,0,1,.032.478l-3.135,3.135a.318.318,0,0,1-.348.069m-1.375,0,0,0a.318.318,0,0,1-.348-.069L64.1,63.407a.319.319,0,0,1,.032-.478,6.729,6.729,0,0,1,3.295-1.366.318.318,0,0,1,.356.317v4.436a.318.318,0,0,1-.2.294m-.975.972,0,0a.318.318,0,0,1-.294.2H61.879a.318.318,0,0,1-.317-.356,6.729,6.729,0,0,1,1.366-3.295.319.319,0,0,1,.478-.032l3.135,3.135a.318.318,0,0,1,.069.348m.971,2.35,0,0a.318.318,0,0,1,.2.294v4.436a.318.318,0,0,1-.356.317,6.729,6.729,0,0,1-3.295-1.366.319.319,0,0,1-.032-.478L67.234,70a.318.318,0,0,1,.348-.069m1.375,0,0,0a.318.318,0,0,1,.348.069l3.135,3.135a.319.319,0,0,1-.032.478,6.729,6.729,0,0,1-3.295,1.366.318.318,0,0,1-.356-.317V70.226a.318.318,0,0,1,.2-.294m.975-.972,0,0a.318.318,0,0,1,.294-.2h4.436a.318.318,0,0,1,.317.356,6.729,6.729,0,0,1-1.366,3.295.319.319,0,0,1-.478.032L70,69.308a.318.318,0,0,1-.069-.348m4.732-1.179H70.227a.318.318,0,0,1-.294-.2l0,0A.318.318,0,0,1,70,67.234L73.134,64.1a.319.319,0,0,1,.478.032,6.729,6.729,0,0,1,1.366,3.295.318.318,0,0,1-.317.356m-12.784.978h4.436a.318.318,0,0,1,.294.2l0,0a.318.318,0,0,1-.069.348l-3.135,3.135a.319.319,0,0,1-.478-.032,6.729,6.729,0,0,1-1.366-3.295.318.318,0,0,1,.317-.356" transform="translate(-58.771 -58.771)" fill="#5f2deb"/>
                                            </g>
                                        </svg>
                                        5.0
                                    </div>
                                </div>
                                <p>Founder, CEO DistroKid</p>
                            </div>
                        </div>
                    </Slider>

                    <Link to="/" className='many-more'>and countless more!</Link>
                </div>
            </section>
        </>
    )
}

export default Experts
