import React, { useContext } from 'react';
import ReviewContext from 'contexts/reviewContext';

//This file contains the logic to create comment.

type createCommentProps = {
    onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

function CreateComment({ onKeyDown, onChange }: createCommentProps) {
    const { isPinActive, toggleIsPinActive, commentInputRef } = useContext(ReviewContext);


    return (
        <div className="pin-container mb-5">
            <textarea ref={commentInputRef} onChange={onChange} onKeyDown={onKeyDown} placeholder='Type your notes here' />
            <button className={`pin-button ${isPinActive && 'pin-button-active'}`} onClick={toggleIsPinActive}>
                <svg id="pin-angle-fill" xmlns="http://www.w3.org/2000/svg" width="28" height="27.999" viewBox="0 0 28 27.999">
                    <g id="Group_41" data-name="Group 41">
                        <path id="Path_15" data-name="Path 15" d="M21,2.166a1,1,0,0,1,.71.293l9.922,9.922a1,1,0,0,1,0,1.417,4.177,4.177,0,0,1-3.013,1.179A5.6,5.6,0,0,1,27.7,14.9L21.418,21.18a11.88,11.88,0,0,1,.321,2.03A6.071,6.071,0,0,1,20.3,27.971a1,1,0,0,1-1.417,0L13.208,22.3,6.83,28.681C6.439,29.071,4.386,30.489,4,30.1s1.026-2.445,1.417-2.834l6.378-6.378L6.122,15.215a1,1,0,0,1,0-1.417,6.063,6.063,0,0,1,4.761-1.443,11.87,11.87,0,0,1,2.03.321L19.2,6.4a5.556,5.556,0,0,1-.08-.924A4.178,4.178,0,0,1,20.3,2.459,1,1,0,0,1,21,2.166Z" transform="translate(-3.928 -2.166)" fill="#5f2deb" />
                    </g>
                </svg>
            </button>
        </div>
    )
}

export default CreateComment