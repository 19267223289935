import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllTransfers, getBankDetails } from "actions/wallet";
const initialState = {
    transfers: [],
    bank: {},
    error: "",
    loading: false
};

const walletSlice = createSlice({
    name: "wallet",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getAllTransfers.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getAllTransfers.fulfilled, (state: any, action: PayloadAction) => {
            state.loading = false;
            state.transfers = action.payload;
        });
        builder.addCase(getAllTransfers.rejected, (state: any, action: PayloadAction) => {
            state.loading = false;
            state.error = action.payload;
            throw new Error('Error', { cause: action.payload })
        });
        builder.addCase(getBankDetails.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getBankDetails.fulfilled, (state: any, action: PayloadAction) => {
            state.loading = false;
            state.bank = action.payload;
        });
        builder.addCase(getBankDetails.rejected, (state: any, action: PayloadAction) => {
            state.loading = false;
            state.error = action.payload;
            throw new Error('Error', { cause: action.payload })
        });
    }
});

export default walletSlice.reducer;