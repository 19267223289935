import lemonDetails from "assets/images/about-founders-updated.png";
import lemonBig from "assets/images/svg/lemon-big.svg";
import connect from "assets/images/svg/connect.svg";
import uploaddeck from "assets/images/svg/software-upload.svg";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import Technologies from "components/partial/technologies/Technologies";
import Experts from "components/partial/experts/Experts";
import Reviews from "components/reviews/Reviews";
import Faqs from "components/partial/faqs/Faqs";
import Packages from "components/partial/packages/Packages"
import "./styles.scss";
import { useLocation, useNavigate } from "react-router";
import { faqArrayProps, reviewArrayProps } from "utils/types";
import { useEffect } from "react";

const reviewsArray: reviewArrayProps[] = [
  { id: 1, content: '“The deck looks great - really like the way you slimmed it down… gets right to the point.”', heading: "Climate Tech Founder" },
  { id: 2, content: '“This was the easiest way for us to get through fundraising.”', heading: "Biotech Founder" },
  { id: 3, content: '“This is our second round with whatapitch. Never fails”', heading: "Fintech Founder" },
  { id: 4, content: '“I am legit getting paid to give feedback and review deal flow”', heading: "Investor" },
];

const faqsArray: faqArrayProps[] = [
  {question: "Who are some of the industry experts?", answer: "Our community of investors and advisors is growing everyday. We have previously exited founders or executives from some of the largest ventures in the world or multi-billion dollar funds all eager to help you close this next round. "},
  {question: "What if I do not even have a pitch deck yet?", answer: "No worries! Schedule a one-on-one with one of our experts or use our templated deck builder to get started."},
  {question: "What if I have already raised some funds in my round?", answer: "Congrats! That’s awesome! Just use our review to improve and push this round faster!"},
  {question: "Who will be reviewing the decks? How do you verify them?", answer: "Each deck is reviewed by a pre-vetted industry or fundraising expert! We DO NOT approve any reviewer until they have undergone and initial vetting process."},
  {question: "Will the reviewers also have a chance to invest?", answer: "Yes! We have seen a bunch of our ventures funded through the platform. In fact, some continue relationships with their investors and advisors offline. "},
  {question: "How do I sign up as an investor or industry expert?", answer: "Simply start here!"}
] 

function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (location.pathname === '/') {
      if (body && html) {
        body.classList.remove('body-height');
        html.style.overflowY = 'auto';
      }
    }
    return () => {
      if (body && html) {
        body.classList.add('body-height');
        html.style.overflowY = 'hidden';
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scroll({ left: 0, top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className="innerWrapper">
        <div className="bg-color-gradient-1">
          <Header />
          <section className="mainBanner" >
            <div className="container text-center" data-aos="fade-in" data-aos-easing="ease-in-sine">
              <h1>Fundraising is a <span>*</span>itch.</h1>
              <p><span>Perfect your pitch</span> with the help of 1,000+ industry and fundraising experts.</p>
              <div className="button-row">
                <button onClick={() => navigate('/authInfo', { state: { type: "founder" } })} className='btn btn-primary list-inline-item'>Perfect my Pitch</button>
                <button onClick={() => navigate('/authInfo', { state: { type: "expert" } })} className='btn btn-default list-inline-item'>Get Paid To Review</button>
              </div>
              <div className="noteBar">
                We connect founders and experts to craft, review and perfect their pitch so fundraising goes faster.
              </div>
            </div>
          </section>
        </div >
        <div className="bg-color-gradient-2">
          <Technologies />
          <Experts />
        </div>
        <Packages />
        <div className="bg-color-gradient-3">
          <section className="aboutFounders">
            <div className="container" data-aos="fade-in" data-aos-easing="ease-in-sine">
              <h2 className='sectionTitle font-sb'>
                Why <span>Founders</span> Squeeze This <br></br>Lemon <span>First.</span>
              </h2>
              <div className="text-center image" >
                <img src={lemonDetails} alt="lemon details" />
              </div>
              <div className="button-row">
                <button className='btn btn-primary' onClick={() => navigate('/auth')}>Compete to win $10,000!</button>
              </div>
            </div>
          </section>
          <Reviews reviews={reviewsArray} />
        </div>
        <div className="bg-color-gradient-4">
          <section className="howitWorks text-center">
            <div className="container" data-aos="fade-right" data-aos-easing="ease-in-sine">
              <h2 className='sectionTitle'>
                How It Works.
              </h2>
              <div className="row justify-content-center justify-content-md-between">
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <div className="featureListing">
                    <div className="featureListing-image">
                      <img src={uploaddeck} alt="Upload Your Deck or Video Pitch." />
                    </div>
                    <div className="featureListing-desc">
                      <h5>Upload Your Deck <br></br> or Video Pitch.</h5>
                      <p>Fast, secure and seamless uploads of your deck into our integrated viewer.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <div className="featureListing">
                    <div className="featureListing-image">
                      <img src={connect} alt="Connect With an Industry and Fundraising Expert." />
                    </div>
                    <div className="featureListing-desc">
                      <h5>Connect With an Industry <br></br> and Fundraising Expert.</h5>
                      <p>Previous founders or active investors in your industry.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <div className="featureListing">
                    <div className="featureListing-image">
                      <img src={lemonBig} alt="Get Back to Changing The World." />
                    </div>
                    <div className="featureListing-desc">
                      <h5>Get Back to<br></br> Changing The World.</h5>
                      <p>Fundraising shouldn’t be such a *itch. Your pitch is everything.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Partners /> */}
        </div>
        <Faqs faqs={faqsArray} />
        {/* <TipsTemplates /> */}
        <Footer />
      </div >

    </>
  )
}

export default Home
