import { configureStore } from '@reduxjs/toolkit';
import signinReducer from 'reducers/authReducer';
import founderDashboardReducer from 'reducers/founderDashboardReducer';
import serviceReducer from 'reducers/billingReducer';
import transactions from 'reducers/transactions';
import profile from 'reducers/profile';
// import { createLogger } from 'redux-logger';
import cards from 'reducers/cards';
import passwordReducer from 'reducers/password';
import reviewsReducer from 'reducers/reviewsReducer';
import walletReducer from "reducers/wallet";

// const logger = createLogger();

const store = configureStore({
    reducer: {
        auth: signinReducer,
        founderDasboard: founderDashboardReducer,
        service: serviceReducer,
        transactions: transactions,
        cards: cards,
        profileStore: profile,
        passwordStore: passwordReducer,
        reviews: reviewsReducer,
        wallet: walletReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;