import { useEffect, useState, useRef } from "react";
import Reply from "../replies/Reply";
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import CreateReply from "../replies/create-reply/CreateReply";
import { createReply } from "actions/reviewActions";
import { useAppDispatch, useAppSelector } from 'hooks';
import { createCommentReply } from "reducers/reviewsReducer";

//This file contains the logic to display comment.

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

function Comment({ comment, index, type }) {
	const [displayReplies, setDisplayReplies] = useState<boolean>(false);
	const [formattedTime, setFormatTime] = useState("");
	const [reply, setReply] = useState("");
	const dispatch = useAppDispatch();
	const replyInputRef = useRef<HTMLTextAreaElement>(null);
	const { profile } = useAppSelector(({ profileStore }) => profileStore);
	const { feedbackId } = useAppSelector(({ reviews }) => reviews);
	const { selectedPitch } = useAppSelector(({ founderDasboard }) => founderDasboard);
	const absoluteImageUrl = comment.user.profileImageUrl ? `${IMAGE_URL}/${comment.user.profileImageUrl}` : "/user.svg";

	useEffect(() => {
		const date = new Date(comment.createdAt);
		const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

		setFormatTime(formattedTime);
	}, [comment])

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
			e.preventDefault();

			try {
				var result = await createReply({ user: profile._id, pitch: selectedPitch._id, message: reply, type: type, commentId: comment._id, feedbackId: feedbackId });
				result.data.data.user = profile;

				const allReplies = [...(comment.replies || []), result.data.data];

				if (replyInputRef.current)
					replyInputRef.current.value = ""

				setReply("");
				dispatch(createCommentReply({ allReplies: allReplies, commentId: comment._id }));
			} catch (err) {
				console.log(err);
			}
		}
	}

	const handleReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setReply(e.target.value);
	}

	return (
		<div className='deck-slider' id={comment.marker}>
			<div className="deck-slider-head">
			</div>
			<div className="deck-messages">
				<div className="d-flex pt-2 position-relative">
					<div className="message-image"><img src={absoluteImageUrl} alt="userProfile" /></div>
					<div className="ms-2 mb-2 content-mes">
						<p className="name">
							<div>
								{comment.user.name}
							</div>
						</p>
						<p className="date">{formattedTime}</p>
					</div>
					<div className="marker-class">
						{selectedPitch.type === "video" && (comment.marker && <div className="video-marker">{comment.marker}</div>)}
						{selectedPitch.type !== "video" && (
							<>
								{comment.marker && (<div className="deck-marker">{comment.marker}</div>)}
							</>
						)}
					</div>
				</div>
				<div className="message-content">
					<p>{comment.message}</p>

				</div>
				<div className='reply-container'>
					<span className="reply" onClick={() => setDisplayReplies(!displayReplies)}>
						{!displayReplies && (
							<ChevronUp />
						)}
						{displayReplies && (
							<ChevronDown />
						)}
						{comment.replies.length === 0 ? "" : comment.replies.length} Reply</span>
					{displayReplies && (
						<div className="reply-content">
							{comment.replies.map((reply, index) => <Reply reply={reply} key={index} />)}
							<CreateReply replyInputRef={replyInputRef} onKeyDown={handleKeyDown} onChange={handleReplyChange} />
						</div>
					)}
				</div>
			</div>

		</div>
	);
}

export default Comment;
