import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { loginAction } from 'actions/authActions';
import { setLoading } from "reducers/authReducer";
import { useAppDispatch, useAppSelector } from "hooks";
import TextError from "components/TextError";
import * as Yup from 'yup';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import Loader from "components/loader/Loader";
import { toLower } from "lodash";

const validationSchema = Yup.object({
	email: Yup
		.string()
		.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Invalid email format')
		.required('Email is required'),
	password: Yup.string()
		.required("Password is required"),
});

const Signin = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [displayPassword, setPassword] = useState(false);
	const { loading } = useAppSelector((state) => state.auth);

	const onSubmit = async (values: any, { setErrors }: any) => {
		try {
			let { email, password } = values;
			email = toLower(email);
			const response = await dispatch(loginAction({ email, password }));
			if (response.type.includes('rejected')) throw response;

			else {
				dispatch(setLoading(false));
				setErrors({ form: '' });
				navigate('/dashboard');
			}
		} catch (error: any) {
			dispatch(setLoading(false));
			setErrors({ form: error.payload });
		}
	};

	const handlePassword = () => {
		setPassword(!displayPassword);

	}

	return (
		<div className="col-xxl-5 col-lg-5" data-aos="fade-up" data-aos-easing="ease-in-sine">
			<div className="card signin-Card">
				<h3>Sign In</h3>
				<Formik
					initialValues={{
						email: '',
						password: ''
					}}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{
						({ errors }: any) => (
							<Form>
								<div className="form-group">
									<Field
										type="text"
										id="email"
										name="email"
										className="form-control"
										placeholder="Email address"
									/>
									<div className="error-field">
										<ErrorMessage name='email' component={TextError} />
									</div>
								</div>
								<div className="form-group">
									<div className="signin-password">
										<Field
											type={displayPassword ? "text" : "password"}
											id="password"
											name="password"
											className="form-control password-field"
											placeholder="Password"
										/>
										<div className="eye-password" onClick={handlePassword}>
											{!displayPassword && <EyeFill />}
											{displayPassword && <EyeSlashFill />}
										</div>
									</div>
									<div className="error-field">
										<ErrorMessage name='password' component={TextError} />
									</div>
								</div>
								<div className="checkbox mt-25">
									<label>
										<input type="checkbox" name="checkbox" />
										<span>
											Keep me logged in
										</span>
									</label>
								</div>
								<div className="button-row">

									<button type="submit" disabled={loading} className="btn btn-primary">
										{loading ? <Loader /> : "Let's Go!"}
									</button>
								</div>
								{errors.form && <div className="error">{errors.form}</div>}
								<div className="text-center">
									<Link to="/forgetPasswordLink" className="forgot-pw">
										I forgot my password
									</Link>
								</div>
							</Form>
						)
					}

				</Formik>
			</div >
		</div >
	);
};

export default Signin;