import Modal from 'react-bootstrap/Modal';
import './style.scss';

//this is the custom pop-up model

function CustomModal({ show, title, description, handleClose, children }) {

	return (
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{description}
				</Modal.Body>
				<Modal.Footer>
					{children}
				</Modal.Footer>
			</Modal>
	);
}

export default CustomModal;