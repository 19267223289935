import logonew from "assets/images/svg/new-logo.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { useEffect, useRef, useState } from 'react';
import PersonalInfo from 'views/expert/signup/PersonalInfo';
import ExperienceInfo from "views/expert/signup/ExperienceInfo";
import TitleInfo from "views/expert/signup/TitleInfo";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { founderSignupAction } from "actions/authActions";
import Loader from "components/loader/Loader";

const initialValues = {
  expertName: "",
  linkedinProfile: "",
  startupExperience: [],
  companyDescription: null,
  designation: "",
  description: ""
};
const PersonalSchema = Yup.object({
  expertName: Yup.string().required('Name is required').max(20, 'Maximum characters limit should be 20')
    .min(3, "Minimum characters limit should be 03"),
  linkedinProfile: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?linkedin\.com(?:\/[a-zA-Z0-9_-]+)?\/[a-zA-Z0-9_-]+\/?$|^$/,
    'Enter valid url format e.g. https://www.linkedin.com/'
  )
});
const ExperienceSchema = Yup.object({
  companyDescription: Yup.array().required('Company description is required')
});

function Founder() {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, password } = location.state || {};
  const { loading } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();
  const sliderRef = useRef<any>();
  const [currentSlide, setCurrentSlide] = useState(0);

  const updateProgressBar = (i: number) => {    
    const progressValues = [25, 50.6667, 75];
    const calc = progressValues[i];    
    const progressBar = document.querySelector('.progress') as HTMLElement;
    const progressBarLabel = document.querySelector('.slider__label') as HTMLElement;
    progressBar.style.backgroundSize = `${calc}% 100%`;
    progressBar.setAttribute('aria-valuenow', calc.toString());
    progressBarLabel.textContent = `${calc}% completed`;
  };

  var settings: any = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    draggable: false,
  };

  useEffect(() => {
    if (isEmpty(email) || isEmpty(password)) navigate('/authInfo', { state: { type: "expert" } });
    // eslint-disable-next-line
  }, []);

  const onClick = async (values: any, setErrors: Function, touched: any, setTouched: Function) => {
    try {
      if (currentSlide === 0) {
        await PersonalSchema.validate(values, { abortEarly: false });
      } else if (currentSlide === 1) {
        await ExperienceSchema.validate(values, { abortEarly: false });
      }
      else if (currentSlide === 2) {
        return onSubmit(values, { setErrors });
      }
      sliderRef.current.slickNext();
    } catch (error) {
      console.log(error)
      const errors: { [key: string]: string } = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e) => {
          errors[e.path || ''] = e.message;
        });
      }
      setErrors(errors);
    }
  };

  const normalizeValues = (values: any) => {
    values.userType = "expert";
    values.name = values.expertName || '';
    return values;
  }

  const onSubmit = async (values: any, { setErrors }: any) => {
    try {
      const normalizedValues = normalizeValues(values);
      const expertData = { email, password, ...normalizedValues };
      const response = await dispatch(founderSignupAction(expertData));
      if (response.type.includes('rejected')) throw response;
      else {
        setErrors({ form: '' });
        navigate('/expertBilling');
      }
    } catch (errors: any) {
      console.log(errors);
      setErrors({ form: errors.payload })
    }
  };

  return (
    <>
      <div className="signup_flow">
        <div className="container-fluid">
          <div className="fullLogo">
            <Link to="/" className="justify-content-start">
              <img src={logonew} alt="whatapitch" />
            </Link>
          </div>
          <section>
            <div className="card " data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="card-header">
                <h1>Expert - Tell us about yourself</h1>
              </div>
              <div className="progress-wrapper">
                <div className="progress have-three-slide" role="progressbar" aria-valuemin={25.33355} aria-valuemax={100}>
                  <span className="slider__label"></span>
                </div>
                <ul className="list-unstyled">
                  <li className="completed"></li>
                  <li className={currentSlide > 0 ? 'completed' : ''}></li>
                  <li className={currentSlide > 1 ? 'completed' : ''}></li>
                </ul>
              </div>

              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validateOnChange={false}
                  validateOnBlur={true}
                  onSubmit={onSubmit}
                >
                  {({ errors, setTouched, touched, values, setErrors, setFieldValue }: any) => (
                    <Form className="expert-form">
                      <div className="slider">
                        <Slider {...settings} ref={sliderRef} afterChange={(current) => { updateProgressBar(current); setCurrentSlide(current); }} >
                          <PersonalInfo setTouched={setTouched} setFieldValue={setFieldValue} />
                          <ExperienceInfo setFieldValue={setFieldValue} />
                          <TitleInfo setFieldValue={setFieldValue} />
                        </Slider>
                      </div>
                      <div className="button-row not-stack justify-content-start">
                        {currentSlide !== 0 && (<button type="button" className='btn btn-secondary list-inline-item sm prev-button' onClick={() => sliderRef.current.slickPrev()}>Back</button>)}
                        <button type="button" className='btn btn-primary list-inline-item sm next-button'
                          onClick={() => onClick(values, setErrors, touched, setTouched)} >
                          {(loading) ? <Loader /> : (currentSlide === 2) ? 'Submit' : 'Next'}
                        </button>
                      </div>
                      {errors.form && <div className="error">{errors.form}</div>}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Founder
