import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getProfile } from "actions/profile";
import { ProfileProps } from "utils/types";


type InitialState = {
    loading: boolean,
    error: string,
    profile: ProfileProps
};
const initialState: InitialState = {
    loading: false,
    error: "",
    profile: {
        _id: "",
        __t: "",
        email: "",
        name: "",
        linkedinProfile: "",
        companyName: "",
        companyWebsite: "",
        isIncorporated: false,
        incorporationDetails: "",
        stage: "",
        roundSize: "0",
        roundProgress: 'string',
        companyDescription: [],
        profileImageUrl: '',
        startupExperience: [],
        designation: "",
        description: "",
        stripeConnectAccountId: "",
        provide_services: []
    }
};
const profileSlice = createSlice({
    name: 'profileStore',
    initialState: initialState,
    reducers: {
        updateProfile: (state: InitialState, action: PayloadAction<any>) => {
            state.profile = { ...state.profile, [action.payload.type]: action.payload.value };
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(getProfile.pending, (state: InitialState) => {
            state.loading = true;
        });
        builder.addCase(getProfile.fulfilled, (state: InitialState, action: PayloadAction<ProfileProps>) => {
            state.loading = false;
            state.profile = action.payload;
        });
        builder.addCase(getProfile.rejected, (state: InitialState, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            throw new Error('Error', { cause: action.payload });
        });
    }

});

export default profileSlice.reducer;
export const { updateProfile } = profileSlice.actions;