import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "axios-instance";

//this file contain the card detail API

export const getCardDetails = createAsyncThunk<any, any>('cards/get', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`/api/cards/${obj.userId}`);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        rejectWithValue(data);
    }
});