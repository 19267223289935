import { useAppSelector } from "hooks";
import { useState } from "react";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import "views/entry/styles.scss";


//this file contains the change password information on the settings page.
const ChangePassword = ({ errors, data, onChange }: any) => {
    const { oldPassword, newPassword, confirmPassword } = useAppSelector(({ passwordStore }) => passwordStore);
    const [showOld, setShowOld] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const onChangePassword = (e: any, type: string) => {
        onChange(type, e.target.value);
    };
    const renderPasswordEye = (display: boolean, handleClick: any) => {
        return (
            <div className="eye-password" onClick={handleClick}>
                {!display && <EyeFill />}
                {display && <EyeSlashFill />}
            </div>
        );
    }
    return (
        <div className="container change-password">
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="#" className="control-label">Old Password</label>
                        <div className="signin-password">
                            <input
                                type={(showOld) ? "text" : "password"}
                                className="form-control password-field"
                                placeholder="Old Password"
                                value={oldPassword}
                                onChange={(e) => onChangePassword(e, 'oldPassword')}
                            />
                            {renderPasswordEye(showOld, () => setShowOld(!showOld))}
                        </div>
                        <div className="error p-1">{errors.oldPassword}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="#" className="control-label">New Password</label>
                        <div className="signin-password">
                            <input
                                type={(showNew) ? "text" : "password"}
                                className="form-control"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => onChangePassword(e, 'newPassword')}
                            />
                            {renderPasswordEye(showNew, () => setShowNew(!showNew))}
                        </div>
                        <div className="error p-1">{errors.newPassword}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="#" className="control-label">Confirm Password</label>
                        <div className="signin-password">
                            <input
                                type={(showConfirm) ? "text" : "password"}
                                className="form-control"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => onChangePassword(e, 'confirmPassword')}
                            />
                            {renderPasswordEye(showConfirm, () => setShowConfirm(!showConfirm))}
                        </div>
                        <div className="error p-1">{errors.confirmPassword}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;