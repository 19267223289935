import React from 'react';
import classNames from "classnames";

//this is the custom input field

type inputFieldProps = {
    onChange?: () => void,
    inputClass?: any,
    disabled?: boolean,
    readOnly?: boolean,
    inputProps?: object
 }

function CustomInputField({onChange, inputClass, inputProps, disabled, readOnly}: inputFieldProps ) {

    const formField = classNames({
        "form-feild": true,
       [inputClass]: inputClass && true,
      });

  return (
    <input
    onChange={onChange}
    className={formField}
    {...inputProps}
    disabled={disabled}
    readOnly={readOnly}
  />
  )
}

export default CustomInputField