import { useRef } from 'react';

function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
}


//this file contains the logic of custom hook for smooth scrolling
export default function useSmoothScroll(duration = 500) {
    const requestRef:any = useRef();

    const smoothScroll = (container, finalScrollPosition) => {
        let startScrollPosition = container.scrollTop;
        let distance = finalScrollPosition - startScrollPosition;
        let startTime:any = null;

        const animation = (currentTime: any) => {
            if (!startTime) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const nextScrollPosition = easeInOutQuad(timeElapsed, startScrollPosition, distance, duration);

            container.scrollTop = nextScrollPosition;

            if (timeElapsed < duration) {
                requestRef.current = requestAnimationFrame(animation);
            } else {
                cancelAnimationFrame(requestRef.current);
            }
        };

        cancelAnimationFrame(requestRef.current); // Cancel any previous animations
        requestAnimationFrame(animation);
    };

    return { smoothScroll };
}
