import { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import pitchLogo from "assets/images/pitchLogo.png";
import './founderListItem.scss';
import { listItemProps } from "utils/types";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "hooks";
import { setCreatedPitch, setSelectedPitch } from 'reducers/founderDashboardReducer';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;


//This file contains the list of pitches on the founder home page.
function ListItem({ pit }: listItemProps) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { userId: { companyDescription, companyWebsite } } = pit || {};
	const [absoluteImageUrl, setImage] = useState("");

	useEffect(() => {
		if(pit.expertId) {
			const absoluteImageUrl = pit?.expert?._doc?.profileImageUrl ? `${IMAGE_URL}/${pit?.expert?._doc?.profileImageUrl}`: "/user.svg";
			setImage(absoluteImageUrl);
		} 

	},[pit])

	const handleNavigate = () => {
		if (pit.status === "create") {
			dispatch(setCreatedPitch({ pitchId: pit._id, serviceType: pit.type }));
			navigate('/deck-info');
			return;
		}

		navigate('/dashboard/reviews');
		dispatch(setSelectedPitch(pit));
	}

	return (
		<>
			<div className="founder-list-item">
				<div className="founder-list-item__left">
					<div className="item-info">
						<div className="item-img">
							<img src={pitchLogo} alt="item" />
						</div>
						<div className="item-content">
							<p className="name">{pit.name}</p>
							<p className="link" data-tooltip-id={companyWebsite}>{companyWebsite}</p>
							<p className="review-by">{pit.type === "deck" ? "Plan: Review my deck pitch." : "Plan: Review my video pitch."}</p>
						</div>
					</div>
					<div className="target-experts">
						<p className="name">Industry</p>
						<div className="label-container">
							<ul className="d-flex list-unstyled select-multiple-buttons">
								{companyDescription?.map((expert: String, index: number) => (
									<li className="checkbox" key={index}>
										<label>
											<span>{expert}</span>
										</label>
									</li>))}
							</ul>
						</div>
					</div>
				</div>
				<div className="founder-list-item__right">
					<div className="first-half">
						<div className="experts-container">
							<p className="name">Experts</p>
							{!pit.expertId && (
								<div className='expert expert-not-av'>N/A</div>
							)}
							{pit.expertId && (
								<div className="expert">
									<div className="expert-icon">
									<img src={absoluteImageUrl} alt="user" />
									</div>
									<p className="cursor">{pit.expert._doc.name}</p>
								</div>
							)}
						</div>
						<div className="status-container">
							<p className="name">Status</p>
							<div className="expert">
								<p>{pit.status === "create" ? "incomplete" : pit.status}</p>
							</div>
						</div>
					</div>
					<div className="second-half">
						<div className="button-container">
							{pit.status === "create" && (
								<button className="btn btn-primary list-inline-item sm" onClick={handleNavigate}>Create</button>
							)}
							{!["create", "uploading"].includes(pit.status)  && (
								<button className="btn btn-primary list-inline-item sm" onClick={handleNavigate}>Details</button>
							)}
							{pit.status === "uploading" && (
								<div className='uploading-primary'>Uploading...</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<ReactTooltip
                id={companyWebsite}
                content={companyWebsite}
                variant="light"
                style={{ color: "#222", fontSize: "14px", left: "0", zIndex: '100000', backgroundColor: '#eee' }}
                place='top-start'
                offset={0}
            />
		</>
	);
}

export default ListItem;
