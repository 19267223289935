import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'axios-instance';

type ChargeType = {
    token: string,
    userId: string,
    amount: string
};
type ErrorString = {
    error: string
};

//this file contains the bigging API's.

export const createCharge = async (obj: ChargeType) => {
    try {
        const { data } = await axiosInstance.post(`/api/payments/charge`, obj);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        throw data;
    }
};

// eslint-disable-next-line
export const getServicesAction = createAsyncThunk<any, any>('service/getServices', async ({ }, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`/api/services`);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        rejectWithValue(data);
    }
});

export const getAccountValidity = createAsyncThunk<any, any>('service/getValidity', async ({ }, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`/api/payments/accountVerificationStatus`);
        return data?.data;
    } catch ({ response: { data } }: any) {
        console.log(data);
        rejectWithValue(data);
    }
});

export const createTransfer = async (obj: any) => {
    try {
        const { data } = await axiosInstance.post(`/api/payments/transfer`, obj);
        return data?.data;
    } catch ({ response: { data } }: any) {
        const error = (data as ErrorString).error || '';
        throw error;
    }
};

export const getTransferStatus = async (obj: any) => {
    try {
        const { data } = await axiosInstance.post(`/api/transfers/getTransferStatus`, obj);
        return data?.data;
    } catch ({ response: { data } }: any) {
        const error = (data as ErrorString).error || '';
        throw error;
    }
};