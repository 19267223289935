import PropTypes from 'prop-types';
import './status.scss';
import { statusProps } from "utils/types";

//this file contains the stats of the pitch on the home page dashboard
function Status({ icon, title, count }: statusProps) {
    return (
        <div className="status">
            <p className="title">
                {icon}
                {title}
            </p>
            <p className="count">{count}</p>
        </div>
    );
}

Status.propTypes = {
    title: PropTypes.string,
    count: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default Status;
