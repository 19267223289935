import React, { useEffect, useState } from 'react';
import VideoPreview from './videoPreview/VideoPreview';
import ImagePreview from './imagePreview/ImagePreview';
import FilePreview from './filePreview/FilePreview';
import { Trash3Fill } from 'react-bootstrap-icons';
import './styles.scss';

//this file display the image, video and document based upon the attachment when creating the pitch.

function AttachmentViewer({ attachment, className, clearAttachment }: { attachment: File | Blob, className: string, clearAttachment: () => void }) {
  const [attachmentSrc, setAttachmentSrc] = useState<string | null>(null);
  const attachmentClass = "attachment-preview"
  const attachmentClassName = `${className} ${attachmentClass}`;

	const type = ((type) => {

		if (type.includes("video")) {
			return "video";
		}

		if(type.includes("image")) {
			return "image";
		}

		if (type.includes("application") || type.includes("text")) {
			return "document";
		}

		return "";
	})(attachment.type);

	useEffect(() => {
		setAttachmentSrc(URL.createObjectURL(attachment));
	}, [attachment]);

	return (
		<div className={attachmentClassName}>
			<Trash3Fill className="bin" onClick={clearAttachment} />
			{type === "document" && attachmentSrc && <FilePreview />}
			{type === "video" && attachment instanceof File &&  <VideoPreview attachmentSrc={attachmentSrc} name={attachment.name} />}
			{type === "image" && attachment instanceof File &&  <ImagePreview attachmentSrc={attachmentSrc} name={attachment.name} /> }
		</div>
	)
}

export default AttachmentViewer