import Slider from "react-slick";
import "./index.scss";
import { reviewArrayProps } from "utils/types";
interface ReviewsProps {
    reviews: reviewArrayProps[];
}

//This file contains the logic to display the reviews.


const Reviews: React.FC<ReviewsProps> = ({reviews}) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        arrows: false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    };
    return (
        <>
            <section className='reviews'>
                <div className="container" data-aos="fade-in" data-aos-easing="ease-in-sine">
                    <Slider {...settings}>
                        {reviews.map((review, index) => 
                            <div className='reviews_listing' key={index}>
                                <div className='reviews_card'>
                                    <div className="reviews_item--desc">
                                        <p>
                                            {review.content}
                                        </p>
                                    </div>
                                </div>
                                <div className="review_heading">
                                    {review.heading}
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Reviews
