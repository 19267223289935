import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFeedbackAction, getReviewAction, deleteMarker, getScales } from "actions/reviewActions";
import { CommentProps, ProfileProps, ScaleProps } from "utils/types";
import { MarkerProps, reviewPitchProps, pitProps } from 'utils/types';


interface reviewerProps extends ProfileProps {
	reviewerDecks: pitProps[]
}

type IntialStateProps = {
	reviewerPitch: reviewPitchProps,
	feedbackId: string,
	feedbackData: { updatedDate: string }
	review: {
		markers?: MarkerProps[],
		pitch?: string,
		createdAt?: Date,
		type?: string,
		updatedAt?: Date
	},
	comments: CommentProps[],
	scales: ScaleProps[],
	loading: boolean,
	error: string,
	reviewError: string,
	markers: MarkerProps[],
	markerLoading: boolean,
	scaleLoading: boolean,
	userScales: ScaleProps[],
	reviewer: reviewerProps | null,
	selectedMarker: MarkerProps,
}

const initialState: IntialStateProps = {
	reviewerPitch: {
		fileData: {},
		pngImages: []
	},
	feedbackId: "",
	feedbackData: {
		updatedDate: ""
	},
	review: {},
	scales: [],
	comments: [],
	loading: true,
	markerLoading: false,
	error: "",
	reviewer: null,
	reviewError: "",
	scaleLoading: false,
	markers: [],
	selectedMarker: {},
	userScales: []
};

const ReviewesSlice = createSlice({
	name: "founderReviewer",
	initialState,
	reducers: {
		createCommentReply: (state: IntialStateProps, action: PayloadAction<any>) => {
			const { commentId, allReplies } = action.payload;

			state.comments[state.comments.findIndex((comment) => comment._id === commentId)].replies = allReplies;
		},
		createFeedbackComment: (state: IntialStateProps, action: PayloadAction<any>) => {
			state.comments = [...action.payload.allComments]
		},
		setUserScale: (state: IntialStateProps, action: PayloadAction<any>) => {
			state.userScales = action.payload;
		},
		setPitchUrl: (state: IntialStateProps, action: PayloadAction<any>) => {
			state.reviewerPitch.fileData = action.payload.fileData;
			state.reviewerPitch.pngImages = action.payload.pngImages;
		},


		setAllMarker: (state: IntialStateProps, action: PayloadAction<any>) => {
			const { allMarkers } = action.payload;

			state.markers = allMarkers;
		},


		setMarker: (state: IntialStateProps, action: PayloadAction<any>) => {
			const { marker } = action.payload;
			state.selectedMarker = marker;
		},



		clearMarker: (state: IntialStateProps) => {
			state.selectedMarker = {}
		}
	},
	extraReducers: (builder: any) => {
		builder.addCase(getFeedbackAction.pending, (state: IntialStateProps) => {
			state.loading = true;
		})
		builder.addCase(getFeedbackAction.fulfilled, (state: IntialStateProps, action) => {
			state.loading = false;

			const { feedbackId, comments, reviewer, scales, feedbackData } = action.payload.data.data;

			state.reviewer = reviewer;
			state.feedbackId = feedbackId;
			state.comments = comments;
			state.userScales = scales;
			state.feedbackData = feedbackData;
		})
		builder.addCase(getFeedbackAction.rejected, (state: IntialStateProps, action) => {
			state.error = action.payload;
			throw new Error('Error', { cause: action.payload });
		})
		builder.addCase(getReviewAction.fulfilled, (state: IntialStateProps, action) => {
			state.review = action.payload.data
			state.markers = action.payload.data.markers
		})
		builder.addCase(getReviewAction.rejected, (state: IntialStateProps, action) => {
			state.reviewError = action.payload;
			throw new Error('Error', { cause: action.payload });
		})
		builder.addCase(deleteMarker.pending, (state: IntialStateProps, action) => {
			state.markerLoading = true
		})
		builder.addCase(deleteMarker.fulfilled, (state: IntialStateProps, action) => {
			state.markerLoading = false;

			const { comment, review } = action.payload;

			let allComments = state.comments.filter((scomment) => scomment._id !== comment._id);
			state.comments = allComments;
			state.review = review;
			state.markers = review.markers

		})
		builder.addCase(getScales.pending, (state: IntialStateProps, action) => {
			state.scaleLoading = true
		})
		builder.addCase(getScales.fulfilled, (state: IntialStateProps, action) => {

			state.scaleLoading = false;
			state.scales = action.payload.data;
		})
	}
})

export default ReviewesSlice.reducer;
export const { setPitchUrl, createFeedbackComment, createCommentReply, setMarker, setAllMarker, clearMarker, setUserScale } = ReviewesSlice.actions;