import DragAndDrop from 'components/drag-and-drop/DragAndDrop';
import AttachmentViewer from 'components/attachment-viewer/AttachmentViewer';
import { uploadToS3, deleteFileS3 } from 'actions/uploadActions';
import TextError from 'components/TextError';
import { useEffect, useState } from 'react';
import Loader from 'components/loader/Loader';
import DocumentViewer from 'components/attachment-viewer/documentViewer/DocumentViewer';

const initialfileTypes = ["MP4", "WEBM", "MKV", "PPTX", "PPT", "PDF", "DOC", "DOCX", "JPG", "JPEG", "PNG"];

function DeckUpload({ formik, serviceType, setS3Key }: any) {
	const { attachment } = formik.errors;
	const [fileTypes, setFileTypes] = useState(initialfileTypes);
	const [loading, setLoading] = useState(false);
	const [docs, setDocs] = useState<any>([]);
	const [attachmentError, setError] = useState<string>("");
	const [displayPreview, setDisplayPreview] = useState(false);
	const FILE_LIMIT = 2097152;

	useEffect(() => {
		let updatedFileTypes = [...initialfileTypes];
		if (serviceType === "deck") updatedFileTypes.splice(0, 3);
		if (serviceType === "video") updatedFileTypes.splice(3);

		setFileTypes(updatedFileTypes);
	}, [serviceType])

	useEffect(() => {

		const handleBeforeUnload = () => {
			if (docs.length !== 0) {
				if (docs[0].fileType !== "pdf") {
					deleteFileS3(docs[0].uri);
				}
			}
			return;
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
	},[docs])

	const handleChange = async (file: any) => {
		if(file.size > FILE_LIMIT) {
			setErrorState("File size should be less than or equal to 2MB");
			return;
		}
		
		if (file.type.includes("application") && !file.type.includes("pdf")) {
			setLoading(true);
			try {

				const fileArray = file.name.split(".");
				const type = fileArray[1];
				const response = await uploadToS3(file, "deck");
				const tempUrl = response.url;
				const key = response.key;
				
				setS3Key(key);
				setDocs([{ uri: tempUrl, fileType: type }]);
				handleAttachment(file);
				setLoading(false);
				return;
			} catch (e) {
				console.log(e);
			}
		}

		if(file.type.includes("pdf")) {
			let result = URL.createObjectURL(file);
			setDocs([{ uri: result, fileType: "pdf" }]);
			handleAttachment(file);
			return;
		}

		if (file) {
			setDisplayPreview(true);
			handleAttachment(file);
		}
	};

	const clearAttachment = async() => {
		if(docs.length !== 0) {
			if(docs[0].fileType !== "pdf") {
				setLoading(true);
				await deleteFileS3(docs[0].uri);
				setS3Key("");
				setLoading(false);
			}
			setDocs([]);
		}
		
		formik.setFieldError("attachment", null);
		formik.setFieldValue("attachment", null);
		setDisplayPreview(false);
	}

	const handleAttachment = (file: File) => {
		formik.setFieldError("attachment", null);
		formik.setFieldValue("attachment", file);
	}

	const handleTypeError = (e) => {
		setErrorState(e);
	}


	const setErrorState = (message) => {
		setError(message);
		setTimeout((_) => {
			setError("");
		}, 3000);
	  };


	return (
		<div className="col-lg-7" data-aos="fade-right" data-aos-easing="ease-in-sine">
			<div className="wrapper">
				<h2>Upload your pitch</h2>
				<p>
				<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
					<defs>
						<clipPath id="clipPath">
							<rect id="Rectangle_52" data-name="Rectangle 52" width="34" height="34" transform="translate(-0.105 -0.105)" fill="none" />
						</clipPath>
					</defs>
					<g id="Group_53" data-name="Group 53" transform="translate(0.105 0.105)" clipPath="url(#clip-path)">
						<path id="Path_749" data-name="Path 749" d="M16.9,33.79A16.895,16.895,0,0,1,4.948,4.948,16.9,16.9,0,1,1,28.842,28.842,16.784,16.784,0,0,1,16.9,33.79m0-32.043A15.148,15.148,0,1,0,32.043,16.9,15.165,15.165,0,0,0,16.9,1.747" fill="#5f2deb" />
						<path id="Path_752" data-name="Path 752" d="M74.721,70.542l-.007,0a.566.566,0,0,1-.349-.523V62.127A.566.566,0,0,1,75,61.564a11.967,11.967,0,0,1,5.859,2.43.568.568,0,0,1,.056.851L75.339,70.42a.566.566,0,0,1-.618.122m-2.446,0-.007,0a.566.566,0,0,1-.618-.122l-5.575-5.575a.568.568,0,0,1,.056-.851,11.967,11.967,0,0,1,5.86-2.43.566.566,0,0,1,.633.563v7.889a.566.566,0,0,1-.349.523m-1.733,1.729,0,.007a.566.566,0,0,1-.523.349H62.127a.566.566,0,0,1-.563-.633,11.967,11.967,0,0,1,2.43-5.859.568.568,0,0,1,.851-.056L70.42,71.65a.566.566,0,0,1,.122.618m1.726,4.179.007,0a.566.566,0,0,1,.349.523v7.889a.566.566,0,0,1-.633.563,11.966,11.966,0,0,1-5.86-2.43.568.568,0,0,1-.056-.851l5.575-5.575a.566.566,0,0,1,.618-.122m2.446,0,.007,0a.566.566,0,0,1,.618.122l5.575,5.575a.568.568,0,0,1-.056.851A11.966,11.966,0,0,1,75,85.425a.566.566,0,0,1-.633-.563V76.973a.566.566,0,0,1,.349-.523m1.733-1.729,0-.007a.566.566,0,0,1,.523-.349h7.889a.566.566,0,0,1,.563.633A11.966,11.966,0,0,1,83,80.857a.568.568,0,0,1-.851.056l-5.575-5.575a.566.566,0,0,1-.122-.618m8.415-2.1H76.973a.566.566,0,0,1-.523-.349l0-.007a.566.566,0,0,1,.122-.618l5.575-5.575a.568.568,0,0,1,.851.056,11.966,11.966,0,0,1,2.43,5.859.566.566,0,0,1-.563.633m-22.735,1.74h7.889a.566.566,0,0,1,.523.349l0,.007a.566.566,0,0,1-.122.618l-5.575,5.575a.568.568,0,0,1-.851-.056A11.967,11.967,0,0,1,61.564,75a.566.566,0,0,1,.563-.633" transform="translate(-56.6 -56.599)" fill="#5f2deb" />
					</g>
				</svg>
				Plan: {serviceType === "deck" ? "Review my pitch deck" : "Review my video pitch"}
					</p>
			</div>
			<div>
				{!formik.values.attachment && !loading && (
					<DragAndDrop handleChange={handleChange} onTypeError={handleTypeError} multiple={false} name="file" types={fileTypes} classes="file-drag-drop" required={true}>
						<div className='uploader'>
							<svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
								<g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(-3 -3)">
									<path id="Path_988" data-name="Path 988" d="M74.5,22.5V37.389a7.617,7.617,0,0,1-7.778,7.444H12.278A7.617,7.617,0,0,1,4.5,37.389V22.5" transform="translate(0 29.667)" fill="none" stroke="#5f2deb" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
									<path id="Path_989" data-name="Path 989" d="M50.722,23.111,30.611,4.5,10.5,23.111" transform="translate(8.889 0)" fill="none" stroke="#5f2deb" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
									<path id="Path_990" data-name="Path 990" d="M18,4.5V49.167" transform="translate(21.5 0)" fill="none" stroke="#5f2deb" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
								</g>
							</svg>
							<h3 className='sm'>Drag and drop your file</h3>
							<div className="upload-btn-wrapper">
								<button className="btn btn-primary sm">Browse</button>
								<input type="file" name="myfile" />
							</div>
						</div>
					</DragAndDrop>
				)}
				{docs.length !== 0 && !loading && <DocumentViewer clearAttachment={clearAttachment} docs={docs} parentClass="deck-viewer" headerProps={{ disableHeader: false }} documentClass="doc-viewer" />}
				{loading && <div className='loader-background'>
					<div className='loader-wrapper'>
						<Loader className='deck-loader' />
						<span className='loading-text'>Loading...</span>
					</div>
				</div>}
				{displayPreview && (
					<AttachmentViewer attachment={formik.values.attachment} className='pitch-file-viewer' clearAttachment={clearAttachment} />
				)}
			</div>
			{attachmentError && <TextError>{attachmentError}</TextError>}
			{attachment && formik.touched.attachment && <TextError>{attachment}</TextError>}
		</div>
	)
}

export default DeckUpload