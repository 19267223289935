import lemonDetails from "assets/images/about-founders-updated.png";
import lemonBig from "assets/images/svg/lemon-big.svg";
import connect from "assets/images/svg/connect.svg";
import uploaddeck from "assets/images/svg/software-upload.svg";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import Reviews from "components/reviews/Reviews";
import Faqs from "components/partial/faqs/Faqs";
import Packages from "components/partial/packages/Packages"
import { faqArrayProps } from "utils/types";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router";
import { reviewArrayProps } from "utils/types";
import { useEffect } from "react";

const reviewsArray: reviewArrayProps[] = [
  { id: 1, content: '“The deck looks great - really like the way you slimmed it down… gets right to the point.”', heading: "Climate Tech Founder" },
  { id: 2, content: '“This was the easiest way for us to get through fundraising.”', heading: "Biotech Founder" },
  { id: 3, content: '“This is our second round with whatapitch. Never fails”', heading: "Fintech Founder" },
  { id: 4, content: '“I am legit getting paid to give feedback and review deal flow”', heading: "Investor" },
];

const faqsArray: faqArrayProps[] = [
  {question: "What does the one-time fee include?", answer: "Our one-time fee includes access to the specified service, such as Roast My Video Pitch, Roast My Pitch Deck, or One-on-One Coaching. It covers the entire process from recording your pitch or uploading your deck to connecting with a fundraising expert and receiving feedback. Additionally, it includes the cost of professional deck design to ensure your presentation stands out."},
  {question: "Can I purchase multiple services?", answer: "Absolutely! You can mix and match our services based on your needs. Whether you want to refine both your video pitch and pitch deck or schedule multiple one-on-one coaching sessions, our pricing is flexible to accommodate your requirements."},
  {question: "Is there a satisfaction guarantee?", answer: "Yes, we stand behind the quality of our services. If you're not satisfied with the outcome or feel that the service did not meet your expectations, please reach out to our support team, and we'll do our best to address your concerns."},
  {question: "Are there any hidden fees or recurring charges?", answer: "No, there are no hidden fees or recurring charges associated with our pricing. The amount you see for each service is a one-time fee, and there are no additional costs involved. We believe in transparency and strive to make our pricing straightforward and predictable."},
  {question: "How long does it take to complete the service?", answer: "The timeframe for completing each service may vary depending on factors such as the complexity of your pitch or deck and the availability of our experts. However, experts are required to provide feedback within 48 hours of accepting a pitch."}
] 

function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (location.pathname === '/pricing') {
      if (body && html) {
        body.classList.remove('body-height');
        html.style.overflowY = 'auto';
      }
    }
    return () => {
      if (body && html) {
        body.classList.add('body-height');
        html.style.overflowY = 'hidden';
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scroll({ left: 0, top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className="innerWrapper">
        <div className="bg-color-gradient-1">
          <Header />
          <section className="mainBanner" >
            <div className="container text-center" data-aos="fade-in" data-aos-easing="ease-in-sine">
              <h1 className="font-medium">Pricing</h1>
              <p className="pricing-heading"><span className="price-heading">The easiest way to <span className="title">perfect your pitch</span> and get back to changing the world.</span> <span className="heading-line">Founders get real feedback from leading investors and industry experts. Investors get paid to connect with and support founders.</span></p>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item"><a href="/#">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Pricing</li>
                </ol>
              </nav>
            </div>
          </section>
        </div >
        <div className="bg-color-gradient-2">
        </div>
        <Packages hide={true} isActive={false} />
        <div className="bg-color-gradient-3">
          <section className="aboutFounders">
            <div className="container" data-aos="fade-in" data-aos-easing="ease-in-sine">
              <h2 className='sectionTitle font-sb'>
                Why <span>Founders</span> Squeeze This <br></br>Lemon <span>First.</span>
              </h2>
              <div className="text-center image" >
                <img src={lemonDetails} alt="lemon details" />
              </div>
              <div className="button-row">
                <button className='btn btn-primary' onClick={() => navigate('/auth')}>Compete to win $10,000!</button>
              </div>
            </div>
          </section>
          <div className="bg-color-gradient-4">
          <section className="howitWorks text-center">
            <div className="container" data-aos="fade-right" data-aos-easing="ease-in-sine">
              <h2 className='sectionTitle'>
                How It Works.
              </h2>
              <div className="row justify-content-center justify-content-md-between">
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <div className="featureListing">
                    <div className="featureListing-image">
                      <img src={uploaddeck} alt="Upload Your Deck or Video Pitch." />
                    </div>
                    <div className="featureListing-desc">
                      <h5>Upload Your Deck <br></br> or Video Pitch.</h5>
                      <p>Fast, secure and seamless uploads of your deck into our integrated viewer.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <div className="featureListing">
                    <div className="featureListing-image">
                      <img src={connect} alt="Connect With an Industry and Fundraising Expert." />
                    </div>
                    <div className="featureListing-desc">
                      <h5>Connect With an Industry <br></br> and Fundraising Expert.</h5>
                      <p>Previous founders or active investors in your industry.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <div className="featureListing">
                    <div className="featureListing-image">
                      <img src={lemonBig} alt="Get Back to Changing The World." />
                    </div>
                    <div className="featureListing-desc">
                      <h5>Get Back to<br></br> Changing The World.</h5>
                      <p>Fundraising shouldn’t be such a *itch. Your pitch is everything.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        <Faqs faqs={faqsArray} />
        </div>
          <Reviews reviews={reviewsArray} />
        </div>
        <Footer />
      </div >

    </>
  )
}

export default Home
