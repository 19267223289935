import React, { useState } from 'react'
import '../mobNavbar/mobNav.scss'
import { Link } from 'react-router-dom';

interface MobNavProps {
    isActive: boolean; // Specify the type of isActive
}

function MobNav({ isActive }: MobNavProps) {
    const [activeTab, setActiveTab] = useState('dashboard');
    const handleActiveTab = (tab: string) => setActiveTab(tab);
    return (
        <div className={`mobNav ${isActive ? 'active' : ''}`}>
            <ul className="sidenav">
                <li className={`nav-item ${activeTab === 'dashboard' && 'active'}`} onClick={() => handleActiveTab('dashboard')}>
                    <Link to="/dashboard">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                            <g id="Dashborad" transform="translate(-0.5 -0.5)">
                                <path id="Path_978" data-name="Path 978" d="M2.7,10.767H8.567a2.2,2.2,0,0,0,2.2-2.2V2.7A2.2,2.2,0,0,0,8.567.5H2.7A2.2,2.2,0,0,0,.5,2.7V8.567a2.2,2.2,0,0,0,2.2,2.2ZM1.967,2.7A.733.733,0,0,1,2.7,1.967H8.567A.733.733,0,0,1,9.3,2.7V8.567a.733.733,0,0,1-.733.733H2.7a.733.733,0,0,1-.733-.733ZM2.7,22.5H8.567a2.2,2.2,0,0,0,2.2-2.2V14.433a2.2,2.2,0,0,0-2.2-2.2H2.7a2.2,2.2,0,0,0-2.2,2.2V20.3a2.2,2.2,0,0,0,2.2,2.2Zm-.733-8.067A.733.733,0,0,1,2.7,13.7H8.567a.733.733,0,0,1,.733.733V20.3a.733.733,0,0,1-.733.733H2.7a.733.733,0,0,1-.733-.733ZM20.3.5H14.433a2.2,2.2,0,0,0-2.2,2.2V8.567a2.2,2.2,0,0,0,2.2,2.2H20.3a2.2,2.2,0,0,0,2.2-2.2V2.7A2.2,2.2,0,0,0,20.3.5Zm.733,8.067A.733.733,0,0,1,20.3,9.3H14.433a.733.733,0,0,1-.733-.733V2.7a.733.733,0,0,1,.733-.733H20.3a.733.733,0,0,1,.733.733Zm-3.667,3.667A5.133,5.133,0,1,0,22.5,17.367,5.133,5.133,0,0,0,17.367,12.233Zm0,8.8a3.667,3.667,0,1,1,3.667-3.667,3.667,3.667,0,0,1-3.667,3.667Z" transform="translate(0 0)" />
                            </g>
                        </svg>
                        <p>Dashboard</p>
                    </Link>
                </li>
                <li className={`nav-item ${activeTab === 'reviews' && 'active'}`} onClick={() => handleActiveTab('reviews')}>
                    <Link to="/dashboard/reviews">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.048" viewBox="0 0 24 24.048">
                            <g id="Reviews" transform="translate(-1.061 -1)">
                                <path id="Path_981" data-name="Path 981" d="M9.641,18.821l3.4-1.9,3.4,1.9a.459.459,0,0,0,.551-.05.476.476,0,0,0,.2-.5l-.651-4,2.8-2.853a.4.4,0,0,0,.1-.5.485.485,0,0,0-.4-.35L15.2,10.011,13.5,6.356a.488.488,0,0,0-.9,0l-1.7,3.654-3.855.551a.485.485,0,0,0-.4.35.582.582,0,0,0,.1.5l2.8,2.853-.651,4a.476.476,0,0,0,.2.5A.449.449,0,0,0,9.641,18.821Zm-1.5-7.409,3.154-.5c.15,0,.3-.15.4-.3l1.4-2.953,1.4,2.953a.519.519,0,0,0,.4.3l3.154.5-2.3,2.353a.612.612,0,0,0-.15.451l.551,3.254-2.753-1.5a.651.651,0,0,0-.5,0l-2.753,1.5.551-3.254a.428.428,0,0,0-.15-.451Zm16.87,8.51a.485.485,0,0,0-.4-.35l-2.2-.35-1-2.1a.488.488,0,0,0-.9,0l-1,2.1-2.2.35a.485.485,0,0,0-.4.35.582.582,0,0,0,.1.5l1.6,1.652-.4,2.353a.476.476,0,0,0,.2.5.689.689,0,0,0,.551.05l2-1.1,2,1.1a.459.459,0,0,0,.551-.05.476.476,0,0,0,.2-.5l-.4-2.353,1.6-1.652A.376.376,0,0,0,25.009,19.922Zm-2.6,1.652a.612.612,0,0,0-.15.451l.25,1.6-1.3-.751a.651.651,0,0,0-.5,0l-1.3.751.25-1.6a.428.428,0,0,0-.15-.451l-1.1-1.151,1.5-.25c.15,0,.3-.15.4-.3l.651-1.4.651,1.4a.519.519,0,0,0,.4.3l1.5.25ZM9.19,19.922a.485.485,0,0,0-.4-.35l-2.2-.35-1-2.1a.488.488,0,0,0-.9,0l-1,2.1-2.2.35a.485.485,0,0,0-.4.35.582.582,0,0,0,.1.5l1.6,1.652-.4,2.353a.476.476,0,0,0,.2.5.689.689,0,0,0,.551.05l2-1.1,2,1.1a.459.459,0,0,0,.551-.05.476.476,0,0,0,.2-.5l-.4-2.353,1.6-1.652a.376.376,0,0,0,.1-.5Zm-2.6,1.652a.612.612,0,0,0-.15.451l.25,1.6-1.3-.751a.455.455,0,0,0-.5,0l-1.3.751.25-1.6a.428.428,0,0,0-.15-.451l-1.1-1.151,1.5-.25c.15,0,.3-.15.4-.3l.651-1.352.651,1.4a.519.519,0,0,0,.4.3l1.5.25ZM13.045,4.8a.473.473,0,0,0,.5-.5V1.5a.5.5,0,0,0-1,0V4.3A.473.473,0,0,0,13.045,4.8Zm4.405.35,1.8-2A.5.5,0,0,0,18.5,2.5l-1.8,2a.487.487,0,0,0,.05.7.536.536,0,0,0,.7-.05Zm-8.51,0a.5.5,0,1,0,.7-.7l-2.052-2a.5.5,0,0,0-.7.7Z" />
                            </g>
                        </svg>
                        <p>Reviews</p>
                    </Link>
                </li>
                <li className={`nav-item ${activeTab === 'payments' && 'active'}`} onClick={() => handleActiveTab('payments')}>
                    <Link to="/dashboard/payments">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="23.977" viewBox="0 0 24.5 23.977">
                            <g id="Payment" transform="translate(-5.75 -6.272)">
                                <path id="Path_979" data-name="Path 979" d="M24.709,16.34V10.022a.5.5,0,0,0-.5-.5,2.5,2.5,0,0,1-2.5-2.5.5.5,0,0,0-.5-.5H9.5a.5.5,0,0,0-.5.5,2.5,2.5,0,0,1-2.5,2.5.5.5,0,0,0-.5.5v9.26c0,5.158,8.777,9.149,9.15,9.316a.5.5,0,0,0,.409,0c.041-.018.937-.421,2.161-1.125A6.929,6.929,0,1,0,24.709,16.34ZM15.355,27.59C14.056,26.969,7,23.4,7,19.282v-8.8A3.508,3.508,0,0,0,9.964,7.522h10.78a3.508,3.508,0,0,0,2.965,2.964v5.681c-.212-.019-.425-.032-.641-.032A6.918,6.918,0,0,0,17.145,26.65q-.878.5-1.79.94ZM23.068,29A5.932,5.932,0,1,1,29,23.067,5.939,5.939,0,0,1,23.068,29Z" transform="translate(0 0)" fill="#191919" stroke="#191919" strokeWidth="0.5" />
                                <path id="Path_980" data-name="Path 980" d="M27.986,28.929A1.021,1.021,0,0,1,28.945,30a.479.479,0,1,0,.952,0,2.1,2.1,0,0,0-1.435-2.059v-.369a.479.479,0,1,0-.952,0v.369A2.1,2.1,0,0,0,26.075,30a2.034,2.034,0,0,0,1.911,2.134,1.078,1.078,0,1,1-.96,1.071.479.479,0,1,0-.952,0,2.1,2.1,0,0,0,1.435,2.059v.369a.479.479,0,1,0,.952,0v-.369A2.1,2.1,0,0,0,29.9,33.206a2.034,2.034,0,0,0-1.911-2.134,1.078,1.078,0,0,1,0-2.143ZM23,22.673a.442.442,0,0,0-.673,0L19.169,26.2l-1.4-1.569a.442.442,0,0,0-.673,0,.576.576,0,0,0,0,.752l1.742,1.945a.442.442,0,0,0,.673,0l3.5-3.9A.576.576,0,0,0,23,22.673Z" transform="translate(-5.131 -8.971)" fill="#191919" stroke="#191919" strokeWidth="0.2" />
                            </g>
                        </svg>
                        <p>Payments</p>
                    </Link>
                </li>
                <li className={`nav-item ${activeTab === 'settings' && 'active'}`} onClick={() => handleActiveTab('settings')}>
                    <Link to="/dashboard/settings">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.001" height="24.002" viewBox="0 0 24.001 24.002">
                            <g id="Settings" transform="translate(-2 -1.999)">
                                <g id="Group_195" data-name="Group 195" transform="translate(2 1.999)">
                                    <path id="Path_982" data-name="Path 982" d="M45.112,4.8a3.6,3.6,0,1,0,0,1.6H46.8a.8.8,0,0,0,0-1.6ZM43.6,5.6a2,2,0,1,0-2,2A2,2,0,0,0,43.6,5.6Zm-2,13.2a3.6,3.6,0,0,1,3.511,2.8H46.8a.8.8,0,0,1,0,1.6H45.112A3.6,3.6,0,1,1,41.6,18.8Zm2,3.6a2,2,0,1,0-2,2A2,2,0,0,0,43.6,22.4Z" transform="translate(-23.6 -1.999)" fill="#191919" fillRule="evenodd" />
                                    <path id="Path_983" data-name="Path 983" d="M14.8,10.6a.8.8,0,0,0,0-1.6H2.8a.8.8,0,0,0,0,1.6Zm.8,16a.8.8,0,0,1-.8.8H2.8a.8.8,0,0,1,0-1.6h12A.8.8,0,0,1,15.6,26.6Z" transform="translate(-2 -6.199)" fill="#191919" />
                                    <path id="Path_984" data-name="Path 984" d="M4.489,27.4a3.6,3.6,0,1,0,0-1.6H2.8a.8.8,0,0,0,0,1.6ZM8,28.6a2,2,0,1,1,2-2A2,2,0,0,1,8,28.6Z" transform="translate(-2 -14.599)" fill="#191919" fillRule="evenodd" />
                                    <path id="Path_985" data-name="Path 985" d="M28,30.8a.8.8,0,0,1,.8-.8h12a.8.8,0,0,1,0,1.6h-12A.8.8,0,0,1,28,30.8Z" transform="translate(-17.599 -18.799)" fill="#191919" />
                                </g>
                            </g>
                        </svg>
                        <p>Settings</p>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default MobNav